import { useState } from "react";
import { useThrottleCallback } from "@react-hook/throttle";
import useEvent from "@react-hook/event";

export interface Coordinates {
    x: number;
    y: number;
}

interface MousePosition {
    /** Relative distance from the center of the viewport, expressed in -1 to 1 for the x & y position.
     * -1 being 100% left/top of viewport & 1 being 100% right/bottom of viewport
     */
    relative: Coordinates;
    /** The innerHeight of the viewport (window.innerHeight) */
    viewportHeight: number | null;
    /** The innerWidth of the viewport (window.innerWidth) */
    viewportWidth: number | null;
    /** MouseEvent.clientX: Provides the horizontal coordinate within the viewport at which the event occurred.  */
    x: number;
    /** MouseEvent.clientY: Provides the vertical coordinate within the viewport at which the event occurred.  */
    y: number;
}
const initialState: MousePosition = {
    relative: {
        x: 1,
        y: 1
    },
    viewportHeight: 0,
    viewportWidth: 0,
    x: 0,
    y: 0
};

interface MousePositionOptions {
    /** Refresh rate of the throttleCallback, default = 30 */
    fps?: number;
}

/**
 * @param x X Position of the cursor in the element
 * @param y Y Position of the cursor in the element
 * @param width Width of the element
 * @param height Height of the element
 */
function getRelativeCoordinates(
    x: number | null,
    y: number | null,
    width: number,
    height: number
): Coordinates {
    if (!x || !y) {
        return { x: 0, y: 0 };
    }

    return {
        x: (x - width / 2) / (width / 2) || 0,
        y: (y - height / 2) / (height / 2) || 0
    };
}

export function useMousePosition(
    options: MousePositionOptions = { fps: 30 }
): MousePosition {
    const { fps } = options;
    const [mousePosition, setMousePosition] =
        useState<MousePosition>(initialState);
    const target = typeof window !== "undefined" ? window : null;

    const onMove = useThrottleCallback(
        (event: MouseEvent) => {
            const { x, y, view } = event;
            const viewportHeight = view?.innerHeight || 0;
            const viewportWidth = view?.innerWidth || 0;

            const state = {
                relative: getRelativeCoordinates(
                    x,
                    y,
                    viewportWidth,
                    viewportHeight
                ),
                viewportHeight,
                viewportWidth,
                x,
                y
            };

            setMousePosition(state);
        },
        fps,
        true
    );

    const onLeave = useThrottleCallback(
        () => {
            setMousePosition(initialState);
        },
        fps,
        false
    );

    useEvent(target, "mousemove", onMove);
    useEvent(target, "mouseleave", onLeave);

    return mousePosition;
}
