import styled from "styled-components";

import { media } from "constants/media";

export const Wrapper = styled.div`
    height: 28px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 58px;
    z-index: var(--z-index-homepage-section);

    svg {
        height: auto;
        width: 100%;
    }

    ${media.mdUp} {
        left: -5px;
        transform: none;
        width: 117px;
    }
`;
