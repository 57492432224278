import { ImagePillBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { StyledContainer } from "./ImagePill.styled";

export function ImagePill({ blok }: ImagePillBlok): JSX.Element {
    const { image } = blok;

    return (
        <StyledContainer {...storyblokEditable(blok)}>
            <Image src={image.filename} alt={image.alt} layout="fill" />
        </StyledContainer>
    );
}
