/* eslint-disable react/no-danger */
import { css } from "styled-components";
import { useRouter } from "next/router";
import NextHead from "next/head";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "";
const googleSiteVerificationToken =
    process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_TOKEN || "";
const themeColor = "#F39000";
const applicationName = "Lead Healthcare";

export function BaseHead(): JSX.Element {
    const router = useRouter();

    return (
        <NextHead>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
            />
            {googleSiteVerificationToken && (
                <meta
                    name="google-site-verification"
                    content={googleSiteVerificationToken}
                />
            )}

            <link
                rel="preconnect"
                href="https://www.googletagmanager.com/"
                crossOrigin="anonymous"
            />
            <link
                rel="preconnect"
                href="https://app.storyblok.com"
                crossOrigin="anonymous"
            />
            <link
                rel="preconnect"
                href="https://o245540.ingest.sentry.io"
                crossOrigin="anonymous"
            />

            <link
                rel="preload"
                href="/fonts/Cabrito-Serif-Norm-Bold.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/fonts/Cabrito-Serif-Norm-Demi.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/fonts/Cabrito-Serif-Norm-Regular.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />

            <link
                rel="preload"
                href="/fonts/Cabrito-Sans-Norm-Bold.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/fonts/Cabrito-Sans-Norm-Demi.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/fonts/Cabrito-Sans-Norm-Regular.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />

            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />

            <link
                rel="icon"
                type="image/png"
                sizes="64x64"
                href="/favicon-64x64.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="160x160"
                href="/favicon-160x160.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/favicon-192x192.png"
            />

            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />
            <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color={themeColor}
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="icon" href="/favicon.ico" />

            <meta name="format-detection" content="telephone=no" />

            <meta name="theme-color" content={themeColor} />
            <meta name="application-name" content={applicationName} />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="apple-mobile-web-app-title" content={applicationName} />
            <meta name="mobile-web-app-capable" content="yes" />

            <meta name="msapplication-tap-highlight" content="no" />
            <meta name="msapplication-config" content="/browserconfig.xml" />
            <meta name="msapplication-TileColor" content={themeColor} />
            <meta
                name="msapplication-TileImage"
                content="/mstile-144x144.png"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content={router.locale} />
            <meta property="og:site_name" content={applicationName} />
            <meta property="og:url" content={`${baseUrl}${router.pathname}`} />

            <style
                /* prettier-ignore */
                dangerouslySetInnerHTML={{__html: css`
                        @font-face {
                            font-display: swap;
                            font-family: "Cabrito Serif";
                            font-style: normal;
                            font-weight: normal;
                            src: local("Cabrito Serif Regular"), url("/fonts/Cabrito-Serif-Norm-Regular.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Cabrito Serif";
                            font-style: normal;
                            font-weight: 600;
                            src: local("Cabrito Serif Demi"), url("/fonts/Cabrito-Serif-Norm-Demi.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Cabrito Serif";
                            font-style: normal;
                            font-weight: 700;
                            src: local("Cabrito Serif Bold"), url("/fonts/Cabrito-Serif-Norm-Bold.woff2") format("woff2");
                        }

                        @font-face {
                            font-display: swap;
                            font-family: "Cabrito Sans";
                            font-style: normal;
                            font-weight: normal;
                            src: local("Cabrito Sans Regular"), url("/fonts/Cabrito-Sans-Norm-Regular.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Cabrito Sans";
                            font-style: normal;
                            font-weight: 600;
                            src: local("Cabrito Sans Demi"), url("/fonts/Cabrito-Sans-Norm-Demi.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Cabrito Sans";
                            font-style: normal;
                            font-weight: 700;
                            src: local("Cabrito Sans Bold"), url("/fonts/Cabrito-Sans-Norm-Bold.woff2") format("woff2");
                        }

                        /* css function of styled-components returns a FlattenSimpleInterpolation type,
                        which does not completely overlap with the type string expected by dangerouslySetInnerHTML */
                    ` as unknown as string
                }}
            />
        </NextHead>
    );
}
