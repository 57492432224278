import {
    MutableRefObject,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import ReactPlayer from "react-player";
import useTranslation from "next-translate/useTranslation";

import { FULLSCREEN, Icon, PAUSE, PLAY } from "components/ui/icon";
import { VisuallyHidden } from "components/ui";

import { FullScreenButton, PlayButton, Wrapper } from "./VideoPlayer.styled";

import { useMediaQueryContext } from "helpers/hooks";

export interface VideoPlayerMethods {
    playVideo: (play: boolean) => void;
    isPlaying: () => boolean;
}

interface VideoProps {
    url: string;
    poster?: string;
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
}

export const VideoPlayer = forwardRef<VideoPlayerMethods, VideoProps>(
    function VideoPlayer(
        {
            url,
            controls = false,
            autoPlay = false,
            muted = false,
            loop = false,
            poster
        }: VideoProps,
        forwardedRef
    ) {
        const { isMobileView } = useMediaQueryContext();
        const [isPlaying, setIsPlaying] = useState<boolean>(autoPlay);
        const { t } = useTranslation();
        const playerRef =
            useRef<ReactPlayer>() as MutableRefObject<ReactPlayer>;
        const internalPlayer =
            playerRef.current?.getInternalPlayer() as Element;

        useImperativeHandle(
            forwardedRef,
            () => ({
                isPlaying() {
                    return isPlaying;
                },
                playVideo(play: boolean) {
                    setIsPlaying(play);
                }
            }),
            [isPlaying]
        );

        function resetVideo() {
            setIsPlaying(false);
            playerRef.current?.seekTo(0);
        }

        function handleClick() {
            setIsPlaying(!isPlaying);
        }

        function handleFullscreen() {
            if (internalPlayer && internalPlayer.requestFullscreen) {
                internalPlayer.requestFullscreen();
            }
        }

        return (
            <Wrapper isPlaying={isPlaying} onClick={handleClick}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <ReactPlayer
                    url={url}
                    loop={loop}
                    muted={muted}
                    autoPlay={autoPlay}
                    controls={controls}
                    playing={isPlaying}
                    onEnded={() => resetVideo()}
                    ref={playerRef}
                    width="100%"
                    height="100%"
                    pip={false}
                    config={{
                        file: {
                            attributes: {
                                poster: poster,
                                preload: "none"
                            }
                        }
                    }}
                />
                <PlayButton onClick={handleClick}>
                    <Icon
                        icon={isPlaying ? PAUSE : PLAY}
                        size={40}
                        aria-hidden="true"
                        color="var(--color-white)"
                    />
                    <VisuallyHidden>
                        {isPlaying ? t("common:pause") : t("common:play")}
                    </VisuallyHidden>
                </PlayButton>
                {isMobileView && internalPlayer?.requestFullscreen && (
                    <FullScreenButton onClick={handleFullscreen}>
                        <Icon
                            icon={FULLSCREEN}
                            size={24}
                            color="var(--color-white)"
                            aria-hidden="true"
                        />
                        <VisuallyHidden>
                            {t("common:fullscreen")}
                        </VisuallyHidden>
                    </FullScreenButton>
                )}
            </Wrapper>
        );
    }
);
