import styled from "styled-components";

interface VisuallyHiddenProps {
    children: React.ReactNode;
}

const HiddenText = styled.span`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export function VisuallyHidden({ children }: VisuallyHiddenProps): JSX.Element {
    return <HiddenText>{children}</HiddenText>;
}
