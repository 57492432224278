import useTranslation from "next-translate/useTranslation";

import { CHEVRON_LEFT } from "components/ui";

import { StyledButton } from "./BackButton.styled";

import { useMenuContext } from "helpers/hooks/useMenuContext";

type Props = {
    isVisible: boolean;
};

export function BackButton({ isVisible = false }: Props): JSX.Element {
    const { setIsSubMenuActive, setActiveItem } = useMenuContext();
    const { t } = useTranslation();

    function handleClick(): void {
        setActiveItem(null);
        setIsSubMenuActive(false);
    }

    return (
        <StyledButton
            buttonType="quinary"
            icon={CHEVRON_LEFT}
            onClick={() => handleClick()}
            isVisible={isVisible}
            aria-label={t("common:backtomenu")}
            role="button"
        >
            {t("common:back")}
        </StyledButton>
    );
}
