import styled from "styled-components";

import { Container } from "components/ui";
import { Heading } from "components/ui";
import { StoryblokButton } from "components/shared";

import { media } from "constants/media";

export const StyledServicesPage = styled.div`
    background-image: url("/images/services-page-bg.webp");
    background-position: center -80px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;

    ${media.mdUp} {
        background-position: center -180px;
        padding: 64px 0;
    }
`;

export const ContentWrapper = styled.div`
    ${media.mdUp} {
        margin: 0;
        width: 50%;
    }
`;

export const ServiceContentWrapper = styled.div<{ isEven: boolean }>`
    ${media.mdUp} {
        margin: ${props => (props.isEven ? "0 48px 0 0" : "0 0 0 48px")};
        width: 50%;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const StyledStoryblokButton = styled(StoryblokButton)`
    width: fit-content;
`;

export const StyledServiceContainer = styled(Container)<{ isEven?: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 0;

    ${media.mdUp} {
        align-items: unset;
        flex-direction: ${props => (props.isEven ? "row-reverse" : "row")};
        margin: 112px auto;
        padding: ${props => (props.isEven ? "0 0 0 112px" : "0")};
    }
`;

export const ServiceImage = styled.div<{ isEven: boolean }>`
    border-radius: 24px;
    height: 144px;
    margin-bottom: 32px;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        transition-duration: var(--default-transition-duration);
        transition-property: transform;
        transition-timing-function: ease;
    }

    &:hover img {
        transform: scale(1.05);
    }

    ${media.mdUp} {
        height: ${props => (props.isEven ? "512px" : "544px")};
        margin-bottom: 0;
        margin-right: ${props => (props.isEven ? "-76px" : "152px")};
        width: ${props => (props.isEven ? "50%" : "472px")};
    }

    ${media.lgUp} {
        height: ${props => (props.isEven ? "512px" : "544px")};
        margin-right: ${props => (props.isEven ? "-112px" : "64px")};
        width: ${props => (props.isEven ? "50%" : "472px")};
    }
`;

export const StyledServiceHeading = styled(Heading)`
    hyphens: auto;
    margin-bottom: 16px;
    word-break: break-all;

    ${media.mdUp} {
        margin-bottom: 24px;
        max-width: 576px;
    }
`;

export const StyledServiceDescription = styled.div`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin-bottom: 40px;
        max-width: 576px;
    }
`;

export const ServiceIconImageWrapper = styled.div`
    border-radius: 50%;
    margin-top: -75px;
    overflow: hidden;
    position: relative;
    width: fit-content;

    ${media.mdUp} {
        margin-top: 48px;
    }
`;

export const BackgroundEllipse = styled.div`
    ${media.mdUp} {
        background: var(--color-orange-40);
        bottom: 76.84%;
        filter: blur(500px);
        left: 50.35%;
        position: absolute;
        right: -41.46%;
        top: -2.02%;
    }
`;
