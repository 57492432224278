import { RelatedArticlesBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import useTranslation from "next-translate/useTranslation";

import { ARROW_RIGHT, Button, Container } from "components/ui";

import { ArticleWrapper, Wrapper } from "./RelatedArticles.styled";
import Item from "./item/Item";

import type { FC } from "react";

const RelatedArticles: FC<RelatedArticlesBlok> = ({ blok }) => {
    const { t } = useTranslation();
    return (
        <Container {...storyblokEditable(blok)}>
            <Wrapper>
                <h3>{blok?.title}</h3>
                <ArticleWrapper>
                    {blok?.articles?.map(article => (
                        <Item
                            key={JSON.stringify(article)}
                            article={article}
                            showDate={blok.showDate}
                            showCategory={blok.showCategory}
                            isDouble={blok?.articles?.length > 1}
                        />
                    ))}
                </ArticleWrapper>
                {blok?.readMore && (
                    <Button
                        href="/nieuws"
                        buttonType="link"
                        icon={ARROW_RIGHT}
                        reversedOrder
                    >
                        {t("common:readmore")}
                    </Button>
                )}
            </Wrapper>
        </Container>
    );
};
export default RelatedArticles;
