import styled, { css } from "styled-components";

import { StoryblokButton } from "components/shared/storyblok";

import { media } from "constants/media";

export const StyledButton = styled(StoryblokButton)``;

export const Container = styled.div<{ isFullWidthBanner?: boolean }>`
    background-color: var(--color-orange-40);
    border-radius: 24px;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    width: 100%;

    h6 {
        text-transform: initial;
        color: var(--color-black);
    }

    a {
        align-self: center;
        justify-content: center;
        width: 224px;
    }

    strong {
        font-weight: var(--font-weight-semibold);
    }

    ${media.mdUp} {
        margin: 0;
        max-width: 576px;

        a {
            align-self: flex-start;
            width: 200px;
        }
    }

    ${({ isFullWidthBanner }) =>
        isFullWidthBanner &&
        css`
        &&& {
            margin: 24px;
            max-width: 992px;
            width: auto;

            ${media.mdUp} {
                flex-direction: row;
                gap: 32px;

                ${StyledButton} {
                    flex: 1 0 200px;
                }
            }

            ${media.lgUp} {
                flex-direction: row;
                margin: 80px auto;
            }
        `};
`;

export const ContactInfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 608px;
`;
