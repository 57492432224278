import { MutableRefObject, useEffect, useRef } from "react";

import { BackButton, NavToggle } from "./mobile";
import { MainNavigation } from "./main";
import { NavigationContainer } from "./Navigation.styled";
import { SecondaryNavigation } from "./secondary";

import { useBodyScrollLock, useMediaQueryContext } from "helpers/hooks";
import { useMenuContext } from "helpers/hooks/useMenuContext";

type NavigationProps = {
    stickyBarActive: boolean;
};

export function Navigation({ stickyBarActive }: NavigationProps) {
    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const { isDesktopView, isMobileView } = useMediaQueryContext();
    const { isMobileMenuActive, isSubMenuActive, setMenuHeight } =
        useMenuContext();

    useBodyScrollLock(isMobileView && isMobileMenuActive);

    useEffect(
        function setMenuForegroundHeight() {
            if (isMobileView && isMobileMenuActive && !isSubMenuActive) {
                const mainMenuHeight: number = ref.current.clientHeight;
                setMenuHeight(mainMenuHeight);
            }
        },
        [isSubMenuActive, isMobileMenuActive, isMobileView, setMenuHeight]
    );

    return (
        <>
            <NavigationContainer
                ref={ref}
                isMobileMenuActive={isMobileMenuActive}
                isSubMenuActive={isSubMenuActive}
                stickyBarActive={stickyBarActive}
            >
                <MainNavigation />
                <SecondaryNavigation />
            </NavigationContainer>
            {!isDesktopView && <BackButton isVisible={isSubMenuActive} />}
            {!isDesktopView && <NavToggle />}
        </>
    );
}
