import Link from "next/link";
import styled from "styled-components";

import { Container, Icon } from "components/ui";

import { media } from "constants/media";

export const LatestNewsArticlesWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px 24px;

    ${media.mdUp} {
        gap: 32px;
        padding: 60px 24px;
    }

    ${media.lgUp} {
        padding: 80px 24px;
    }
`;

export const StyledTitle = styled.h3`
    font-family: var(--font-secondary);
    font-weight: 400;

    ${media.mdUp} {
        font-size: var(--type-heading-2-font-size);
        line-height: var(--type-heading-2-line-height);
    }
`;

export const StyledLink = styled.a`
    align-items: center;
    color: var(--color-orange-60);
    display: flex;
    font-size: 15px;
    font-weight: 800;
    gap: 4px;
    line-height: 24px;
    text-decoration: none;
`;

export const Articles = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media.mdUp} {
        gap: 48px;
    }

    ${media.lgUp} {
        flex-direction: row;
    }
`;

export const ArticleTitle = styled.h6`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    //stylelint-disable-next-line order/properties-alphabetical-order
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    transition: color var(--default-transition-duration) ease;
`;

export const Article = styled(Link)`
    border-radius: 24px;
    box-shadow: 0 2px 4px 0 rgba(225, 233, 243, 1);
    overflow: hidden;
    text-decoration: none;

    &:hover {
        ${ArticleTitle} {
            color: var(--color-orange-60);
        }
    }

    @media (min-width: 425px) {
        width: 368px;
    }
`;

export const ImageWrapper = styled.div`
    height: 96px;
    position: relative;

    ${media.mdUp} {
        height: 152px;
    }
`;

export const ArticleContent = styled.div`
    padding: 16px 24px 8px;

    ${media.mdUp} {
        padding: 24px;
    }
`;

export const ArticleBottom = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`;

export const ArticleDate = styled.p`
    color: var(--color-grey-55);
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
`;

export const StyledArrow = styled(Icon)`
    > path {
        stroke-width: 1.5px;
    }
`;
