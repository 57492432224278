import styled from "styled-components";

export interface IconProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Any icon (`<path /> or <g />`) imported from "IconSet.tsx" */
    icon: React.ReactNode;
    /** Can be any number for pixel values, or a string e.g. "100%" | 10 */
    size?: number | string;
    /** Can be any hex value, name, or set css variable as a string e.g. "var(--color-violet-50)" | "red" | "#FFF".
     * If left undefined will default to parent color. */
    color?: string;
    /** Custom viewbox option */
    viewbox?: string;
    /** Custom width for when the icon is not square sized */
    width?: number | string;
    /** Custom height for when the icon is not square sized */
    height?: number | string;
}

const StyledIcon = styled.svg`
    display: inline-block;
    fill: ${props => props.color};
`;

export function Icon({
    className,
    icon,
    size,
    color = "currentColor",
    viewbox = "0 0 24 24",
    width,
    height
}: IconProps): JSX.Element {
    return (
        <StyledIcon
            className={className}
            fill={color}
            height={height || size}
            stroke={color}
            viewBox={viewbox}
            width={width || size}
        >
            {icon}
        </StyledIcon>
    );
}
