import { Parallax } from "react-parallax";
import { ParallaxImageProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";

import { ParallaxContent, StyledParallaxImage } from "./ParallaxImage.styled";

import { optimizeImage } from "helpers/util/optimizeImage";

export function ParallaxImage({ blok }: ParallaxImageProps): JSX.Element {
    const { image } = blok;

    return (
        <FramerMotionWrapper
            playWhenInView
            animationType={AnimationStyle.FadeInFromBottom}
            duration={0.5}
        >
            <StyledParallaxImage {...storyblokEditable(blok)}>
                {image && (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Parallax
                        bgImage={
                            optimizeImage(image.filename, image.focus, true) ??
                            ""
                        }
                        bgImageAlt={image.alt}
                        strength={500}
                    >
                        <ParallaxContent />
                    </Parallax>
                )}
            </StyledParallaxImage>
        </FramerMotionWrapper>
    );
}
