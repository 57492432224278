import { Button, HomepageHeroBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import { useInView } from "react-intersection-observer";
import Image from "next/legacy/image";
import worldclassWorkplace from "public/images/logos/worldclass-workplace-2023-2024.svg";

import { Heading } from "components/ui";
import { StoryblokButton } from "components/shared/storyblok";

import {
    Content,
    Description,
    HeroImageWrapper,
    Links,
    LinksItem,
    RingWrapper,
    Road,
    StyledContainer,
    WorldclassWorkplaceImageWrapper,
    Wrapper
} from "./HomepageHero.styled";
import { Pointer } from "./pointer";

const renderButtons = (buttons: Array<Button>) => (
    <Links>
        {buttons.map((button: Button) => {
            return (
                button.component === "Button" && (
                    <LinksItem key={button._uid}>
                        <StoryblokButton {...button} />
                    </LinksItem>
                )
            );
        })}
    </Links>
);

export function HomepageHero({ blok }: HomepageHeroBlok): JSX.Element {
    const { ref, inView } = useInView({
        initialInView: false,
        triggerOnce: true
    });

    const { title, description, buttons, image } = blok;

    return (
        <Wrapper ref={ref} {...storyblokEditable(blok)}>
            <StyledContainer type="grid">
                <Pointer />
                <Content>
                    <Heading headingLevel={1}>{title}</Heading>
                    <Description>{description}</Description>
                    {renderButtons(buttons)}
                </Content>

                <RingWrapper>
                    {image && (
                        <HeroImageWrapper>
                            <Image
                                src={image.filename}
                                priority
                                loading="eager"
                                alt={image.alt || "Homepage Hero Image"}
                                layout="fill"
                            />
                        </HeroImageWrapper>
                    )}

                    <Image
                        src="/images/logos/homepage-hero-ring-full-new-gradient.svg"
                        priority
                        loading="eager"
                        alt="Homepage hero ring"
                        layout="fill"
                    />

                    <WorldclassWorkplaceImageWrapper>
                        <Image
                            src={worldclassWorkplace}
                            priority
                            loading="eager"
                            alt="Worldclass Workplace 2023-2024"
                            layout="fill"
                        />
                    </WorldclassWorkplaceImageWrapper>
                </RingWrapper>
            </StyledContainer>
            <Road inView={inView} />
        </Wrapper>
    );
}
