import { Heading as HeadingProps } from "storyblok/components";
import {
    MARK_BOLD,
    NODE_PARAGRAPH,
    render
} from "storyblok-rich-text-react-renderer";

import { Heading } from "components/ui";

export function StoryblokHeading({
    content,
    bold,
    headingLevel,
    as
}: HeadingProps) {
    return (
        <Heading headingLevel={headingLevel} bold={bold} as={as}>
            {render(content, {
                markResolvers: {
                    [MARK_BOLD]: children => <strong>{children}</strong>
                },
                nodeResolvers: {
                    [NODE_PARAGRAPH]: children => <>{children}</>
                }
            })}
        </Heading>
    );
}
