import styled, { css } from "styled-components";

import { Container, Icon } from "components/ui";

export const StyledBreadcrumbsWrapper = styled.div`
    background-color: var(--color-white);
    height: fit-content;
    width: 100%;
    z-index: var(--z-index-menu-background);
`;

export const StyledBreadcrumbsContainer = styled(Container)`
    margin: 0 auto 16px;
    overflow: hidden;
    width: 100%;
`;

export const StyledBreadcrumbs = styled.div`
    display: flex;
    margin-left: 8px;
`;

export const BreadcrumbItem = styled.div`
    align-items: center;
    display: flex;
    text-decoration: none;
`;

export const HomepageCrumbItem = styled.a`
    align-items: center;
    display: flex;
    text-decoration: none;

    svg {
        color: var(--color-black);
        transition: var(--default-transition-duration) color ease-in-out;

        &:hover {
            color: var(--color-primary);
        }
    }
`;

export const BreadcrumbItemLink = styled.a`
    align-items: center;
    display: flex;
    text-decoration: none;
`;

export const BreadcrumbSeperatorIcon = styled(Icon)`
    color: var(--color-grey-55);
    margin: 0 16px;
`;

export const BreadcrumbLabel = styled.div<{
    isDisabled: boolean;
}>`
    color: var(--color-grey-55);
    font-weight: normal;
    line-height: 20px;
    max-width: 128px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Calling first-letter to only capitalize the first word, not every word. */
    &::first-letter {
        text-transform: uppercase;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isDisabled }) =>
        !isDisabled &&
        css`
            color: var(--color-black);
            transition: var(--default-transition-duration) color ease-in-out;

            &:hover {
                color: var(--color-primary);
            }
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;
