import styled from "styled-components";

import { ARROW_RIGHT, Icon } from "components/ui";

import { media } from "constants/media";

export const Arrow = styled(Icon).attrs({
    color: "var(--color-orange-60)",
    icon: ARROW_RIGHT,
    size: 24
})`
    transition: all var(--quick-transition-duration) ease;

    ${media.mdUp} {
        stroke: var(--color-black);
    }
`;

export const Anchor = styled.a`
    align-items: center;
    color: var(--color-orange-60);
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    line-height: 56px;
    text-decoration: none;

    ${media.mdUp} {
        font-size: 15px;
    }

    @media (any-hover: hover) {
        :hover,
        :active,
        :focus {
            ${Arrow} {
                stroke: var(--color-orange-60);
                transform: translateX(5px);
            }
        }
    }
`;
