import { VacancyProps } from "storyblok/components";

export default function storageReader(key: string) {
    if (typeof window === "undefined") return [];
    const storageItem = localStorage.getItem(key);

    if (storageItem) {
        return JSON.parse(storageItem) as Array<VacancyProps>;
    } else {
        return [];
    }
}
