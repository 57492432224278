import { AccordionContentProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper, Icon } from "components/ui";
import { MINUS, PLUS } from "components/ui/icon/IconSet";

import {
    Content,
    Description,
    ImageWrapper,
    Item,
    StyledAccordionBlock,
    Tab,
    TabTitle,
    Tabs,
    Title
} from "./AccordionContent.styled";

import { useMediaQueryContext } from "helpers/hooks";

function AccordionContent({ blok }: AccordionContentProps): JSX.Element {
    const { title, items } = blok;
    const [activeTab, setActiveTab] = useState<string>(items[0].title);
    const { isMobileView } = useMediaQueryContext();
    const tabs = items.map(item => item.title);

    return (
        <FramerMotionWrapper
            playWhenInView
            animationType={AnimationStyle.FadeInFromBottom}
            duration={0.5}
            delay={0.1}
        >
            <StyledAccordionBlock {...storyblokEditable(blok)}>
                {title && <Title headingLevel={2}>{title}</Title>}

                {!isMobileView && (
                    <Tabs>
                        {tabs.map(tab => (
                            <Tab
                                key={`tab-${tab}`}
                                className={activeTab === tab ? "active" : ""}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tabs>
                )}

                {items.map(item => {
                    const { description, image, title } = item;
                    const isActive = activeTab === title;

                    return (
                        <Item key={`accordion-item-${title}`}>
                            {isMobileView && (
                                <Tab onClick={() => setActiveTab(title)}>
                                    {title}
                                    <Icon
                                        icon={isActive ? MINUS : PLUS}
                                        viewbox={
                                            isActive ? "0 0 24 2" : "0 0 24 24"
                                        }
                                        size={24}
                                        color="var(--color-black)"
                                    />
                                </Tab>
                            )}{" "}
                            <Content className={isActive ? "active" : ""}>
                                <ImageWrapper>
                                    {image && (
                                        <Image
                                            src={image?.filename}
                                            layout="fill"
                                            objectFit="cover"
                                            quality={100}
                                            alt={image?.alt}
                                        />
                                    )}
                                </ImageWrapper>
                                <div>
                                    {!isMobileView && title && (
                                        <TabTitle>{title}</TabTitle>
                                    )}
                                    <Description>{description}</Description>
                                </div>
                            </Content>
                        </Item>
                    );
                })}
            </StyledAccordionBlock>
        </FramerMotionWrapper>
    );
}

export default AccordionContent;
