import { VacancyProps } from "storyblok/components";

import storageReader from "./storageReader";

export default function storageRemover(
    currentVacancy: VacancyProps | undefined,
    key: string
) {
    if (typeof window === "undefined") return null;

    if (currentVacancy === undefined) return null;

    const localStorageVacancies = storageReader(key);

    //if the key doesnt exist in local storage, return null
    if (localStorageVacancies === null) return;

    const newLocalStorageVacancies = localStorageVacancies.filter(v => {
        if (v.slug) return v.slug !== currentVacancy.slug;
        if (v.id) return v.id !== currentVacancy.id;
    });

    localStorage.setItem(key, JSON.stringify(newLocalStorageVacancies));
}
