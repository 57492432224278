import styled from "styled-components";

import { media } from "constants/media";

export const Background = styled.div`
    background: var(--color-grey-45);
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto 48px;
`;

export const Title = styled.h2``;

export const VacancyCounter = styled.p``;

export const SavedVacancyWrapper = styled.div`
    display: grid;
    gap: 8px;
    grid-template-columns: 272px;
    margin-top: 32px;

    ${media.mdUp} {
        gap: 12px;
        grid-template-columns: 300px 300px;
        margin-top: 50px;
        overflow: auto;
    }

    ${media.lgUp} {
        gap: 48px;
        grid-template-columns: repeat(2, 368px);
    }

    ${media.xlUp} {
        grid-template-columns: repeat(3, 368px);
    }
`;

export const StyledLink = styled.a`
    align-items: center;
    display: flex;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 24px;
    text-decoration: none;

    svg {
        margin-left: 8px;
    }
`;
