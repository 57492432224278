import styled from "styled-components";

import { FooterUspType } from "./FooterUsp";

import { media } from "constants/media";

export const Pill = styled.p`
    align-items: center;
    border: 1px solid var(--color-grey-50);
    border-radius: 10em;
    color: var(--color-black);
    display: inline-flex;
    font-size: 13px;
    font-weight: 600;
    gap: 8px;
    line-height: 16px;
    padding: 6px 8px;

    ${media.mdUp} {
        font-size: 12px;
    }

    ${media.lgUp} {
        font-size: 13px;
    }

    ${media.xlUp} {
        font-size: 14px;
    }
`;

export const IconWrapper = styled.span<{ type: FooterUspType }>`
    display: inline-block;
    height: ${({ type }) => (type === "award" ? 46 : 32)}px;
    margin: ${({ type }) => (type === "award" ? "3px 0 -3px 0" : "0 9px 0 0")};
    position: relative;
    width: ${({ type }) => (type === "award" ? 39 : 32)}px;
`;

export const Counter = styled.strong`
    align-items: center;
    background-color: var(--color-purple-50);
    border-radius: 24px;
    color: var(--color-white);
    display: inline-flex;
    font-size: 10px;
    font-weight: 600;
    height: 24px;
    justify-content: center;
    left: 17px;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 3px;
    width: 24px;
`;
