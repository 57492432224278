import { Button, HomepageCollaborationBlok } from "storyblok/components";
import { StoryblokAsset, storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";
import { StoryblokButton } from "components/shared/storyblok";

import {
    AmbianceImage,
    Article,
    Author,
    Avatar,
    ButtonWrapper,
    Collage,
    Content,
    Info,
    Name,
    StyledContainer,
    StyledHeading,
    Wrapper
} from "./HomepageCollaboration.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function HomepageCollaboration({
    blok
}: HomepageCollaborationBlok): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();
    const {
        photos,
        avatars,
        boldTitle,
        title,
        text,
        button,
        quote,
        authorPhoto,
        author,
        role,
        marginMobile,
        marginDesktop
    } = blok;

    return (
        <Wrapper {...storyblokEditable(blok)}>
            <StyledContainer type="grid">
                <Content
                    marginMobile={marginMobile}
                    marginDesktop={marginDesktop}
                >
                    <Collage>
                        {photos.map((photo: StoryblokAsset, index: number) => (
                            <AmbianceImage key={index}>
                                <FramerMotionWrapper
                                    playWhenInView
                                    animationType={
                                        AnimationStyle.FadeInFromBottom
                                    }
                                    delay={index * 0.5}
                                    duration={0.5}
                                >
                                    <Image
                                        alt={photo.alt}
                                        src={photo.filename}
                                        width={isDesktopView ? 356 : 178}
                                        height={isDesktopView ? 240 : 120}
                                        objectFit="cover"
                                        layout="fixed"
                                    />
                                </FramerMotionWrapper>
                            </AmbianceImage>
                        ))}

                        {avatars.map(
                            (avatar: StoryblokAsset, index: number) => (
                                <Avatar key={index}>
                                    <FramerMotionWrapper
                                        playWhenInView
                                        animationType={AnimationStyle.FadeIn}
                                        delay={index * 0.375}
                                        duration={0.5}
                                    >
                                        <Image
                                            alt={avatar.alt}
                                            src={avatar.filename}
                                            objectFit="fill"
                                            layout="fill"
                                        />
                                    </FramerMotionWrapper>
                                </Avatar>
                            )
                        )}
                    </Collage>
                    <Info>
                        <StyledHeading>
                            <span>{boldTitle}</span>
                            {title}
                        </StyledHeading>
                        <p>{text}</p>
                        <ButtonWrapper>
                            {button.map((entry: Button) => {
                                return (
                                    entry.component === "Button" && (
                                        <StoryblokButton
                                            key={entry._uid}
                                            {...entry}
                                        />
                                    )
                                );
                            })}
                        </ButtonWrapper>
                        <FramerMotionWrapper
                            playWhenInView
                            animationType={AnimationStyle.FadeInFromBottom}
                            duration={0.5}
                        >
                            {!!quote && <Article>{quote}</Article>}
                        </FramerMotionWrapper>
                        <FramerMotionWrapper
                            playWhenInView
                            animationType={AnimationStyle.FadeInFromBottom}
                            duration={0.5}
                            delay={0.1}
                        >
                            <Author>
                                {authorPhoto?.filename && (
                                    <Image
                                        alt={authorPhoto.alt}
                                        src={authorPhoto.filename}
                                        width={64}
                                        height={64}
                                        layout="fixed"
                                        style={{ borderRadius: "50%" }}
                                    />
                                )}
                                <Info>
                                    <Name>{author}</Name>
                                    <strong>{role}</strong>
                                </Info>
                            </Author>
                        </FramerMotionWrapper>
                    </Info>
                </Content>
            </StyledContainer>
        </Wrapper>
    );
}
