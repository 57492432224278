import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    padding: 50px 0 64px;
    position: relative;
    z-index: var(--z-index-homepage-section);

    ${media.mdUp} {
        padding: 170px 0 64px;
    }

    ${media.lgUp} {
        padding: 70px 0 240px;
    }
`;

export const StyledContainer = styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;
    position: relative;

    ${media.mdUp} {
        display: grid;
        grid-gap: 16px 0;
    }
`;

export const Content = styled.div`
    align-items: flex-start;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 16px 0;
    max-width: 500px;

    a {
        color: var(--color-white);
    }

    svg {
        stroke: var(--color-white);
    }

    ${media.mdUp} {
        grid-column: span 5;
        max-width: 272px;
    }

    ${media.lgUp} {
        color: currentColor;
        gap: 24px 0;
        max-width: none;

        a {
            color: currentColor;
        }

        svg {
            stroke: currentColor;
        }
    }
`;

export const StyledHeading = styled.h2`
    font-size: var(--type-heading-2-font-size);
    font-weight: normal;
    line-height: var(--type-heading-2-line-height);

    ${media.mdUp} {
        font-size: var(--type-heading-1-font-size);
        line-height: var(--type-heading-1-line-height);
    }

    strong {
        display: block;
        font-weight: bold;
    }
`;

export const Buttons = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
    padding: 0;
`;
