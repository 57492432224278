import styled from "styled-components";

import { Container, Heading } from "components/ui";

import { media } from "constants/media";

export const StyledTwoImagesTextBlock = styled(Container)<{
    isReversed?: boolean;
}>`
    display: flex;
    flex-direction: ${props =>
        props.isReversed ? "column-reverse" : "column"};
    gap: 24px;
    margin: 64px auto;

    ${media.mdUp} {
        flex-direction: ${props => (props.isReversed ? "row-reverse" : "row")};
        gap: 64px;
        margin: 80px auto;
        min-height: 676px;
        padding: 80px 0;
    }
`;

export const ImageWrapper = styled.div`
    height: 180px;
    margin-bottom: 24px;
    position: relative;

    ${media.mdUp} {
        height: auto;
        margin: 0;
        width: 50%;
    }
`;

export const ContentWrapper = styled.div`
    position: relative;

    ${media.mdUp} {
        margin-left: 24px;
        width: 50%;
    }

    ${media.lgUp} {
        width: 50%;
    }
`;

export const Title = styled(Heading)`
    margin-bottom: 16px;
`;

export const Text = styled.div`
    font-size: 16px;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 18px;
    }
`;

export const ImageOneWrapper = styled.div`
    border-radius: 24px;
    height: 146px;
    left: -5%;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: rotate(-2deg);
    width: 218px;

    ${media.mdUp} {
        height: 300px;
        max-width: 450px;
        position: absolute;
        width: 100%;
    }
`;

export const ImageTwoWrapper = styled.div`
    border-radius: 24px;
    height: 146px;
    overflow: hidden;
    position: absolute;
    right: -5%;
    top: 45px;
    transform: rotate(2deg);
    width: 218px;

    ${media.mdUp} {
        height: 300px;
        left: 150px;
        max-width: 450px;
        top: 180px;
        width: 100%;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    ${media.mdUp} {
        flex-direction: row;
        margin-top: 32px;
    }
`;
