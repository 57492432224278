import { useEffect, useRef } from "react";

export function useBodyScrollLock(shouldBodyScrollBeLocked: boolean): void {
    const originalStyle = useRef("");
    useEffect(() => {
        // Get original body overflow
        originalStyle.current = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = "hidden";
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalStyle.current;
        };
    }, []); // Empty array ensures effect is only run on mount and unmount

    useEffect(() => {
        if (shouldBodyScrollBeLocked === true) {
            document.body.style.overflow = "hidden";
        } else if (shouldBodyScrollBeLocked === false) {
            document.body.style.overflowY = "visible";
            document.body.style.overflowX = "hidden";
        }
    }, [shouldBodyScrollBeLocked]);
}
