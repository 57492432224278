import styled from "styled-components";

import { Container, Icon } from "components/ui";

import { media } from "constants/media";

export const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    padding: 0 24px;
    position: relative;
    z-index: var(--z-index-homepage-section);

    ${media.mdUp} {
        gap: 24px;
        margin-top: 88px;
    }
`;

export const StyledTitle = styled.h2`
    grid-column-end: 4;
    grid-column-start: 1;
`;

export const ServiceList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    grid-column-end: 4;
    grid-column-start: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    svg:last-of-type {
        display: block;
        margin: 0 0 0 auto;
    }

    ${media.mdUp} {
        cursor: pointer;
        gap: 24px;
    }
`;

export const StyledHeading = styled.p`
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 16px;
    width: 100%;
`;

export const StyledServiceBlock = styled.li`
    border: 1px solid var(--color-grey-50);
    border-radius: 24px;
    grid-column-start: 1;
    min-height: 264px;
    padding: 24px;
    width: 100%;

    ${media.mdUp} {
        width: calc(50% - 24px);

        :nth-child(2n) {
            grid-column-start: 2;
        }
    }
`;

export const Text = styled.p`
    margin: 8px 0 24px;
`;

export const StyledIcon = styled(Icon)`
    fill: var(--color-orange-50);
    stroke: var(--color-orange-50);
    transition: all var(--quick-transition-duration) ease;
`;
