import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { Arrow } from "components/shared/header/navigation/main/submenu/submenuItem";

import { Anchor, ImageContainer, StyledAvatar } from "./AllStories.styled";

type AllStoriesProps = {
    images: Array<string>;
};

export function AllStories({ images }: AllStoriesProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Link
            legacyBehavior
            href="/zorgprofessionals/ervaringen"
            prefetch={false}
            passHref
        >
            <Anchor>
                <ImageContainer>
                    {images.map((imageUrl, index) => (
                        <StyledAvatar
                            key={`${index}-${imageUrl}`}
                            imageUrl={imageUrl}
                            size="small"
                        />
                    ))}
                </ImageContainer>
                {t("common:allstories")}
                <Arrow />
            </Anchor>
        </Link>
    );
}
