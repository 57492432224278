import { StoryblokBlokData, StoryblokComponent } from "@storyblok/react";

import {
    ArticleWrapper,
    TermsContentWrapper,
    TermsMenu,
    TermsMenuItem,
    TermsPageHeading,
    TermsPageWrapper
} from "components/termsAndConditions/termsPage/TermsPage.styled";
import {
    TermArticleType,
    createTermHeading
} from "components/termsAndConditions/termArticle/TermArticle";

export type TermsPageProps = {
    blok: StoryblokBlokData & {
        title?: string;
        articles?: Array<StoryblokBlokData & TermArticleType> | null;
    };
};

function TermsPage({ blok }: TermsPageProps): JSX.Element {
    const { articles, title } = blok;

    return (
        <TermsPageWrapper>
            <TermsPageHeading headingLevel={1}>{title}</TermsPageHeading>
            <TermsContentWrapper>
                <TermsMenu>
                    {articles &&
                        articles.map(listItem => {
                            const headingTitle = createTermHeading({
                                articleNumber: listItem.articleNumber,
                                title: listItem.title
                            });

                            return (
                                <TermsMenuItem
                                    key={listItem.title}
                                    href={`#${headingTitle}`}
                                >
                                    {listItem.articleNumber &&
                                        `${listItem.articleNumber}. `}
                                    {listItem.title}
                                </TermsMenuItem>
                            );
                        })}
                </TermsMenu>
                <div>
                    {articles &&
                        articles?.map(block => {
                            return (
                                <ArticleWrapper key={block._uid}>
                                    <StoryblokComponent
                                        key={block._uid}
                                        blok={block}
                                    />
                                </ArticleWrapper>
                            );
                        })}
                </div>
            </TermsContentWrapper>
        </TermsPageWrapper>
    );
}

export default TermsPage;
