import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { FACEBOOK, INSTAGRAM, Icon, LINKEDIN } from "components/ui";

import {
    ClosingAnchor,
    ClosingText,
    Container,
    DisclaimerList,
    SocialsList
} from "./FooterBottom.styled";

import { useMediaQueryContext } from "helpers/hooks";

const currentYear = new Date().getFullYear();

export function FooterBottom(): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const { t } = useTranslation();

    return (
        <Container>
            <nav>
                <SocialsList>
                    <li>
                        <a
                            href="https://www.facebook.com/LeadHealthcare1"
                            target="_blank"
                            rel="noopener noreferrer"
                            title={t("common:visitfacebook")}
                        >
                            <Icon
                                icon={FACEBOOK}
                                size={16}
                                color="var(--color-facebook-blue)"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/lead_healthcare/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title={t("common:visitinstagram")}
                        >
                            <Icon
                                icon={INSTAGRAM}
                                size={16}
                                color="var(--color-instagram-black)"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.linkedin.com/company/lead-healthcare/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title={t("common:visitlinkedin")}
                        >
                            <Icon
                                icon={LINKEDIN}
                                size={16}
                                color="var(--color-linkedin-blue)"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                </SocialsList>
            </nav>
            <nav>
                <DisclaimerList>
                    <li>
                        <Link
                            legacyBehavior
                            href="/algemene-voorwaarden"
                            passHref
                            prefetch={false}
                        >
                            <ClosingAnchor>
                                {t("common:termsandconditions")}
                            </ClosingAnchor>
                        </Link>
                    </li>
                    <li>
                        <Link
                            legacyBehavior
                            href="/privacy-cookies"
                            passHref
                            prefetch={false}
                        >
                            <ClosingAnchor>
                                {t("common:privacyandcookies")}
                            </ClosingAnchor>
                        </Link>
                    </li>
                </DisclaimerList>
            </nav>
            {!isMobileView && (
                <ClosingText>&copy; {currentYear} Lead Healthcare</ClosingText>
            )}
        </Container>
    );
}
