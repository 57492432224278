import styled from "styled-components";

import { HEART, Icon } from "components/ui";

import { media } from "constants/media";

export const Button = styled.span`
    border-radius: 50%;
    display: inline-block;
    height: 28px;
    position: relative;
    width: 28px;
`;

export const HeartIcon = styled(Icon).attrs({
    icon: HEART,
    size: 24
})`
    stroke: var(--color-white);

    ${media.lgUp} {
        stroke: var(--color-black);
    }
`;

export const Counter = styled.span`
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-orange-60);
    border-radius: 100%;
    color: var(--color-orange-60);
    display: inline-flex;
    font-size: 8px;
    font-weight: bold;
    height: 14px;
    justify-content: center;
    position: absolute;
    right: -3px;
    text-align: center;
    top: -3px;
    width: 14px;

    ${media.lgUp} {
        background-color: var(--color-black);
        border-color: var(--color-white);
        color: var(--color-white);
    }
`;
