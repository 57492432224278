import { MutableRefObject, useEffect, useRef } from "react";
import { useMotionValue, useScroll } from "framer-motion";

import { StickyContainer, Wrapper } from "./StickyScrollWrapper.styled";

import { clamp } from "helpers/transform";
import { useMediaQueryContext } from "helpers/hooks";

interface StickyScrollProps {
    children: React.ReactNode;
    scrollLength?: number;
    setProgress: (value: number) => void;
}

export function StickyScrollWrapper({
    children,
    scrollLength = 500,
    setProgress
}: StickyScrollProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const { scrollY } = useScroll();
    const translateY = useMotionValue(0);
    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        if (isMobileView) {
            return;
        }

        const updateTop = scrollY.onChange(() => {
            const bounds = ref.current?.getBoundingClientRect();
            const newTop = Math.round(clamp(bounds.top * -1, 0, scrollLength));

            if (setProgress) {
                setProgress(newTop / scrollLength);
            }

            translateY.set(newTop);
        });

        return () => {
            updateTop();
        };
    }, [isMobileView, scrollLength, scrollY, setProgress, translateY]);

    return (
        <Wrapper ref={ref} scrollHeight={scrollLength}>
            <StickyContainer
                style={!isMobileView ? { translateY } : undefined}
                transition={{ ease: "linear" }}
            >
                {children}
            </StickyContainer>
        </Wrapper>
    );
}
