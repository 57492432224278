import { ForwardedRef, forwardRef } from "react";

import { CHECK, CLOSE, Icon } from "components/ui";

import {
    ButtonLabel,
    CheckboxInput,
    CheckedButton,
    Label,
    StyledCheckbox,
    SwitchWrapper,
    UnCheckedButton
} from "./Checkbox.styled";

export interface CheckboxProps {
    /** Label for the checkbox. */
    label: string;
    /** Name used for tracking the value of the input, used by react-hook-forms. */
    name: string;
    /** Active value for the input, read by react-hook-forms. */
    activeValue: string | number;
    /** Checkbox is disabled and stuck on its default value */
    disabled?: boolean;
    /** Current value of the checkbox, provided by react-hook-forms `watch(name)` */
    currentValue?: string | number;
    /** Show unchecked state as red */
    showUncheckedAsRed?: boolean;

    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef(function Checkbox(
    {
        label,
        name,
        activeValue,
        currentValue,
        disabled,
        showUncheckedAsRed,
        onChange,
        ...otherProps
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    return (
        <StyledCheckbox>
            <CheckboxInput
                disabled={disabled}
                id={`${name}-${label}`}
                name={`${name}-${label}`}
                ref={ref}
                readOnly
                type="checkbox"
                value={activeValue}
                checked={currentValue === activeValue}
                onChange={onChange}
                {...otherProps}
            />
            <ButtonLabel disabled={disabled} htmlFor={`${name}-${label}`}>
                <SwitchWrapper disabled={disabled}>
                    {showUncheckedAsRed && (
                        <UnCheckedButton
                            isActive={currentValue !== activeValue}
                            disabled={disabled}
                        >
                            <Icon icon={CLOSE} size={24} />
                        </UnCheckedButton>
                    )}
                    <CheckedButton
                        isActive={currentValue === activeValue}
                        disabled={disabled}
                    >
                        <Icon
                            icon={CHECK}
                            size={24}
                            color="var(--color-white)"
                        />
                    </CheckedButton>
                </SwitchWrapper>
                <Label disabled={disabled}>{label}</Label>
            </ButtonLabel>
        </StyledCheckbox>
    );
});
