import { BenefitsSectionBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

// import { ContactBanner } from "components";

import { Container, Section } from "./BenefitsSection.styled";
import Benefits from "./benefits/Benefits";
import BenefitsDescriptionBlock from "./benefitsDescriptionBlock/BenefitsDescriptionBlock";

export function BenefitsSection({ blok }: BenefitsSectionBlok): JSX.Element {
    const { descriptionBlock, benefitsItems } = blok;

    return (
        <Section {...storyblokEditable(blok)}>
            <Container>
                {descriptionBlock?.[0] && (
                    <BenefitsDescriptionBlock
                        title={descriptionBlock[0].title}
                        subtitle={descriptionBlock[0].subtitle}
                        description={descriptionBlock[0].description}
                        link={descriptionBlock[0].link}
                        contactBannerBlok={
                            descriptionBlock?.[0].contactBannerBlok
                        }
                    />
                )}
                {benefitsItems?.[0] && <Benefits items={benefitsItems} />}
            </Container>
        </Section>
    );
}

export default BenefitsSection;
