import { MoreInformationProps } from "storyblok/components";
import { useEffect, useRef, useState } from "react";
import Image from "next/legacy/image";
import Link from "next/link";

import {
    ARROW_LEFT,
    ARROW_RIGHT,
    AnimationStyle,
    FramerMotionWrapper,
    Icon
} from "components/ui";

import {
    BottomLinkAndIndicators,
    IconBackground,
    ImageWrapper,
    Indicator,
    Indicators,
    LinkWrapper,
    Links,
    StyledContainer,
    StyledHeading,
    StyledLink,
    StyledScrollContainer,
    Title,
    Wrapper
} from "./MoreInformation.styled";

import { useMediaQueryContext } from "helpers/hooks";

function calculateScrollDistance({
    scrollRef,
    direction
}: {
    scrollRef: React.RefObject<typeof StyledScrollContainer>;
    direction: "next" | "prev" | null;
}) {
    if (!scrollRef.current) return 0;

    const div = scrollRef.current.container.current;
    const scrollWidth = div.scrollWidth;
    const slides = div.children.length;
    const currentScrollPosition = div.scrollLeft;
    const distanceToScroll =
        (scrollWidth / slides) * (direction === "prev" ? -1 : 1);

    div.scrollTo({
        behavior: "smooth",
        left: currentScrollPosition + distanceToScroll
    });
}

export function MoreInformation({ blok }: MoreInformationProps): JSX.Element {
    const {
        title,
        firstRowLinks,
        secondRowLinks,
        arrowsColor,
        isHomepage,
        bottomLinkHref,
        bottomLinkText
    } = blok;
    const { isTabletViewOrLarger } = useMediaQueryContext();
    const scrollRef = useRef<typeof StyledScrollContainer>(null);

    const [direction, setDirection] = useState<"next" | "prev" | null>(null);

    useEffect(() => {
        if (!direction) return;

        calculateScrollDistance({ direction, scrollRef });

        setDirection(null);
    }, [direction]);

    return (
        <StyledContainer isHomepage={isHomepage}>
            <StyledHeading>{title}</StyledHeading>

            {!isTabletViewOrLarger ? (
                <Links>
                    {firstRowLinks.map((link, index) => (
                        <FramerMotionWrapper
                            key={index}
                            playWhenInView
                            animationType={AnimationStyle.FadeInFromBottom}
                            duration={0.15}
                            delay={index * 0.15}
                        >
                            <LinkWrapper>
                                <ImageWrapper>
                                    <Image
                                        src={link.avatar.filename}
                                        objectFit="cover"
                                        layout="fill"
                                    />
                                </ImageWrapper>
                                <Link legacyBehavior href={link.url} passHref>
                                    <Title>{link.title}</Title>
                                </Link>
                                <Icon
                                    icon={ARROW_RIGHT}
                                    size={24}
                                    color={
                                        arrowsColor || "var(--color-orange-60)"
                                    }
                                />
                            </LinkWrapper>
                        </FramerMotionWrapper>
                    ))}
                    {secondRowLinks &&
                        secondRowLinks.map((link, index) => (
                            <FramerMotionWrapper
                                key={index}
                                playWhenInView
                                animationType={AnimationStyle.FadeInFromBottom}
                                duration={0.15}
                                delay={index * 0.15 + 0.75}
                            >
                                <LinkWrapper>
                                    <ImageWrapper>
                                        <Image
                                            src={link.avatar.filename}
                                            objectFit="cover"
                                            layout="fill"
                                        />
                                    </ImageWrapper>
                                    <Link
                                        legacyBehavior
                                        href={link.url}
                                        passHref
                                    >
                                        <Title>{link.title}</Title>
                                    </Link>
                                    <Icon
                                        icon={ARROW_RIGHT}
                                        size={24}
                                        color={
                                            arrowsColor ||
                                            "var(--color-orange-60)"
                                        }
                                    />
                                </LinkWrapper>
                            </FramerMotionWrapper>
                        ))}
                </Links>
            ) : (
                <>
                    <Wrapper>
                        <StyledScrollContainer
                            ref={scrollRef}
                            horizontal
                            vertical={false}
                        >
                            {firstRowLinks.map((link, index) => (
                                <FramerMotionWrapper
                                    key={index}
                                    playWhenInView
                                    animationType={AnimationStyle.FadeIn}
                                    duration={0.15}
                                    delay={index * 0.15}
                                >
                                    <LinkWrapper>
                                        <ImageWrapper>
                                            <Image
                                                src={link.avatar.filename}
                                                objectFit="cover"
                                                layout="fill"
                                            />
                                        </ImageWrapper>
                                        <Title>{link.title}</Title>
                                        <Link
                                            legacyBehavior
                                            href={link.url}
                                            passHref
                                        >
                                            <IconBackground>
                                                <Icon
                                                    icon={ARROW_RIGHT}
                                                    size={24}
                                                    color="var(--color-black)"
                                                />
                                            </IconBackground>
                                        </Link>
                                    </LinkWrapper>
                                </FramerMotionWrapper>
                            ))}
                        </StyledScrollContainer>
                    </Wrapper>

                    {secondRowLinks && secondRowLinks.length !== 0 && (
                        <Wrapper>
                            <StyledScrollContainer horizontal vertical={false}>
                                {secondRowLinks.map((link, index) => (
                                    <FramerMotionWrapper
                                        key={index}
                                        playWhenInView
                                        animationType={AnimationStyle.FadeIn}
                                        duration={0.15}
                                        delay={index * 0.15}
                                    >
                                        <LinkWrapper>
                                            <ImageWrapper>
                                                <Image
                                                    src={link.avatar.filename}
                                                    objectFit="cover"
                                                    layout="fill"
                                                />
                                            </ImageWrapper>
                                            <Title>{link.title}</Title>
                                            <Link
                                                legacyBehavior
                                                href={link.url}
                                                passHref
                                            >
                                                <IconBackground>
                                                    <Icon
                                                        icon={ARROW_RIGHT}
                                                        size={24}
                                                        color="var(--color-black)"
                                                    />
                                                </IconBackground>
                                            </Link>
                                        </LinkWrapper>
                                    </FramerMotionWrapper>
                                ))}
                            </StyledScrollContainer>
                        </Wrapper>
                    )}
                </>
            )}

            <BottomLinkAndIndicators>
                {bottomLinkHref && bottomLinkText && (
                    <StyledLink
                        href={
                            bottomLinkHref?.url ||
                            bottomLinkHref?.cached_url ||
                            ""
                        }
                    >
                        {bottomLinkText} <Icon icon={ARROW_RIGHT} size={24} />
                    </StyledLink>
                )}

                <Indicators>
                    <Indicator onClick={() => setDirection("prev")}>
                        <Icon
                            icon={ARROW_LEFT}
                            size={40}
                            color="var(--color-orange-60)"
                        />
                    </Indicator>

                    <Indicator onClick={() => setDirection("next")}>
                        <Icon
                            icon={ARROW_RIGHT}
                            size={40}
                            color="var(--color-orange-60)"
                        />
                    </Indicator>
                </Indicators>
            </BottomLinkAndIndicators>
        </StyledContainer>
    );
}
