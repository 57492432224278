import { LazyMotion, domAnimation, m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useEffect, useState } from "react";

import { useMediaQueryContext } from "helpers/hooks";

export type SlideInAnimationWrapperProps = {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    children?: React.ReactNode;
    playWhenInView?: boolean;
    delay?: number;
    duration?: number;
    animationType: AnimationStyle;
};

export enum AnimationStyle {
    SlideInFromLeft = "slide-in-from-left",
    SlideInFromRight = "slide-in-from-right",
    FadeInFromBottom = "fade-in-from-bottom",
    FadeIn = "fade-in",
    GrowInWidth = "grow-in-width",
    ScaleIn = "scale-in"
}

export function FramerMotionWrapper({
    className,
    children,
    playWhenInView,
    delay = 0,
    duration = 1,
    animationType = AnimationStyle.SlideInFromLeft
}: SlideInAnimationWrapperProps): JSX.Element {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const [isSafari, setIsSafari] = useState(false);
    const { isMobileView } = useMediaQueryContext();

    useEffect(() => {
        if (playWhenInView && inView) {
            controls.start("visible");
        } else if (!playWhenInView) {
            controls.start("visible");
        }
    }, [controls, inView, playWhenInView]);

    let animationVariants;
    let exitVariants;

    switch (animationType) {
        case AnimationStyle.SlideInFromLeft: {
            animationVariants = {
                hidden: { opacity: 0, originX: 0, x: "-20" },
                visible: { opacity: 1, originX: 0, x: 0 }
            };
            exitVariants = {
                opacity: 0,
                originX: 0,
                x: "-20"
            };
            break;
        }
        case AnimationStyle.SlideInFromRight: {
            animationVariants = {
                hidden: { opacity: 0, originX: 0, x: "20" },
                visible: { opacity: 1, originX: 0, x: 0 }
            };
            exitVariants = {
                opacity: 0,
                originX: 0,
                x: "20"
            };
            break;
        }
        case AnimationStyle.FadeIn: {
            animationVariants = {
                hidden: { opacity: 0 },
                visible: { opacity: 1 }
            };
            exitVariants = {
                opacity: 0
            };
            break;
        }
        case AnimationStyle.FadeInFromBottom: {
            animationVariants = {
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
            };
            exitVariants = {
                opacity: 0,
                y: 20
            };
            break;
        }
        case AnimationStyle.GrowInWidth: {
            animationVariants = {
                hidden: { opacity: 0, width: 0 },
                visible: {
                    opacity: 1,
                    width: "100%"
                }
            };
            exitVariants = {
                opacity: 0
            };
            break;
        }
        case AnimationStyle.ScaleIn: {
            animationVariants = {
                hidden: { opacity: 0, scale: 1.1 },
                visible: { opacity: 1, scale: 1 }
            };
            exitVariants = {
                opacity: 0,
                scale: 1.1
            };
            break;
        }
    }

    // Remove Framermotion if you are on safari, current implementation doesn't
    // really like old(er) macs in combination with old(er) iOS
    useEffect(() => {
        if (navigator.vendor === "Apple Computer, Inc.") {
            setIsSafari(true);
        }
    }, [isSafari]);

    return (
        <>
            {!isMobileView && (
                <LazyMotion features={domAnimation}>
                    <m.div
                        className={className}
                        ref={ref}
                        animate={controls}
                        exit={exitVariants}
                        initial="hidden"
                        transition={{
                            delay: isSafari ? 0 : delay,
                            duration: isSafari ? 0 : duration
                        }}
                        variants={animationVariants}
                    >
                        {children}
                    </m.div>
                </LazyMotion>
            )}
            {isMobileView && <div className={className}>{children}</div>}
        </>
    );
}
