import styled from "styled-components";

import { media } from "constants/media";

export const StyledP = styled.p`
    margin: 16px 0;
`;

export const StyledUL = styled.ul`
    li {
        margin-bottom: 8px;
        margin-left: 16px;

        ${media.mdUp} {
            margin-left: 16px;
        }
    }
`;

export const StyledOL = styled.ol`
    li {
        margin-bottom: 8px;
        margin-left: 16px;

        ${media.mdUp} {
            margin-left: 16px;
        }
    }
`;
