import styled from "styled-components";

import { Container as BaseContainer } from "components/ui";

import { media } from "constants/media";

export const Section = styled.section`
    background-color: var(--color-orange-60);
    color: var(--color-white);
    padding: 40px 0;
`;

export const Container = styled(BaseContainer)`
    flex-direction: column;
    gap: 32px;

    ${media.lgUp} {
        flex-direction: row;
        gap: 152px;
    }

    > ul,
    > div {
        flex: 1;
    }
`;
