import { Button, ServicesPageProps } from "storyblok/components";
import Image from "next/legacy/image";

import {
    BackgroundEllipse,
    ButtonsWrapper,
    ServiceContentWrapper,
    ServiceIconImageWrapper,
    ServiceImage,
    StyledServiceContainer,
    StyledServiceDescription,
    StyledServiceHeading,
    StyledServicesPage,
    StyledStoryblokButton
} from "components/services/servicesPage/ServicesPage.styled";
import AvatarsContainer from "components/shared/avatars/AvatarsContainer";

import { useMediaQueryContext } from "helpers/hooks";

function ServicesPage({ blok }: ServicesPageProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();

    const { services } = blok;

    return (
        <StyledServicesPage>
            <BackgroundEllipse />
            <AvatarsContainer blok={blok} />
            {services &&
                services.map((service, index: number) => {
                    const {
                        title,
                        description,
                        mainImage,
                        iconImage,
                        buttons
                    } = service;

                    const isEven = index % 2 == 0;

                    return (
                        <StyledServiceContainer
                            key={service._uid}
                            isEven={isEven}
                        >
                            <ServiceImage isEven={isEven}>
                                {mainImage && (
                                    <Image
                                        alt={mainImage.alt}
                                        src={mainImage.filename}
                                        layout="fill"
                                        objectFit="cover"
                                    />
                                )}
                            </ServiceImage>
                            <ServiceContentWrapper isEven={isEven}>
                                <ServiceIconImageWrapper>
                                    {iconImage && (
                                        <Image
                                            alt={iconImage.alt}
                                            src={iconImage.filename}
                                            objectFit="cover"
                                            height={isMobileView ? 64 : 104}
                                            width={isMobileView ? 64 : 104}
                                        />
                                    )}
                                </ServiceIconImageWrapper>
                                <StyledServiceHeading headingLevel={1}>
                                    {title}
                                </StyledServiceHeading>
                                <StyledServiceDescription>
                                    {description}
                                </StyledServiceDescription>
                                {buttons && (
                                    <ButtonsWrapper>
                                        {buttons.map((entry: Button) => {
                                            return (
                                                entry.component ===
                                                    "Button" && (
                                                    <StyledStoryblokButton
                                                        key={entry._uid}
                                                        {...entry}
                                                    />
                                                )
                                            );
                                        })}
                                    </ButtonsWrapper>
                                )}
                            </ServiceContentWrapper>
                        </StyledServiceContainer>
                    );
                })}
        </StyledServicesPage>
    );
}

export default ServicesPage;
