import { MutableRefObject } from "react";
import { Video } from "storyblok/components";
import Image from "next/legacy/image";
import useTranslation from "next-translate/useTranslation";

import { CurrentVideoState } from "components/homepage/videos/HomepageVideos";
import { VideoPlayerMethods, VisuallyHidden } from "components/ui";

import {
    ButtonLabel,
    List,
    ListItem,
    PlayIcon,
    SelectVideoButton,
    StyledContainer
} from "./VideoList.styled";

import { useMediaQueryContext } from "helpers/hooks";

interface VideoListProps {
    videos: Array<Video>;
    playerRef: MutableRefObject<VideoPlayerMethods>;
    activeVideo: CurrentVideoState;
    setVideo: ({ url, poster }: CurrentVideoState) => void;
    rounded?: boolean;
}

export function VideoList({
    videos,
    activeVideo,
    setVideo,
    playerRef,
    rounded
}: VideoListProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const { t } = useTranslation();

    function switchVideo(video: string, poster: string): void {
        setVideo({
            poster: poster,
            url: video
        });
        playerRef.current?.playVideo(true);
    }

    return (
        <StyledContainer>
            <List>
                {videos.map(({ _uid, video, poster, title }: Video) => {
                    const isActive = activeVideo.url === video?.filename;

                    return (
                        <ListItem key={_uid}>
                            <SelectVideoButton
                                rounded={rounded}
                                onClick={() =>
                                    switchVideo(video.filename, poster.filename)
                                }
                                disabled={isActive}
                            >
                                {!isActive && (
                                    <PlayIcon
                                        aria-hidden="true"
                                        size={isMobileView ? 20 : 32}
                                    />
                                )}
                                {isMobileView ? (
                                    <ButtonLabel>
                                        {title ? title : video.title}
                                    </ButtonLabel>
                                ) : (
                                    <VisuallyHidden>
                                        {t("common:playvideo", {
                                            name: video.title
                                        })}
                                    </VisuallyHidden>
                                )}
                                <Image
                                    src={poster?.filename}
                                    layout="fill"
                                    objectFit="cover"
                                    alt={poster?.alt}
                                />
                            </SelectVideoButton>
                            {!isMobileView && title ? title : null}
                        </ListItem>
                    );
                })}
            </List>
        </StyledContainer>
    );
}
