import styled from "styled-components";

import { media } from "constants/media";

export const StyledTooltip = styled.div`
    position: relative;
    width: fit-content;
`;

export const ToolTipContent = styled.div`
    background-color: var(--color-primary);
    border-radius: 4px;
    color: var(--color-white);
    left: 50%;
    padding: 16px 24px;
    position: absolute;
    top: -8px;
    transform: translate(-50%, -100%);
    width: 280px;

    ${media.mdUp} {
        /* display gets overwritten on button hover */
        display: none;
        font-size: 15px;
        line-height: 32px;
    }

    &::before {
        background-color: var(--color-primary);
        bottom: -7px;
        content: "";
        height: 8px;
        left: calc(50% - 3px);
        position: absolute;
        transform: rotate(45deg) translateX(-50%);
        width: 8px;
    }
`;

export const TooltipButton = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--color-grey);
    cursor: pointer;
    display: flex;
    outline: 0;
    padding: 0;
    pointer-events: auto;
    transition: color var(--default-transition-duration) ease;

    &:hover {
        color: var(--color-primary);

        ${media.mdUp} {
            + ${ToolTipContent} {
                display: block;
            }
        }
    }
`;
