import { HomepageServicesProps, ServiceBlock } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

import {
    ARROW_RIGHT,
    AnimationStyle,
    FramerMotionWrapper,
    Icon,
    Icons
} from "components/ui";

import {
    ServiceList,
    StyledContainer,
    StyledHeading,
    StyledIcon,
    StyledServiceBlock,
    StyledTitle,
    Text
} from "./HomepageServices.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function HomepageServices({ blok }: HomepageServicesProps): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();
    const { serviceBlocks, title } = blok;

    return (
        <section>
            <StyledContainer {...storyblokEditable(blok)}>
                <StyledTitle>{title}</StyledTitle>
                <ServiceList>
                    {serviceBlocks.map(
                        (
                            { link, icon, title, text }: ServiceBlock,
                            index: number
                        ) => (
                            <Link
                                legacyBehavior
                                href={link}
                                passHref
                                key={index}
                                prefetch={false}
                            >
                                <StyledServiceBlock>
                                    <FramerMotionWrapper
                                        playWhenInView
                                        animationType={
                                            AnimationStyle.FadeInFromBottom
                                        }
                                        duration={0.5}
                                        delay={index * 0.25}
                                    >
                                        <StyledIcon
                                            icon={Icons[icon]}
                                            size={isDesktopView ? 48 : 32}
                                            aria-hidden="true"
                                        />
                                        <StyledHeading>{title}</StyledHeading>
                                        <Text>{text}</Text>
                                        <Icon
                                            icon={ARROW_RIGHT}
                                            size={24}
                                            color="var(--color-black)"
                                        />
                                    </FramerMotionWrapper>
                                </StyledServiceBlock>
                            </Link>
                        )
                    )}
                </ServiceList>
            </StyledContainer>
        </section>
    );
}
