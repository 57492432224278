import { Icon, STAR } from "components/ui";

import { Counter, IconWrapper, Pill } from "./FooterUsp.styled";

export type FooterUspType = "experience" | "award";

type FooterUspProps = {
    type: FooterUspType;
    title: string;
};

const currentYear: number = new Date().getFullYear();
const yearsOfExperience: number = currentYear - 2007;

export function FooterUsp({ type, title }: FooterUspProps): JSX.Element {
    return (
        <Pill>
            <IconWrapper type={type}>
                {type === "award" ? (
                    <img
                        src="/images/logos/worldclass-workplace-2023-2024.svg"
                        alt="Great  place to work award"
                        width={39}
                        height={46}
                        loading="lazy"
                    />
                ) : (
                    <Icon
                        icon={STAR}
                        size={32}
                        color="var(--color-orange-50)"
                        aria-hidden="true"
                    />
                )}
                {type === "experience" && (
                    <Counter>{yearsOfExperience}+</Counter>
                )}
            </IconWrapper>
            {title}
        </Pill>
    );
}
