import styled from "styled-components";

import { media } from "constants/media";

// This is component placed in this file so we can call it correctly for (odd) styling
export const StyledTermTableRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;

    ${media.mdUp} {
        flex-direction: row;
        padding: 0;
    }
`;

export const StyledTermTable = styled.table`
    border-spacing: 0;
    // Negative margin-left because of parent padding
    margin-left: -24px;
    width: calc(100% + 48px);

    /* stylelint-disable */
    ${StyledTermTableRow}:nth-child(odd) {
        background-color: var(--color-grey-45);
    }
    /* stylelint-enable */

    ${media.mdUp} {
        margin: 0;
        width: 100%;
    }
`;
