import styled, { css } from "styled-components";

import { media } from "constants/media";

export const StyledTextAreaWrapper = styled.div`
    background-color: var(--color-primary-light);
    display: initial;
    vertical-align: top;
    width: 100%;

    p:last-child {
        margin-top: 8px;
    }
`;

export const TextAreaContainer = styled.div<{
    hasError?: boolean;
    canResize?: boolean;
}>`
    border-radius: 6px;
    display: flex;
    height: 100%;
    min-height: 120px;
    position: relative;

    > span {
        display: block;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ canResize }) =>
        canResize &&
        css`
            &::after {
                border-left: 1px solid var(--color-primary-dark);
                border-right: 1px solid var(--color-primary-dark);
                bottom: -1px;
                content: "";
                display: block;
                height: 8px;
                position: absolute;
                right: 1px;
                transform: rotate(45deg);
                width: 2px;
            }
        `} /* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */

    ${media.mdUp} {
        min-width: 320px;
    }
`;

export const StyledTextArea = styled.textarea<{
    hasError?: boolean;
    disabled?: boolean;
}>`
    background-color: var(--color-light-grey);
    border: 0;
    border-radius: 32px;
    box-sizing: border-box;
    color: var(--color-grey-60);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    line-height: 24px;
    margin: 0;
    min-height: 120px;
    outline: none;
    padding: ${props => (props.hasError ? "16px 40px 16px 16px" : "16px")};
    position: relative;
    resize: none;
    transition: border-color var(--animation-medium-entry-duration);
    width: 100%;

    &::-webkit-scrollbar {
        background: transparent;
        cursor: pointer;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--color-secondary-dark);
        background-clip: padding-box;
        border-radius: 64px;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-button {
        background: transparent;
        display: block;
        height: 8px;
    }

    &::after {
        align-items: center;
        content: "";
        display: block;
        height: 30px;
        justify-content: center;
        position: absolute;
        width: 30px;
    }

    &:focus {
        background-color: var(--color-white);
        box-shadow: 0 0 6px var(--color-primaryLight);
        outline: none;
    }
`;

export const LabelWrapper = styled.div`
    color: var(--color-black);
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
`;

export const StyledErrorMessage = styled.p`
    color: var(--color-red-50);
    font-size: var(--type-sub-text-font-size-sub-text);
    font-weight: 800;
    line-height: var(--type-sub-text-line-height);
    margin-top: 4px;
`;
