import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const StyledContainer = styled(Container)`
    align-items: center;
    color: var(--color-white);
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
    max-width: 832px;

    h2,
    p {
        color: var(--color-white);
        text-align: center;
    }

    ${media.mdUp} {
        margin-bottom: 56px;
        text-align: left;
    }
`;
