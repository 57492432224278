import styled from "styled-components";

import { Button } from "components/ui";

import { media } from "constants/media";

export const StyledDialog = styled.dialog`
    background-color: var(--color-white);
    border: unset;
    border-radius: 20px;
    margin: auto 16px 16px;
    max-height: 66vh;
    padding: 16px;
    z-index: var(--z-index-modal);

    ${media.mdUp} {
        margin: auto 24px 24px auto;
        max-width: 60vw;
        padding: 24px;
    }

    ${media.lgUp} {
        margin: auto 24px 24px auto;
        max-width: 40vw;
    }

    ${media.xlUp} {
        padding: 24px 36px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PopUpContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 400px;

    p {
        margin: 0;
    }

    ${media.mdUp} {
        gap: 16px;
        max-width: 50vw;
    }
`;

export const ImageWrapper = styled.div`
    align-items: start;
    background-color: var(--color-white);
    height: 64px;
    margin-top: -36px;

    img {
        max-width: 80%;
        width: unset;
    }

    ${media.mdUp} {
        top: -24px;
    }
`;

export const Title = styled.p`
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    line-height: 28px;
    text-align: center;
    width: auto;

    ${media.mdUp} {
        line-height: 32px;
    }

    ${media.lgUp} {
        line-height: 36px;
        text-align: start;
    }
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
    }
`;

export const StyledButton = styled(Button)`
    justify-content: center;

    ${media.mdUp} {
        width: fit-content;
    }
`;

export const CloseButton = styled.button`
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--color-white);
    border: unset;
    border-radius: 100%;
    color: var(--color-black);
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    margin: 0 0 auto auto;
    position: sticky;
    top: 0;
    transition: var(--default-transition-duration) ease;
    z-index: 1;

    &:hover {
        background-color: var(--color-orange-60);
        color: var(--color-white);
    }
`;

export const RichtextRenderWrapper = styled.div`
    height: 150px;
    overflow-y: scroll;

    p {
        line-height: 18px;
    }

    li {
        margin-bottom: 2px;
        margin-left: 24px;
    }

    ${media.mdUp} {
        height: 300px;
    }

    ${media.lgUp} {
        height: 200px;
    }
`;
