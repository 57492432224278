import {
    MARK_BOLD,
    NODE_OL,
    NODE_PARAGRAPH,
    NODE_UL,
    StoryblokRichtext,
    render
} from "storyblok-rich-text-react-renderer";

import {
    StyledOL,
    StyledP,
    StyledUL
} from "components/shared/richTextRender/RichTextRender.styled";

export function RichTextRender({ content }: StoryblokRichtext): JSX.Element {
    return (
        <>
            {render(content, {
                markResolvers: {
                    [MARK_BOLD]: children => <strong>{children}</strong>
                },
                nodeResolvers: {
                    [NODE_OL]: children => <StyledOL>{children}</StyledOL>,
                    [NODE_PARAGRAPH]: children => (
                        <StyledP>
                            {children === null ? <br /> : children}
                        </StyledP>
                    ),
                    [NODE_UL]: children => <StyledUL>{children}</StyledUL>
                }
            })}
        </>
    );
}
