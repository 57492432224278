import styled from "styled-components";

import { media } from "constants/media";

export const StyledAboutUsPage = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    ::before {
        //stylelint-disable
        background: linear-gradient(
            rgba(243, 144, 0) 0%,
            rgba(251, 188, 18) 100%
        );

        //stylelint-enable
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        ${media.mdUp} {
            height: calc(100% - 550px);
            top: 550px;
        }
    }
`;
