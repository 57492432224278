import useTranslation from "next-translate/useTranslation";

import { Button, Line } from "./NavTogggle.styled";

import { useMenuContext } from "helpers/hooks/useMenuContext";

type NavToggleProps = {
    stickyHeader?: boolean;
};

export function NavToggle({ stickyHeader }: NavToggleProps): JSX.Element {
    const { t } = useTranslation();
    const {
        isMobileMenuActive,
        setActiveItem,
        setIsMobileMenuActive,
        setIsSubMenuActive,
        isSubMenuActive
    } = useMenuContext();

    function handleClick(isActive: boolean): void {
        setIsMobileMenuActive(isActive);

        if (!isActive) {
            setIsSubMenuActive(false);
            setActiveItem(null);
        }
    }

    return (
        <Button
            aria-expanded={isMobileMenuActive}
            aria-label={
                isMobileMenuActive
                    ? t("common:openmenu")
                    : t("common:closemenu")
            }
            aria-haspopup="true"
            aria-controls="navigation"
            role="button"
            isActive={isMobileMenuActive}
            onClick={() => handleClick(!isMobileMenuActive)}
            isVisible={!isMobileMenuActive || !isSubMenuActive}
            stickyHeader={stickyHeader}
        >
            <Line isActive={isMobileMenuActive} />
            <Line isActive={isMobileMenuActive} />
            <Line isActive={isMobileMenuActive} />
        </Button>
    );
}
