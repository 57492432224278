import React, { MutableRefObject, useEffect, useRef } from "react";

import { AllStories } from "./submenuItem/allStories";
import {
    Column,
    PrimaryMenu,
    PrimaryMenuItem,
    SecondaryMenu,
    StyledContainer,
    Title,
    Wrapper
} from "./PrimarySubMenu.styled";
import { SubMenuItemType, SubmenuItem } from "./submenuItem/SubmenuItem";

import { useBodyScrollLock, useMediaQueryContext } from "helpers/hooks";
import { useMenuContext } from "helpers/hooks/useMenuContext";

function getAllStoryImages(menu?: Array<SubMenuItemType>): Array<string> {
    const items: Array<string> = [];

    if (menu) {
        menu.forEach(item => {
            if (item.icon && typeof item.icon === "string") {
                items.push(item.icon);
            }
        });
    }

    return items;
}

function getColumnSize(hasSecondaryMenu: boolean, isDesktop: boolean): number {
    if (isDesktop) {
        return hasSecondaryMenu ? 2 : 4;
    } else {
        return 2;
    }
}

export type SubMenuType = {
    title: string;
    items: Array<SubMenuItemType>;
};

type PrimarySubMenuProps = {
    id: string;
    primary: SubMenuType;
    secondary?: SubMenuType;
};

export function PrimarySubMenu({
    id,
    primary,
    secondary
}: PrimarySubMenuProps): JSX.Element {
    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const { isDesktopView } = useMediaQueryContext();
    const { activeItem, setMenuHeight } = useMenuContext();
    const isOpen: boolean = id === activeItem;
    const hasSecondaryMenu: boolean = secondary !== undefined;
    const columns: number = hasSecondaryMenu ? 2 : 1;
    const primaryMenuGridSize: number = getColumnSize(
        hasSecondaryMenu,
        isDesktopView
    );

    useEffect(
        function setForegroundHeight() {
            const submenuHeight: number = ref.current.clientHeight || 0;

            if (isOpen) {
                setMenuHeight(submenuHeight);
            }
        },
        [isOpen, ref, setMenuHeight]
    );

    useBodyScrollLock(isOpen);

    return (
        <Wrapper
            id={`menu-${id}`}
            role={isDesktopView ? "group" : "tabpanel"}
            aria-hidden={!isOpen}
            ref={ref}
            isOpen={isOpen}
        >
            <StyledContainer columns={columns}>
                <Column>
                    <Title>{primary.title}</Title>
                    <PrimaryMenu columns={primaryMenuGridSize}>
                        {primary.items.map(
                            (item: SubMenuItemType, index: number) => {
                                const fullWidth = [
                                    "heading",
                                    "inverted-card"
                                ].includes(item.type);

                                return (
                                    <PrimaryMenuItem
                                        key={`${index}-${item.title}`}
                                        columns={
                                            fullWidth ? primaryMenuGridSize : 1
                                        }
                                    >
                                        <SubmenuItem
                                            type={item.type}
                                            href={item.href}
                                            title={item.title}
                                            icon={item.icon}
                                            description={item.description}
                                        />
                                    </PrimaryMenuItem>
                                );
                            }
                        )}
                    </PrimaryMenu>
                </Column>
                {secondary && (
                    <Column>
                        <Title>{secondary.title}</Title>
                        <SecondaryMenu
                            type={id === "about-us" ? "story" : "default"}
                        >
                            {secondary.items?.map((item: SubMenuItemType) => (
                                <li key={`${secondary.title}-${item.title}`}>
                                    <SubmenuItem
                                        href={item.href}
                                        title={item.title}
                                        type={item.type}
                                        icon={item.icon}
                                        description={item.description}
                                    />
                                </li>
                            ))}
                            {id === "about-us" && (
                                <li>
                                    <AllStories
                                        images={getAllStoryImages(
                                            secondary?.items
                                        )}
                                    />
                                </li>
                            )}
                        </SecondaryMenu>
                    </Column>
                )}
            </StyledContainer>
        </Wrapper>
    );
}
