import { m } from "framer-motion";
import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.div`
    aspect-ratio: 272 / 236;
    max-height: 340px;
    min-height: 272px;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        aspect-ratio: unset;
        height: 330px;
        max-height: none;
        position: absolute;
        right: -25px;
        top: -50px;
        width: 381px;
    }

    ${media.lgUp} {
        height: 454px;
        right: -25px;
        top: 0;
        width: 466px;
    }

    ${media.xlUp} {
        height: 538px;
        right: 0;
        width: 621px;
    }
`;

export const Avatar = styled(m.div)`
    aspect-ratio: 1;
    border: 3px solid var(--color-orange-60);
    border-radius: 100%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 25%;

    ${media.lgUp} {
        border-width: 4px;
    }

    :nth-of-type(1) {
        left: 38.4%;
        top: 29.41%;
        width: 32.21%;
    }

    :nth-of-type(2) {
        left: 28.59%;
        top: 0;
        width: 18.68%;
    }

    :nth-of-type(3) {
        left: 65.36%;
        top: 5.23%;
        width: 17.4%;
    }

    :nth-of-type(4) {
        left: 83.9%;
        top: 31.05%;
        width: 16.1%;
    }

    :nth-of-type(5) {
        left: 84.48%;
        top: 60.46%;
        width: 14.81%;
    }

    :nth-of-type(6) {
        left: 56.36%;
        top: 71.82%;
        width: 14.81%;
    }

    :nth-of-type(7) {
        left: 18.36%;
        top: 62.8%;
        width: 18.36%;
    }

    :nth-of-type(8) {
        left: 0;
        top: 23.67%;
        width: 24.48%;
    }
`;
