export function getEducationCode(education_code: string | undefined | null) {
    switch (education_code) {
        case "master_degree":
        case "WO":
            return "WO";
        case "vocational":
        case "VPK 5":
        case "vpk_5":
        case "vpk_4":
        case "verzorgende_ig":
        case "helpende_plus":
        case "MBO":
            return "MBO";
        case "high_school":
        case "In opleiding":
            return "In opleiding";
        case "bachelor_degree":
        case "HBO":
            return "HBO";
        case "certification":
        case "Gecertificeerd":
            return "Gecertificeerd";
        default:
            return education_code;
    }
}
