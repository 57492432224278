import styled, { css } from "styled-components";

import { ContainerType } from "./Container";

import { media, sizes } from "constants/media";

const gridStyling = css`
    grid-gap: 16px 0;
    grid-template-columns: repeat(4, 1fr);

    ${media.mdUp} {
        grid-gap: 0 24px;
        grid-template-columns: repeat(8, 1fr);
    }

    ${media.lgUp} {
        grid-template-columns: repeat(12, 1fr);
    }

    ${media.xlUp} {
        grid-gap: 0 48px;
    }
`;

const gutterWidth = 24;

export const StyledContainer = styled.div<{ type: ContainerType }>`
    display: ${({ type }) => type};
    margin-left: auto;
    margin-right: auto;
    padding: 0 ${gutterWidth}px;
    position: relative;
    width: 100%;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ type }) => type === "grid" && gridStyling}

    ${media.mdUp} {
        max-width: ${sizes.md + gutterWidth * 2}px;
    }

    ${media.lgUp} {
        max-width: ${sizes.lg + gutterWidth * 2}px;
    }

    ${media.xlUp} {
        max-width: ${sizes.xl + gutterWidth * 2}px;
    }
`;
