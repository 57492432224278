import styled, { css } from "styled-components";

import { Icon } from "components/ui";

import { media } from "constants/media";

export const IconWrapper = styled.div`
    align-items: center;
    display: inline-flex;
    flex: 0 0 40px;
    justify-content: center;
`;

export const StyledIcon = styled(Icon)`
    fill: var(--color-orange-50);
    max-height: 32px;
    stroke: var(--color-orange-50);
    transition: all var(--quick-transition-duration) ease;

    ${media.mdUp} {
        max-height: 38px;
    }

    ${media.lgUp} {
        fill: var(--color-grey-55);
        stroke: var(--color-grey-55);
    }
`;

export const Title = styled.strong`
    display: block;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;

    ${media.mdUp} {
        margin-bottom: 6px;
    }

    ${media.xlUp} {
        font-size: 18px;
    }
`;

export const Description = styled.span`
    display: block;
    font-size: 13px;
    line-height: 16px;

    ${media.lgUp} {
        color: var(--color-grey-55);
        font-size: 14px;
        line-height: 18px;
    }
`;

function determineCardStyling(invert: boolean) {
    switch (invert) {
        case true:
            return css`
                background-color: var(--color-orange-50);
                color: var(--color-white);
                padding: 12px;

                ${StyledIcon} {
                    fill: var(--color-white);
                    stroke: var(--color-white);
                }

                /* stylelint-disable-next-line */
                ${Title},
                ${Description} {
                    color: var(--color-white);
                }
            `;
        case false:
        default:
            return css`
                @media (any-hover: hover) {
                    :hover,
                    :focus,
                    :active {
                        background-color: var(--color-grey-45);

                        ${StyledIcon} {
                            fill: var(--color-orange-50);
                            stroke: var(--color-orange-50);
                        }
                    }
                }
            `;
    }
}

export const Anchor = styled.a<{ invert: boolean }>`
    align-items: center;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ invert }) => determineCardStyling(invert)}

    ${media.mdUp} {
        gap: 16px;
    }

    ${media.lgUp} {
        gap: 12px;
        padding: 12px;
        width: fit-content;
    }
`;

export const Content = styled.div`
    flex: 1 1 auto;
`;
