import { ChangeEventHandler, ForwardedRef, forwardRef } from "react";

import {
    ButtonLabel,
    CheckboxInput,
    Label,
    StyledToggleCheckbox,
    Switch,
    SwitchWrapper
} from "./Toggle.styled";

export interface ToggleProps {
    /** Label for the checkbox. */
    label: string;
    /** Name used for tracking the value of the input, used by react-hook-forms. */
    name: string;
    /** Active value for the input, read by react-hook-forms. */
    activeValue: string | number;
    /** Toggle is disabled and stuck on its default value */
    disabled?: boolean;
    /** Current value of the checkbox, provided by react-hook-forms `watch(name)` */
    currentValue?: string | boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Toggle = forwardRef(function Checkbox(
    {
        label,
        name,
        activeValue,
        currentValue,
        disabled,
        ...otherProps
    }: ToggleProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    return (
        <StyledToggleCheckbox>
            <CheckboxInput
                disabled={disabled}
                id={name}
                name={name}
                ref={ref}
                readOnly
                type="checkbox"
                value={activeValue}
                {...otherProps}
            />
            <ButtonLabel disabled={disabled} htmlFor={name}>
                <SwitchWrapper isActive={currentValue === activeValue}>
                    <Switch isActive={currentValue === activeValue} />
                </SwitchWrapper>
                <Label disabled={disabled}>{label}</Label>
            </ButtonLabel>
        </StyledToggleCheckbox>
    );
});
