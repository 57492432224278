import styled from "styled-components";

import { Heading } from "components/ui";

import { media } from "constants/media";

export const TermArticleStyledHeader = styled(Heading)`
    margin-bottom: 8px;

    ${media.mdUp} {
        margin-bottom: 32px;
    }
`;
