import styled from "styled-components";

import { Avatar } from "components/shared/header/navigation/main/submenu/submenuItem/avatar";

import { media } from "constants/media";

export const StyledAvatar = styled(Avatar)`
    margin-right: 16px;
`;

export const Anchor = styled.a`
    align-items: center;
    color: var(--color-black);
    display: inline-flex;
    flex-direction: row;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease;

    ${media.lgUp} {
        :hover,
        :focus,
        :active {
            color: var(--color-orange-50);

            ${StyledAvatar} {
                border-color: var(--color-orange-50);
            }
        }
    }
`;

export const Title = styled.strong`
    color: var(--color-grey-55);
    display: block;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;

    ${media.mdUp} {
        font-size: 14px;
    }
`;

export const Description = styled.span`
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 15px;
    }
`;
