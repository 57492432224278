import styled from "styled-components";

import { Container as BaseContainer } from "components/ui/container";

import { media } from "constants/media";

export const Container = styled(BaseContainer)`
    align-items: center;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 40px 24px;

    h2 {
        font-weight: var(--font-weight-semibold);
    }

    p {
        font-family: var(--font-secondary);
        font-size: var(--type-paragraph-large-font-size);
        line-height: var(--type-paragraph-large-line-height);
        margin: auto;
        max-width: 728px;
        text-align: center;
    }

    ${media.mdUp} {
        padding: 80px 0;
    }
`;

export const ClientsGrid = styled.div`
    margin: 0 24px;

    ${media.mdUp} {
        margin: 0 48px;
    }
`;
