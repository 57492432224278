import styled from "styled-components";

import { Container, FramerMotionWrapper } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    overflow-y: hidden;
`;

export const StyledContainer = styled(Container)`
    margin: 80px auto;
    padding: 0;
    position: relative;
    width: 320px;
    z-index: var(--z-index-homepage-section);

    ${media.mdUp} {
        max-width: 1200px;
        padding: 0 24px;
        width: 100%;
    }

    ${media.lgUp} {
        max-width: 1200px;
        width: 100%;
    }
`;

export const Content = styled.div`
    background: var(--color-orange-40);
    border-radius: 165px;
    display: flex;
    flex-direction: column;
    min-height: 688px;

    ${media.mdUp} {
        border-radius: 200px;
        flex-direction: row;
        height: 320px;
        min-height: unset;
    }

    ${media.lgUp} {
        height: 400px;
        max-width: 1200px;
        width: 100%;

        h3 {
            max-width: 320px;
        }
    }
`;

export const Info = styled.span`
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;

    ${media.lgUp} {
        padding: 48px;
    }
`;

export const Text = styled.p`
    font-family: var(--font-secondary);
    font-size: 20px;
    line-height: 32px;
    margin-top: 16px;
`;

export const ImageWrapper = styled.div`
    img {
        border-radius: 50%;

        ${media.mdUp} {
            border-radius: 200px;
        }

        ${media.lgUp} {
            border-radius: 50%;
        }
    }
`;

export const ButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
    margin-top: 16px;

    /* stylelint-disable-next-line */
    ${media.lgUp} {
        gap: 24px;
    }
`;
export const StyledFramerMotionWrapper = styled(FramerMotionWrapper)`
    width: 100%;
`;
