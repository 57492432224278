import styled, { css } from "styled-components";

import { PillImageSize } from "./HeroPillImage";

import { media } from "constants/media";

function selectSizeStyles(size: PillImageSize.LARGE | PillImageSize.SMALL) {
    switch (size) {
        case PillImageSize.LARGE:
        case PillImageSize.SMALL:
        default:
            return css`
                border-radius: 1000px 0 0 1000px;
                flex: 1;
                height: 168px;
                margin-left: 24px;
                overflow: hidden;
                width: 100%;

                ${media.mdUp} {
                    align-self: flex-end;
                    flex: 1 0 348px;
                    height: 348px;
                    margin: 0;
                    width: 648px;
                }

                ${media.lgUp} {
                    flex: 1 0 696px;
                    height: 696px;
                    margin: 0;
                }
            `;
    }
}

export const ImageContainer = styled.div<{
    size: PillImageSize.LARGE | PillImageSize.SMALL;
}>`
    ${({ size }) => size && selectSizeStyles(size)};

    img {
        height: 100%;
        width: 100%;
    }
`;
