import { Wrapper } from "./HomepageBackground.styled";

interface HomepageBackgroundProps {
    children: React.ReactNode;
}

export function HomepageBackground({
    children
}: HomepageBackgroundProps): JSX.Element {
    return <Wrapper>{children}</Wrapper>;
}
