import {
    CircleSkeleton,
    RectangleSkeleton,
    StringSkeleton,
    TextSkeleton
} from "./skeletonTypes";

export type SkeletonProps = {
    skeletonType: "text" | "string" | "circle" | "rectangle";
    ready: boolean | string;
    size?: string;
    width?: string;
    height?: string;
    children?: React.ReactNode;
    rows?: number;
    className?: string;
};

export function Skeleton({
    className,
    skeletonType,
    ready = false,
    children,
    size = "32px",
    width = "32px",
    height = "32px",
    rows = 3
}: SkeletonProps): JSX.Element | null {
    if (ready && children) return <>{children}</>;
    if (ready && !children) return null;

    let passedProps;

    switch (skeletonType) {
        case "circle":
            passedProps = { className, size };
            return <CircleSkeleton {...passedProps} />;
        case "rectangle":
            passedProps = { className, height, width };
            return <RectangleSkeleton {...passedProps} />;
        case "string":
            passedProps = { className };
            return <StringSkeleton {...passedProps} />;
        case "text":
        default:
            passedProps = { className, rows };
            return <TextSkeleton {...passedProps} />;
    }
}
