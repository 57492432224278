import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const StyledFooter = styled.footer`
    background-color: var(--color-grey-45);
    flex-shrink: 0;
    min-height: 250px;
    padding: 32px 24px;
    width: 100vw;
    z-index: var(--z-index-footer);

    ${media.mdUp} {
        padding: 24px 0;
    }

    ${media.lgUp} {
        padding: 32px 0;
    }

    ${media.xlUp} {
        padding: 64px 0;
    }
`;

export const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 40px 0;

    ${media.mdUp} {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: space-between;
    }

    ${media.lgUp} {
        gap: 64px 24px;
    }

    ${media.xlUp} {
        gap: 64px 48px;
    }
`;

export const NavList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    ${media.xlUp} {
        li:first-of-type {
            margin: 16px 0 0;
        }
    }
`;

export const ListItem = styled.li`
    width: 100%;

    :not(:last-of-type) {
        border-bottom: 1px solid var(--color-grey-50);
    }
`;

export const Navigation = styled.nav`
    width: 100%;

    ${media.mdUp} {
        max-width: 264px;
    }
`;

export const Section = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.mdUp} {
        align-items: flex-start;
        justify-content: flex-end;
    }

    ${media.lgUp} {
        :nth-of-type(2) {
            min-width: 264px;
        }
    }
`;

export const Logo = styled.img`
    height: auto;
    margin-bottom: 16px;
    max-width: 259px;
    width: 100%;

    ${media.mdUp} {
        max-width: 186px;
    }

    ${media.xlUp} {
        max-width: 266px;
    }
`;

export const QualityMarks = styled.div`
    align-items: center;
    display: flex;
    gap: 48px;
    height: 75px;
    width: 100%;
`;

export const NBBULogo = styled.img`
    margin: 16px 0 0;

    ${media.mdUp} {
        margin: 16px 0;
    }
`;

export const ImageWrapper = styled.div`
    height: 75px;
    position: relative;
    width: 75px;
`;

export const ContactLink = styled.li`
    ${media.xlUp} {
        margin: 16px 0;
    }
`;

export const Address = styled.address`
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    width: 100%;

    ${media.mdUp} {
        font-size: 15px;
    }
`;

export const AddressLink = styled.a<{ noMargin?: boolean }>`
    display: inline-flex;
    font-weight: bold;
    gap: 16px;
    justify-content: center;
    margin-bottom: 8px;
    text-decoration: none;

    ${media.mdUp} {
        margin-bottom: ${({ noMargin }) => (noMargin ? "0" : "16px")};
    }
`;
