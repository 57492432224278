import { FC } from "react";
import { StoryblokArticle } from "@storyblok/react";
import Image from "next/legacy/image";
import Link from "next/link";

import { ARROW_RIGHT } from "components/ui";

import {
    Category,
    Content,
    Date,
    Footer,
    ImageWrapper,
    Item,
    StyledButton
} from "./Item.styled";

import { formatBlogDate } from "helpers/format/blog";

interface ItemsProps {
    showDate?: boolean;
    showCategory?: boolean;
    article: StoryblokArticle;
    isDouble: boolean;
}

const Items: FC<ItemsProps> = ({
    article,
    showCategory,
    showDate,
    isDouble
}) => {
    return (
        <Item>
            {article?.content?.image?.filename && (
                <ImageWrapper>
                    <Link
                        href={article?.full_slug ?? ""}
                        prefetch={false}
                        passHref
                        legacyBehavior
                    >
                        <a>
                            <Image
                                src={`${article?.content?.image.filename}/m/`}
                                alt={article?.content?.image?.alt}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="center"
                                unoptimized
                            />
                        </a>
                    </Link>
                </ImageWrapper>
            )}
            <Content isDouble={isDouble}>
                {showCategory && (
                    <div>
                        {article?.content?.categories
                            ?.filter(category => category.name)
                            ?.map(category => (
                                <Category key={JSON.stringify(category)}>
                                    {category?.name}
                                </Category>
                            ))}
                    </div>
                )}
                <Link
                    legacyBehavior
                    href={article?.full_slug ?? ""}
                    prefetch={false}
                    passHref
                >
                    <a>
                        <h5>{article?.name}</h5>
                    </a>
                </Link>

                {article?.first_published_at && (
                    <Footer>
                        {showDate && (
                            <Date>
                                {formatBlogDate(article.first_published_at)}
                            </Date>
                        )}
                        {article?.full_slug && (
                            <StyledButton
                                buttonType="link"
                                reversedOrder
                                icon={ARROW_RIGHT}
                                href={article?.full_slug}
                            />
                        )}
                    </Footer>
                )}
            </Content>
        </Item>
    );
};
export default Items;
