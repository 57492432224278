import styled from "styled-components";

import { Button, Icon, Input } from "components/ui";

import { media } from "constants/media";

export const StyledOrganisationApplyForm = styled.div`
    height: 100%;
    margin: 0 auto;
    max-width: 1400px;
    position: relative;
    width: 100%;
`;

export const ApplyPageBackground = styled.div`
    background: url("/images/contact-page-bg-mobile.png") no-repeat top center;
    background-size: cover;
    height: 225px;
    width: 100%;

    ${media.mdUp} {
        background: url("/images/organisation-apply-form-bg-desktop.png");
        background-size: contain;
        bottom: -90px;
        height: 600px;
        overflow: visible;
        position: absolute;
        right: -15px;
        width: 600px;
        z-index: -1;
    }
`;

export const StyledApplyForm = styled.div`
    margin: 32px 24px;

    ${media.mdUp} {
        margin: 24px 0 60px 120px;
        width: 576px;
    }
`;

export const Description = styled.p`
    font-family: var(--font-secondary);
    font-size: var(--type-heading-5-font-size);
    line-height: var(--type-heading-5-line-height);
    margin-bottom: 24px;
`;

export const StyledInput = styled(Input)`
    margin-bottom: 24px;
`;

export const TextAreaWrapper = styled.div`
    margin-bottom: 24px;
`;

export const SubmitButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        align-items: center;
        flex-direction: row-reverse;
    }
`;

export const FormDisclaimer = styled.div`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin: 0;
    }
`;

export const StyledButton = styled(Button)`
    justify-content: center;
    width: 100%;

    ${media.mdUp} {
        justify-content: normal;
        margin-right: 24px;
        width: fit-content;
    }
`;

export const StyledAnchor = styled.a`
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
    transition-duration: var(--default-transition-duration);
    transition-property: color;
    transition-timing-function: ease;

    &:hover {
        color: var(--color-orange-40);
    }
`;

export const FormErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormErrorButton = styled(Button)`
    margin-top: 24px;
    width: fit-content;
`;

export const SuccessBlock = styled.div`
    border: 2px solid var(--color-green-50);
    border-radius: 24px;
    margin-top: 24px;
    padding: 24px;
`;

export const SuccessTitle = styled.div`
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 16px;

    ${media.mdUp} {
        font-size: 18px;
    }
`;

export const SuccessIcon = styled(Icon)`
    font-size: 18px;
    font-weight: 800;
    line-height: 32px;
    margin-right: 8px;
`;
