import { Prospect, ProspectsProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { Accordion, AnimationStyle, FramerMotionWrapper } from "components/ui";

import {
    Content,
    DesktopImageWrapper,
    DesktopTitle,
    ImageWrapper,
    LeftContent,
    RightContent,
    StyledContainer,
    StyledProspect,
    Text,
    Wrapper
} from "./Prospects.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function Prospects({ blok }: ProspectsProps): JSX.Element {
    const { isTabletViewOrLarger } = useMediaQueryContext();
    const { title, desktopTitle, desktopImage, prospect } = blok;

    const Prospects = (
        <>
            {prospect.map((entry: Prospect, index) => (
                <FramerMotionWrapper
                    key={index}
                    playWhenInView
                    animationType={AnimationStyle.ScaleIn}
                    duration={0.5}
                >
                    <StyledProspect>
                        <ImageWrapper>
                            <Image
                                alt={entry.image.alt}
                                src={entry.image.filename}
                                objectFit="cover"
                                layout="fill"
                            />
                        </ImageWrapper>

                        <Text>
                            {isTabletViewOrLarger ? (
                                <>
                                    <h3>{entry.title}</h3>
                                    <p>{entry.text}</p>
                                </>
                            ) : (
                                <Accordion
                                    title={entry.title}
                                    information={entry.text}
                                />
                            )}
                        </Text>
                    </StyledProspect>
                </FramerMotionWrapper>
            ))}
        </>
    );

    return (
        <Wrapper {...storyblokEditable(blok)}>
            <StyledContainer>
                <Content>
                    <LeftContent>
                        <FramerMotionWrapper
                            playWhenInView
                            animationType={AnimationStyle.FadeIn}
                            duration={0.5}
                        >
                            <DesktopTitle>{desktopTitle}</DesktopTitle>
                            {desktopImage && desktopImage.filename && (
                                <DesktopImageWrapper>
                                    <Image
                                        src={desktopImage.filename}
                                        alt={desktopImage.alt}
                                        objectFit="cover"
                                        layout="fill"
                                    />
                                </DesktopImageWrapper>
                            )}
                        </FramerMotionWrapper>
                    </LeftContent>
                    <RightContent>
                        <h4>{title}</h4>
                        {Prospects}
                    </RightContent>
                </Content>
            </StyledContainer>
        </Wrapper>
    );
}
