import { StoryblokBlokData } from "@storyblok/react";
import { StoryblokRichtextContent } from "storyblok-rich-text-react-renderer";

import { RichTextRender } from "components/shared";

export type TermRichTextProps = {
    blok: StoryblokBlokData & {
        text?: Array<StoryblokRichtextContent>;
    };
};

function TermRichText({ blok }: TermRichTextProps): JSX.Element {
    return (
        <>
            {blok.text && (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <RichTextRender type="doc" content={blok.text} />
            )}
        </>
    );
}

export default TermRichText;
