import { StoryblokAsset, StoryblokBlokData } from "@storyblok/react";
import { StoryblokRichtextContent } from "storyblok-rich-text-react-renderer";
import Image from "next/legacy/image";

import { RichTextRender } from "components/shared";

import {
    HeroImage,
    HeroWrapper,
    TextDescription,
    TextTitle,
    TextWrapper
} from "./OnzeMensenHero.styled";

import { useMediaQueryContext } from "helpers/hooks";

interface OnzeMensenHeroProps {
    blok: StoryblokBlokData & {
        picture: StoryblokAsset;
        picture_mobile: StoryblokAsset;
        description: Array<StoryblokRichtextContent>;
    };
}

export function OnzeMensenHero({ blok }: OnzeMensenHeroProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const { picture, picture_mobile, title, description } = blok;
    return (
        <HeroWrapper>
            <TextWrapper>
                <TextTitle>{title}</TextTitle>
                <TextDescription>
                    <RichTextRender type="doc" content={description} />
                </TextDescription>
            </TextWrapper>

            {picture && picture_mobile && (
                <HeroImage>
                    <Image
                        src={
                            isMobileView
                                ? picture_mobile?.filename
                                : picture?.filename
                        }
                        layout="fill"
                        objectFit="cover"
                    />
                </HeroImage>
            )}
        </HeroWrapper>
    );
}
