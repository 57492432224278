import styled, { css } from "styled-components";

import { SubMenuLinkType } from "./SubmenuItem";

import { media } from "constants/media";

function determineTypeStyling(size: SubMenuLinkType) {
    switch (size) {
        case "heading":
            return css`
                font-family: var(--font-secondary);
                font-size: 20px;
                font-weight: bold;
                line-height: 32px;
                margin-bottom: 16px;

                :hover,
                :focus,
                :active {
                    color: var(--color-orange-50);
                }

                ${media.mdUp} {
                    margin-bottom: 0;
                }
            `;
        case "link":
        default:
            return css`
                font-size: 14px;
                font-weight: bold;
                line-height: 32px;

                :hover,
                :focus,
                :active {
                    color: var(--color-orange-50);
                }

                ${media.lgUp} {
                    font-size: 15px;
                }
            `;
    }
}

export const Anchor = styled.a<{
    type: SubMenuLinkType;
}>`
    align-items: center;
    color: var(--color-black);
    display: inline-flex;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ type }) => determineTypeStyling(type)}

    ${media.lgUp} {
        :hover,
        :focus,
        :active {
            color: var(--color-orange-50);

            svg {
                transform: translateX(5px);
            }
        }
    }
`;
