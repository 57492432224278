import { Fragment } from "react";

import { ARROW_LEFT, ARROW_RIGHT, Icon } from "components/ui";

import {
    ArrowLeft,
    ArrowRight,
    Buttons,
    Page,
    Wrapper
} from "./Pagination.styled";

export type PaginationProps = {
    paginationIndex: number;
    setPageChange: (page: number) => void;
    paginationEdges?: {
        hasPreviousPage: boolean;
        hasNextPage: boolean;
    };
    total?: number;
    itemsPerPage?: number;
};

export function Pagination({
    paginationIndex,
    paginationEdges,
    setPageChange,
    total,
    itemsPerPage
}: PaginationProps): JSX.Element {
    function generatePages() {
        if (total && itemsPerPage) {
            return [
                paginationIndex !== 1 ? paginationIndex - 1 : null,
                paginationIndex,
                total > (paginationIndex + 1) * itemsPerPage
                    ? paginationIndex + 1
                    : null
            ];
        } else {
            return [
                paginationEdges?.hasPreviousPage ? paginationIndex - 1 : null,
                paginationIndex,
                paginationEdges?.hasNextPage ? paginationIndex + 1 : null
            ];
        }
    }

    function allowedPrevious() {
        if (total && itemsPerPage) {
            return paginationIndex !== 1;
        } else {
            return paginationEdges?.hasPreviousPage;
        }
    }

    function allowedNext() {
        if (total && itemsPerPage) {
            const currentMaxItems = (paginationIndex + 1) * itemsPerPage;
            return total > currentMaxItems;
        } else {
            return paginationEdges?.hasNextPage;
        }
    }

    return (
        <Wrapper>
            <Buttons>
                <ArrowLeft
                    allowed={allowedPrevious()}
                    onClick={() => setPageChange(paginationIndex - 1)}
                >
                    <Icon icon={ARROW_LEFT} size={32} />
                </ArrowLeft>

                {generatePages().map((page, pageIndex) => (
                    <Fragment key={`page-${pageIndex}`}>
                        {page ? (
                            <Page
                                active={page === paginationIndex}
                                onClick={() => setPageChange(page)}
                            >
                                {page}
                            </Page>
                        ) : (
                            <Page empty />
                        )}
                    </Fragment>
                ))}

                <ArrowRight
                    allowed={allowedNext()}
                    onClick={() => setPageChange(paginationIndex + 1)}
                >
                    <Icon icon={ARROW_RIGHT} size={32} />
                </ArrowRight>
            </Buttons>
        </Wrapper>
    );
}
