import React, { createContext, useContext, useMemo, useState } from "react";

export interface MenuContextProviderProps {
    /** Pass any React.Node to render inside this component */
    children?: React.ReactNode;
}

interface MenuProps {
    isSubMenuActive: boolean;
    setIsSubMenuActive: (isActive: boolean) => void;
    isMobileMenuActive: boolean;
    setIsMobileMenuActive: (isActive: boolean) => void;
    activeItem: string | null;
    setActiveItem: (activeItem: string | null) => void;
    menuHeight: number;
    setMenuHeight: (menuHeight: number) => void;
}

const MenuContext = createContext({} as MenuProps);

export function useMenuContext(): MenuProps {
    return useContext(MenuContext);
}

export function MenuContextProvider({
    children
}: MenuContextProviderProps): JSX.Element {
    {
        const [isSubMenuActive, setIsSubMenuActive] = useState<boolean>(false);
        const [isMobileMenuActive, setIsMobileMenuActive] =
            useState<boolean>(false);
        const [activeItem, setActiveItem] = useState<string | null>(null);
        const [menuHeight, setMenuHeight] = useState<number>(500);

        const value = useMemo(
            () => ({
                activeItem,
                isMobileMenuActive,
                isSubMenuActive,
                menuHeight,
                setActiveItem,
                setIsMobileMenuActive,
                setIsSubMenuActive,
                setMenuHeight
            }),
            [
                menuHeight,
                setIsSubMenuActive,
                setMenuHeight,
                setActiveItem,
                isSubMenuActive,
                activeItem,
                isMobileMenuActive,
                setIsMobileMenuActive
            ]
        );

        return (
            <MenuContext.Provider value={value}>
                {children}
            </MenuContext.Provider>
        );
    }
}
