import Link from "next/link";

import { Anchor, Arrow } from "./FooterLink.styled";

export type FooterLinkType = {
    href: string;
    title: string;
};

export function FooterLink({ href, title }: FooterLinkType): JSX.Element {
    return (
        <Link legacyBehavior href={href} passHref prefetch={false}>
            <Anchor>
                {title}
                <Arrow />
            </Anchor>
        </Link>
    );
}
