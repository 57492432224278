import styled from "styled-components";

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: var(--font-secondary);
    gap: 16px;

    > h2 {
        font-weight: var(--font-weight-extra-bold);
    }

    > h3 {
        font-weight: var(--font-weight-semibold);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        color: var(--color-white);
    }

    > p {
        font-size: var(--type-paragraph-large-font-size);
        line-height: var(--type-paragraph-large-line-height);
    }

    a {
        font-family: var(--font-primary);
    }
`;
