import styled from "styled-components";

import { media } from "constants/media";

export const Title = styled.div`
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 15px;
        max-width: 180px;
        min-width: 180px;
        padding: 16px 24px 16px 16px;
    }
`;

export const Text = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 15px;
        padding: 16px 24px 16px 16px;
    }
`;
