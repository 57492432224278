import dynamic from "next/dynamic";

import {
    Article,
    Page,
    QuoteSection,
    Usp,
    Usps
} from "components/shared/storyblok";
import { Benefits } from "components/organisation/benefitsSection/benefits/Benefits";
import { BenefitsDescriptionBlock } from "components/organisation/benefitsSection/benefitsDescriptionBlock/BenefitsDescriptionBlock";
import { BenefitsSection } from "components/organisation/benefitsSection/BenefitsSection";
import { Button, Heading } from "components/ui";
import { ContactBanner } from "components/organisation/benefitsSection/contactBanner/ContactBanner";
import { ContactForm } from "components/contactForm";
import { HomepageHero } from "components/homepage";
import { ImagePill } from "components/shared/imagePill";
import {
    JoinOurTeam,
    MoreInformation,
    ParallaxImage,
    Prospects,
    Stats,
    VacancyPill,
    Zzp
} from "components/shared";
import { LatestNewsArticles } from "components/homepage/latestNewsArticles";
import { LatestStories } from "components/shared/latestStories/LatestStories";
import { OnzeMensenHero } from "components/aboutUs/OnzeMensenHero/OnzeMensenHero";
import { OrganisationApplyForm } from "components/organisation/applyForm";
import { OrganisationsHero } from "components/organisation/hero";
import { OurClients } from "components/organisation/ourClients";
import { ProfessionalHero } from "components/professional";
import { ReviewQuotes } from "components/reviewQuotes/ReviewQuotes";
import { SpacerComponent } from "components/shared/spacerComponent";
import { StandaloneStoryblokButton } from "components/standaloneStoryblokButton/StandaloneStoryblokButton";
import { VacancyWishList } from "components/vacancies/VacancyWishList/VacancyWishList";
import { ZzpHero } from "components/zzp";
import AboutUsPage from "components/aboutUs/aboutUsPage/AboutUsPage";
import AccordionContent from "components/aboutUs/accordion/AccordionContent";
import AvatarsContainer from "components/shared/avatars/AvatarsContainer";
import BlogRichText from "components/shared/storyblok/blogRichText/BlogRichText";
import PopUp from "components/popUp";
import RelatedArticles from "components/shared/storyblok/relatedArticles/RelatedArticles";
import RoundImageTextBlock from "components/aboutUs/RoundImageTextBlock/RoundImageTextBlock";
import ServicesPage from "components/services/servicesPage/ServicesPage";
import TermArticle from "components/termsAndConditions/termArticle/TermArticle";
import TermRichText from "components/termsAndConditions/termRichText/TermRichText";
import TermTable from "components/termsAndConditions/termTable/TermTable";
import TermTableRow from "components/termsAndConditions/termTableRow/TermTableRow";
import TermsPage from "components/termsAndConditions/termsPage/TermsPage";
import TwoImagesTextBlock from "components/shared/twoImagesTextBlock/TwoImagesTextBlock";

/* eslint-disable @typescript-eslint/ban-ts-comment */
const VacancySlider = dynamic(
    //@ts-ignore
    () => import("components/vacancies").then(m => m.VacancySlider),
    { ssr: false }
);

const VacancyAlert = dynamic(
    //@ts-ignore
    () => import("components/vacancies").then(m => m.VacancyAlert),
    { ssr: false }
);

const VacancyDetailImage = dynamic(
    //@ts-ignore
    () => import("components/vacancies").then(m => m.VacancyDetailImage),
    { ssr: false }
);

const VacancyHero = dynamic(
    //@ts-ignore
    () => import("components/vacancies").then(m => m.VacancyHero),
    { ssr: false }
);

const VacancyProcess = dynamic(
    //@ts-ignore
    () => import("components/vacancies").then(m => m.VacancyProcess),
    { ssr: false }
);

const VideoBlok = dynamic(
    //@ts-ignore
    () => import("components/vacancies").then(m => m.VideoBlok),
    { ssr: false }
);

const HomepageVideos = dynamic(
    //@ts-ignore
    () => import("components/homepage/videos").then(m => m.HomepageVideos),
    { ssr: false }
);

const VideoPill = dynamic(
    //@ts-ignore
    () =>
        import("components/shared/videoPill/VideoPill").then(m => m.VideoPill),
    { ssr: false }
);

const VideoPillWithText = dynamic(
    //@ts-ignore
    () =>
        import("components/shared/videoPill/VideoPillWithText").then(
            m => m.VideoPillWithText
        ),
    { ssr: false }
);

const PeoplePicturesAndLines = dynamic(
    //@ts-ignore
    () =>
        import(
            "components/aboutUs/PeoplePicturesAndLines/PeoplePicturesAndLines"
        ).then(m => m.PeoplePicturesAndLines),
    { ssr: false }
);

const HomepageCollaboration = dynamic(
    //@ts-ignore
    () => import("components/homepage").then(m => m.HomepageCollaboration),
    { ssr: false }
);

const HomepageProfessionals = dynamic(
    //@ts-ignore
    () => import("components/homepage").then(m => m.HomepageProfessionals),
    { ssr: false }
);

const HomepageQuote = dynamic(
    //@ts-ignore
    () => import("components/homepage").then(m => m.HomepageQuote),
    { ssr: false }
);

const HomepageServices = dynamic(
    //@ts-ignore
    () => import("components/homepage").then(m => m.HomepageServices),
    { ssr: false }
);

const StoriesOverview = dynamic(
    //@ts-ignore
    () =>
        import("components/stories/storiesOverview").then(
            m => m.StoriesOverview
        ),
    { ssr: false }
);
/* eslint-enable @typescript-eslint/ban-ts-comment */

export const StoryblokComponents: Record<string, unknown> = {
    AboutUsPage: AboutUsPage,
    AccordionContent: AccordionContent,
    Article: Article,
    Avatars: AvatarsContainer,
    Benefits: Benefits,
    BenefitsDescriptionBlock: BenefitsDescriptionBlock,
    BenefitsSection: BenefitsSection,
    BlogRichText: BlogRichText,
    Button: Button,
    ContactBanner: ContactBanner,
    ContactForm: ContactForm,
    Heading: Heading,
    HomepageCollaboration: HomepageCollaboration,
    HomepageHero: HomepageHero,
    HomepageProfessionals: HomepageProfessionals,
    HomepageQuote: HomepageQuote,
    HomepageServices: HomepageServices,
    HomepageVideos: HomepageVideos,
    ImagePill: ImagePill,
    JoinOurTeam: JoinOurTeam,
    LatestStories: LatestStories,
    MoreInformation: MoreInformation,
    OnzeMensenHero: OnzeMensenHero,
    OrganisationApplyForm: OrganisationApplyForm,
    OrganisationsHero: OrganisationsHero,
    OurClients: OurClients,
    Page: Page,
    ParallaxImage: ParallaxImage,
    PeoplePicturesAndLines: PeoplePicturesAndLines,
    ProfessionalHero: ProfessionalHero,
    Prospects: Prospects,
    QuoteSection: QuoteSection,
    RelatedArticles: RelatedArticles,
    ReviewQuotes: ReviewQuotes,
    RoundImageTextBlock: RoundImageTextBlock,
    ServicesPage: ServicesPage,
    SpacerComponent: SpacerComponent,
    Stats: Stats,
    StoriesOverview: StoriesOverview,
    TermArticle: TermArticle,
    TermRichText: TermRichText,
    TermTable: TermTable,
    TermTableRow: TermTableRow,
    TermsPage: TermsPage,
    TwoImagesTextBlock: TwoImagesTextBlock,
    Usp: Usp,
    Usps: Usps,
    VacancyAlert: VacancyAlert,
    VacancyDetailImage: VacancyDetailImage,
    VacancyHero: VacancyHero,
    VacancyPill: VacancyPill,
    VacancyProcess: VacancyProcess,
    VacancySlider: VacancySlider,
    VideoBlok: VideoBlok,
    VideoPill: VideoPill,
    VideoPillWithText: VideoPillWithText,
    Wishlist: VacancyWishList,
    Zzp: Zzp,
    ZzpHero: ZzpHero,
    latestNewsArticles: LatestNewsArticles,
    popUp: PopUp,
    standaloneButton: StandaloneStoryblokButton
};
