import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { Button, Counter, HeartIcon } from "./SavedJobs.styled";

import { useWishlistCounter } from "helpers/hooks/useWishlistCounterContext";

export function SavedJobs(): JSX.Element {
    const { t } = useTranslation();
    const { wishlistCount } = useWishlistCounter();

    return (
        <Link
            legacyBehavior
            href="/opgeslagen-vacatures"
            passHref
            prefetch={false}
        >
            <a>
                <Button aria-label={t("common:savedjobs")}>
                    <Counter>{wishlistCount}</Counter>
                    <HeartIcon aria-hidden="true" />
                </Button>
            </a>
        </Link>
    );
}
