import { ProfessionalHeroProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import { useRouter } from "next/router";
import { useState } from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import {
    ARROW_RIGHT,
    AnimationStyle,
    Button,
    FramerMotionWrapper,
    Input,
    SEARCH
} from "components/ui";

import {
    Content,
    ImageWrapper,
    InputWrapper,
    LinkWrapper,
    StyledContainer,
    Text,
    Wrapper
} from "./ProfessionalHero.styled";

export function ProfessionalHero({ blok }: ProfessionalHeroProps): JSX.Element {
    const { t } = useTranslation();
    const router = useRouter();
    const { title, text, image } = blok;
    const [searchValue, setSearchValue] = useState<string>("");

    function handleSearch() {
        router.push({
            pathname: "/zorgprofessionals/vacatures",
            query: { search: searchValue }
        });
    }
    return (
        <Wrapper>
            <StyledContainer {...storyblokEditable(blok)}>
                <FramerMotionWrapper
                    playWhenInView
                    animationType={AnimationStyle.FadeIn}
                >
                    <ImageWrapper>
                        <Image
                            alt={image.alt}
                            layout="fill"
                            objectFit="cover"
                            src={image.filename}
                            priority
                        />
                    </ImageWrapper>
                </FramerMotionWrapper>
                <FramerMotionWrapper
                    playWhenInView
                    animationType={AnimationStyle.FadeIn}
                    delay={0.25}
                >
                    <Content>
                        <h1>{title}</h1>
                        <Text>{text}</Text>
                        <InputWrapper>
                            <Input
                                name="challenge"
                                label={t("common:professionalschallenge")}
                                placeholder="Bijv. 'Verpleegkundige'"
                                icon={SEARCH}
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                                onIconClick={handleSearch}
                            />
                        </InputWrapper>
                        <LinkWrapper>
                            <Link
                                legacyBehavior
                                href="zorgprofessionals/vacatures"
                                passHref
                                prefetch={false}
                            >
                                <Button
                                    icon={ARROW_RIGHT}
                                    buttonType="link-dark"
                                    size="large"
                                    reversedOrder
                                >
                                    {t("common:alljobs")}
                                </Button>
                            </Link>
                        </LinkWrapper>
                    </Content>
                </FramerMotionWrapper>
            </StyledContainer>
        </Wrapper>
    );
}
