import { HeroPillImageBlok } from "storyblok/components";
import Image from "next/legacy/image";

import { ImageContainer } from "./HeroPillImage.styled";

import { useMediaQueryContext } from "helpers/hooks";

export enum PillImageSize {
    SMALL,
    LARGE
}

type ImageSize = {
    height: number;
    width: number;
};

/**
 *
 * HeroPillImage blok from storyblok
 * @param image Herobanner image
 * @param size Can be SMALL or LARGE.
 *
 * SMALL  is used as herobanner image for the following pages:
 * - Professional stories overview.
 *
 * LARGE is used as herobanner image for the following pages:
 * - Organisations
 * - Vacancy Detail
 * - ZZP'ers
 * - Professionals Overview
 * - Professional Story detail
 */
export function HeroPillImage({ image, size }: HeroPillImageBlok): JSX.Element {
    const { isMobileView, isTabletView, isDesktopView } =
        useMediaQueryContext();

    const imageSize: ImageSize = {
        height: 0,
        width: 0
    };

    // Size "small" is not used on the website
    switch (size) {
        case PillImageSize.LARGE:
        case PillImageSize.SMALL:
        default:
            if (isMobileView) {
                imageSize.height = 168;
                imageSize.width = 320;
            }
            if (isTabletView) {
                imageSize.height = 348;
                imageSize.width = 648;
            }
            if (isDesktopView) {
                imageSize.height = 696;
                imageSize.width = 696;
            }
            break;
    }

    return (
        <ImageContainer size={size}>
            {image?.filename && (
                <Image
                    src={image.filename}
                    alt={image.alt}
                    width={imageSize.width}
                    height={imageSize.height}
                    layout="responsive"
                    objectFit="cover"
                    objectPosition="center"
                />
            )}
        </ImageContainer>
    );
}

export default HeroPillImage;
