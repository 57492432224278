import Link from "next/link";

import { CHEVRON_RIGHT, HOME, Icon } from "components/ui";

import {
    BreadcrumbItem,
    BreadcrumbItemLink,
    BreadcrumbLabel,
    BreadcrumbSeperatorIcon,
    HomepageCrumbItem,
    StyledBreadcrumbs,
    StyledBreadcrumbsContainer,
    StyledBreadcrumbsWrapper
} from "./Breadcrumbs.styled";

export function Breadcrumbs(): JSX.Element | null {
    let pathNames: Array<string> = [];

    if (typeof window !== "undefined") {
        const currentLocation = window.location;
        pathNames = currentLocation.pathname
            .split("/")
            .filter(item => item !== "");
    }

    const pathNamesWithLabelAndPath: Array<{
        label: string;
        path: string;
    }> = pathNames.map((item, index) => {
        /* We splice the array so we're left with only the relevant paths for this item */
        const currentPathArray = [...pathNames].splice(0, 1 + index);

        /* Removing hyphens from the label */
        const pathLabel = item.replace(/-/g, " ");

        /* We join the array and add the first slash to get the url for the current path */
        const currentPath = "/" + currentPathArray.join("/");

        return { label: pathLabel, path: currentPath };
    });

    if (pathNamesWithLabelAndPath.length === 0) {
        return null;
    }

    return (
        <StyledBreadcrumbsWrapper>
            <StyledBreadcrumbsContainer>
                <StyledBreadcrumbs>
                    <Link legacyBehavior href="/" passHref>
                        <HomepageCrumbItem aria-label="Homepage">
                            <Icon icon={HOME} size={16} viewbox="0 0 16 18" />
                        </HomepageCrumbItem>
                    </Link>
                    {pathNamesWithLabelAndPath.map((item, index) => {
                        const isLastItem =
                            pathNamesWithLabelAndPath.length === index + 1;

                        const breadcrumbContent = (isDisabled: boolean) => (
                            <>
                                <BreadcrumbSeperatorIcon
                                    icon={CHEVRON_RIGHT}
                                    size={20}
                                />
                                <BreadcrumbLabel isDisabled={isDisabled}>
                                    {item.label}
                                </BreadcrumbLabel>
                            </>
                        );

                        /* If item is the last item in the array we don't want it to be clickable */
                        if (isLastItem) {
                            return (
                                <BreadcrumbItem key={item.label}>
                                    {breadcrumbContent(true)}
                                </BreadcrumbItem>
                            );
                        }

                        return (
                            <Link
                                legacyBehavior
                                key={item.label}
                                href={item.path}
                                passHref
                            >
                                <BreadcrumbItemLink>
                                    {breadcrumbContent(false)}
                                </BreadcrumbItemLink>
                            </Link>
                        );
                    })}
                </StyledBreadcrumbs>
            </StyledBreadcrumbsContainer>
        </StyledBreadcrumbsWrapper>
    );
}
