import { HomepageQuoteBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";

import {
    Author,
    Content,
    Info,
    Name,
    StyledContainer,
    Wrapper
} from "./HomepageQuote.styled";

export function HomepageQuote({ blok }: HomepageQuoteBlok): JSX.Element {
    return (
        <Wrapper {...storyblokEditable(blok)}>
            <StyledContainer type="grid">
                <Content>
                    <FramerMotionWrapper
                        playWhenInView
                        animationType={AnimationStyle.FadeInFromBottom}
                        duration={0.5}
                    >
                        <article>{blok.quote}</article>
                    </FramerMotionWrapper>
                    <FramerMotionWrapper
                        playWhenInView
                        animationType={AnimationStyle.FadeInFromBottom}
                        duration={0.5}
                        delay={0.1}
                    >
                        <Author>
                            {blok.image.filename && (
                                <div>
                                    <Image
                                        alt={blok.image.alt}
                                        src={blok.image.filename}
                                        width={64}
                                        height={64}
                                        layout="fixed"
                                        style={{ borderRadius: "50%" }}
                                    />
                                </div>
                            )}
                            <Info>
                                <Name>{blok.author}</Name>
                                <strong>{blok.role}</strong>
                            </Info>
                        </Author>
                    </FramerMotionWrapper>
                </Content>
            </StyledContainer>
        </Wrapper>
    );
}
