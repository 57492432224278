import Link from "next/link";

import { ARROW_RIGHT, Icon } from "components/ui";

import {
    Anchor,
    Content,
    Description,
    IconWrapper,
    StyledIcon,
    Title
} from "./Card.styled";

type CardProps = {
    icon?: JSX.Element;
    href: string;
    title: string;
    description?: string;
    invert?: boolean;
};

export function Card({
    icon,
    title,
    href,
    description,
    invert = false
}: CardProps): JSX.Element {
    return (
        <Link legacyBehavior href={href} passHref prefetch={false}>
            <Anchor invert={invert}>
                {icon && (
                    <IconWrapper>
                        <StyledIcon icon={icon} size={40} aria-hidden="true" />
                    </IconWrapper>
                )}
                <Content>
                    <Title>{title}</Title>
                    {description && <Description>{description}</Description>}
                </Content>
                {invert && (
                    <Icon icon={ARROW_RIGHT} size={24} aria-hidden="true" />
                )}
            </Anchor>
        </Link>
    );
}
