import styled from "styled-components";

import { media } from "constants/media";

export const Label = styled.label<{ disabled: boolean }>`
    /* stylelint-disable order/properties-alphabetical-order*/
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    /* stylelint-enable order/properties-alphabetical-order*/
    color: ${props => (props.disabled ? "var(--color-grey)" : "inherit")};
    cursor: pointer;
    display: inline-flex;
    font-size: var(--type-paragraph-font-size);
    justify-content: center;
    line-height: var(--type-paragraph-line-height);
    margin: 4px 14px 4px 0;
`;

export const StyledRadio = styled.span<{ hasLabel: boolean }>`
    align-items: center;
    border: 1px solid var(--color-border-grey);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: ${({ hasLabel }) => (hasLabel ? "8px" : "0")};
    min-width: 24px;
    /* trick for aligning multi-line labels */
    transform: translateY(-0.05em);
    transition: var(--default-transition-duration) border ease-in-out;
    width: 24px;

    ${media.mdUp} {
        margin-top: 4px;
    }
`;

export const Fill = styled.div`
    background: var(--color-primary);
    border-radius: 50%;
    height: 14px;
    opacity: 0;
    transform: scale(0);
    transition: var(--default-transition-duration) all ease-in-out;
    visibility: hidden;
    width: 14px;

    &.animate-in {
        opacity: 1;
        transform: scale(1);
        visibility: visible;
    }

    &.animate-out {
        opacity: 0;
        transform: scale(0);
        visibility: visible;
    }
`;

export const RadioInput = styled.input`
    display: none;
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + ${StyledRadio} {
        border: 1px solid var(--color-primary);
    }

    &:hover + ${StyledRadio} {
        border: 1px solid var(--color-primary);
    }

    &:focus + ${StyledRadio} {
        border: 1px solid var(--color-primary);
        box-shadow: 0 0 5px var(--color-primary);
    }

    &:disabled + ${StyledRadio} {
        background-color: var(--color-light-grey);
        border: 1px solid var(--color-grey);
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const Text = styled.div`
    flex-shrink: 0;
`;
