import styled from "styled-components";

import { media } from "constants/media";

export const StyledParallaxImage = styled.div`
    margin: 0 auto;
    max-width: 1248px;
    position: relative;
`;

export const ParallaxContent = styled.div`
    height: 216px;
    width: 100%;

    ${media.mdUp} {
        height: 496px;
    }
`;
