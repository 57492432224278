import { ForwardedRef, forwardRef } from "react";

import { StyledContainer } from "./Container.styled";

export type ContainerType = "flex" | "grid";

export interface ContainerProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Pass any React.Node to render inside this component */
    children: React.ReactNode;
    /* Use container as grid or flexbox */
    type?: ContainerType;
}

export const Container = forwardRef(function Container(
    { children, className, type = "flex", ...otherProps }: ContainerProps,
    ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
    return (
        <StyledContainer
            type={type}
            className={className}
            ref={ref}
            {...otherProps}
        >
            {children}
        </StyledContainer>
    );
});
