import styled from "styled-components";

import { Container } from "components/ui";

function getButtonPlacement(placement: "left" | "right" | "center") {
    switch (placement) {
        case "left":
            return "flex-start";
        case "right":
            return "flex-end";
        case "center":
            return "center";
    }
}

const gutterWidth = 24;

export const StandaloneStoryblokButtonWrapper = styled(Container)<{
    placement: "left" | "right" | "center";
}>`
    display: flex;
    justify-content: ${({ placement }) => getButtonPlacement(placement)};
    padding: ${gutterWidth}px;
`;
