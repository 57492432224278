import styled, { css } from "styled-components";

interface StyledInputProps {
    disabled?: boolean;
    hasIcon: boolean;
    hasError: boolean;
    maxLength?: number;
}

export const StyledLabel = styled.label<{ disabled: boolean }>`
    color: ${({ disabled }) =>
        disabled ? "var(--color-border-grey)" : "var(--color-black)"};
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    position: relative;
`;

export const LabelWrapper = styled.span<{ noWrap?: boolean }>`
    margin-bottom: 8px;

    ${({ noWrap }) =>
        noWrap &&
        css`
            white-space: nowrap;
        `}
`;

export const IconWrapper = styled.div`
    align-items: center;
    background: var(--color-orange-60);
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    width: 48px;

    svg {
        g {
            stroke: var(--color-white);
        }

        circle {
            fill: var(--color-orange-60);
        }
    }
`;

export const InputWrapper = styled.div`
    position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
    background-color: var(--color-light-grey);
    border: 0;
    border-radius: 32px;
    color: var(--color-grey-60);
    line-height: 32px;
    padding: 11px 16px;
    transition-duration: var(--default-transition-duration);
    transition-property: box-shadow, border-color, background-color, color;
    transition-timing-function: ease;
    width: 100%;

    + ${IconWrapper} {
        color: var(--color-primary);
        right: 3px;
    }

    &::placeholder {
        color: ${({ disabled }) =>
            disabled ? "var(--color-border-grey)" : "var(--color-grey-60)"};
    }

    &:focus {
        background-color: var(--color-white);
        box-shadow: 0 0 6px var(--color-primaryLight);
        outline: none;
    }

    &:disabled {
        background-color: var(--color-white);
        color: var(--color-border-grey);
        cursor: not-allowed;
    }

    &:placeholder-shown + ${IconWrapper} {
        color: ${({ disabled }) =>
            disabled ? "var(--color-border-grey)" : "var(--color-grey)"};
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ hasError }) =>
        hasError &&
        css`
            background-color: var(--color-red-light);
            border-color: var(--color-red-50);

            &:focus {
                box-shadow: 0 0 6px var(--color-red-50);
            }
        `}
    ${({ hasIcon }) =>
        hasIcon &&
        css`
            padding-right: 40px;
        `} /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const StyledErrorMessage = styled.p`
    color: var(--color-red-50);
    font-size: var(--type-sub-text-font-size-sub-text);
    line-height: var(--type-sub-text-line-height);
    margin-top: 4px;
`;
