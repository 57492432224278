import styled from "styled-components";

import { media } from "constants/media";

export const Item = styled.li<{ bgColor: string }>`
    > div {
        align-items: center;
        background: var(--color-${({ bgColor }) => bgColor});
        border-radius: 24px;
        color: var(--color-white);
        display: flex;
        flex-direction: row;
        gap: 10px;
        line-height: 24px;
        padding: 8px 12px;
    }

    ${media.mdUp} {
        flex-basis: calc(50% - 10px);
        gap: 18px;
    }
`;

export const IconContainer = styled.div`
    height: 20px;
    width: 20px;

    > svg {
        fill: transparent;
    }
`;
