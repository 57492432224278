import styled from "styled-components";

import { Avatar } from "components/shared/header/navigation/main/submenu/submenuItem";

import { media } from "constants/media";

export const Anchor = styled.a`
    align-items: center;
    color: var(--color-orange-50);
    display: inline-flex;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 16px;
    text-decoration: none;

    :hover,
    :focus,
    :active {
        svg {
            transform: translateX(5px);
        }
    }

    ${media.lgUp} {
        margin-top: 0;
    }
`;

export const ImageContainer = styled.div`
    margin-right: 8px;
`;

export const StyledAvatar = styled(Avatar)`
    :nth-child(1n + 2) {
        margin-left: -8px;
        transform: translateX(0);
    }
`;
