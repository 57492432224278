import styled from "styled-components";

import { Container, Heading } from "components/ui";

import { media } from "constants/media";

export const StyledAccordionBlock = styled(Container)`
    flex-direction: column;
    margin: 64px auto;
`;

export const Title = styled(Heading)`
    margin-bottom: 16px;

    ${media.mdUp} {
        text-align: center;
    }
`;

export const Description = styled.div`
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 56px;

    ${media.mdUp} {
        font-size: 18px;
    }
`;

export const Content = styled.div`
    font-family: var(--font-secondary);
    max-height: 0;
    opacity: 0;
    transition: var(--default-transition-duration) ease;

    &.active {
        max-height: 1000px;
        opacity: 1;
    }

    ${media.mdUp} {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 48px;
        overflow: hidden;
        transition: none;
    }
`;

export const ImageWrapper = styled.div`
    display: block;
    height: 200px;
    margin: 0 -24px 16px;
    position: relative;

    ${media.mdUp} {
        border-radius: 200px;
        display: block;
        height: 400px;
        margin: 0;
        overflow: hidden;
        width: 100%;
    }
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        border-bottom: 2px solid rgba(229 115 0 / 20%);
        flex-direction: row;
        justify-content: space-around;
        margin: 56px 0;
    }
`;

export const Tab = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: var(--font-secondary);
    font-size: 26px;
    font-weight: var(--font-weight-extra-bold);
    justify-content: space-between;
    padding-bottom: 32px;
    position: relative;

    ${media.mdUp} {
        font-weight: normal;

        &.active {
            font-weight: bold;

            ::after {
                background: var(--color-orange-60);
                bottom: 0;
                content: "";
                height: 8px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 96px;
            }
        }
    }
`;

export const Item = styled.div`
    display: block;
`;

export const TabTitle = styled.div`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
`;
