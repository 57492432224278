import React, { FormEvent, ForwardedRef, forwardRef } from "react";

import { CLOSE, Icon } from "components/ui/icon";
import {
    LabelWrapper,
    StyledErrorMessage,
    StyledTextArea,
    StyledTextAreaWrapper,
    TextAreaContainer
} from "components/ui/textArea/TextArea.styled";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    /**
     * Changes the appearance of the component depending of the status
     */
    hasError?: boolean;

    /**
     * Defines the message to be shown when the component is invalid.
     */
    errorMessage?: string;

    /**
     * Defines the label of the component
     */
    label?: string;

    /**
     * Defines the value of the component
     */
    value?: string;

    /**
     * Creates a reference to the component element
     */
    ref?: React.MutableRefObject<HTMLTextAreaElement>;

    /**
     * Disable the textArea component
     */
    disabled?: boolean;

    /**
     * Fired when component's value changes.
     */
    onChange?: (event: FormEvent<HTMLTextAreaElement>) => void;

    /**
     * Fired when component's value changes.
     */
    resize?: "none" | "both" | "horizontal" | "vertical";
    /**
     * Name for the input element, also used for id / htmlfor
     */
    name?: string;
    /**
     * Placeholder for the input element
     */
    placeholder?: string;
    /** maximum amount of characters */
    maxLength?: number;
}

export const TextArea = forwardRef(
    (
        {
            label,
            hasError,
            errorMessage,
            value,
            disabled,
            onChange,
            resize = "both",
            name,
            placeholder,
            maxLength
        }: TextAreaProps,
        ref: ForwardedRef<HTMLTextAreaElement>
    ) => {
        return (
            <StyledTextAreaWrapper>
                {label && <LabelWrapper>{label}</LabelWrapper>}

                <TextAreaContainer
                    style={{ resize }}
                    canResize={resize !== "none"}
                >
                    <StyledTextArea
                        ref={ref}
                        disabled={disabled}
                        hasError={Boolean(errorMessage) || hasError}
                        defaultValue={value}
                        onChange={onChange}
                        name={name}
                        placeholder={placeholder}
                        maxLength={maxLength}
                    />

                    {hasError && (
                        <span>
                            <Icon
                                icon={CLOSE}
                                size={18}
                                color="var(--color-primary)"
                                viewbox="0 0 16 16"
                            />
                        </span>
                    )}
                </TextAreaContainer>
                {(hasError || Boolean(errorMessage)) && (
                    <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
                )}
            </StyledTextAreaWrapper>
        );
    }
);

TextArea.displayName = "TextArea";
