import { useEffect } from "react";

/**
 * @param parentElement The parent element which does a lookup to see if 'comparedElement' is a child node.
 * @param comparedElement The compared element that is used to determine if it's a child node of the 'parentElement'.
 */
export const containsElement = (
    parentElement: Element | null,
    comparedElement: EventTarget | null
): boolean => {
    return parentElement?.contains(comparedElement as Node) ?? false;
};

/**
 * @param parentElement The parent element which does a lookup to see if 'comparedElement' is a child node.
 * @param callback The callback function that is called when detecting a click outside of the 'parentElement'
 */
export const useOnClickOutside = (
    parentElement: Element | null,
    callback: (event?: Event | KeyboardEvent) => void
): void => {
    useEffect(() => {
        const listener = (event: Event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (containsElement(parentElement, event.target)) {
                return;
            }

            callback(event);
        };

        const escapeKeyListener = (event: KeyboardEvent) => {
            // fire handler when the key pressed is the escape key
            if (event.key === "Escape") {
                callback(event);
            }

            return;
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        document.addEventListener("keydown", escapeKeyListener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
            document.addEventListener("keydown", escapeKeyListener);
        };
    }, [parentElement, callback]);
};
