export function optimizeImage(
    url: string,
    focus?: string,
    wide?: boolean
): string {
    if (!url) return "";

    if (url.includes("a.storyblok.com")) {
        return (
            url +
            `/m/${
                wide ? "1920" : "400"
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            }x900/filters:focal(${focus})format(webp)`
        );
    } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return url.replace("/f/", `/filters:focal(${focus})format(webp)/f/`);
    }
}
