import { Button, TwoImagesTextBlockProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";
import {
    ButtonsWrapper,
    ContentWrapper,
    ImageOneWrapper,
    ImageTwoWrapper,
    ImageWrapper,
    StyledTwoImagesTextBlock,
    Text,
    Title
} from "components/shared/twoImagesTextBlock/TwoImagesTextBlock.styled";
import { StyledStoryblokButton } from "components/services/servicesPage/ServicesPage.styled";

function TwoImagesTextBlock({ blok }: TwoImagesTextBlockProps): JSX.Element {
    const { title, text, imageOne, imageTwo, buttons, isReversed } = blok;

    return (
        <FramerMotionWrapper
            playWhenInView
            animationType={AnimationStyle.FadeInFromBottom}
            duration={0.5}
            delay={0.1}
        >
            <StyledTwoImagesTextBlock
                isReversed={isReversed}
                {...storyblokEditable(blok)}
            >
                <ImageWrapper>
                    {imageOne && (
                        <ImageOneWrapper>
                            <Image
                                src={`${imageOne?.filename}/m/`}
                                layout="fill"
                                objectFit="cover"
                                quality={100}
                                alt={imageOne?.alt}
                            />
                        </ImageOneWrapper>
                    )}
                    {imageTwo && (
                        <ImageTwoWrapper>
                            <Image
                                src={`${imageTwo?.filename}/m/`}
                                layout="fill"
                                objectFit="cover"
                                quality={100}
                                alt={imageOne?.alt}
                            />
                        </ImageTwoWrapper>
                    )}
                </ImageWrapper>
                <ContentWrapper>
                    {title && <Title headingLevel={2}>{title}</Title>}
                    <Text>{text}</Text>
                    {buttons && (
                        <ButtonsWrapper>
                            {buttons.map((entry: Button) => {
                                return (
                                    entry.component === "Button" && (
                                        <StyledStoryblokButton
                                            key={entry._uid}
                                            {...entry}
                                        />
                                    )
                                );
                            })}
                        </ButtonsWrapper>
                    )}
                </ContentWrapper>
            </StyledTwoImagesTextBlock>
        </FramerMotionWrapper>
    );
}

export default TwoImagesTextBlock;
