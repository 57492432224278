import { QuoteSectionBlok } from "storyblok/components";

import { AnimationStyle, FramerMotionWrapper, QUOTE } from "components/ui";
import { RichTextRender } from "components/shared/richTextRender";

import { Quote, QuoteContainer, QuoteDecoration } from "./QuoteSection.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function QuoteSection({ blok }: QuoteSectionBlok): JSX.Element {
    const { centeredText, text, withQuoteMarks } = blok;
    const { isDesktopView } = useMediaQueryContext();

    return (
        <FramerMotionWrapper
            playWhenInView
            animationType={AnimationStyle.FadeInFromBottom}
            duration={0.5}
            delay={0.1}
        >
            <QuoteContainer
                centeredText={centeredText}
                withQuoteMarks={withQuoteMarks}
            >
                {withQuoteMarks && (
                    <QuoteDecoration
                        icon={QUOTE}
                        viewbox="0 0 62 56"
                        color="var(--color-orange-40)"
                        width={isDesktopView ? 62 : 36}
                        height={isDesktopView ? 54 : 32}
                    />
                )}

                <Quote centeredText={centeredText}>
                    <RichTextRender type={text?.type} content={text} />
                </Quote>
                {withQuoteMarks && (
                    <QuoteDecoration
                        icon={QUOTE}
                        viewbox="0 0 61 54"
                        color="var(--color-orange-40)"
                        width={isDesktopView ? 62 : 36}
                        height={isDesktopView ? 54 : 32}
                    />
                )}
            </QuoteContainer>
        </FramerMotionWrapper>
    );
}

export default QuoteSection;
