import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    background-color: var(--color-white);
    overflow-x: hidden;

    ${media.lgUp} {
        overflow: visible;
    }
`;

export const StyledContainer = styled(Container)`
    padding: 0 24px;
    position: relative;

    ${media.mdUp} {
        padding: 0;
    }
`;

export const AmbianceImage = styled.div`
    position: absolute;

    img {
        border-radius: 12px;

        ${media.lgUp} {
            border-radius: 24px;
        }
    }
`;

export const Avatar = styled.div`
    height: 24px;
    position: absolute;
    width: 24px;
    z-index: var(--z-index-homepage-section);

    img {
        border-radius: 50%;
    }
`;

export const Collage = styled.div`
    margin: 0 -24px;
    max-width: 320px;
    min-height: 212px;
    position: relative;

    /*stylelint-disable selector-type-no-unknown*/
    ${AmbianceImage}:nth-child(1) {
        left: 24px;
        top: 32px;
        transform: rotate(3deg);

        ${media.lgUp} {
            top: 12px;
        }
    }

    ${AmbianceImage}:nth-child(3) {
        right: -20px;
        top: 8px;
        transform: rotate(-6deg);

        ${media.lgUp} {
            bottom: -464px;
            right: initial;
            top: initial;
        }
    }

    ${AmbianceImage}:nth-child(2) {
        right: 40px;
        top: 88px;
        transform: rotate(-2deg);
        z-index: var(--z-index-homepage-bg);

        ${media.lgUp} {
            left: 80px;
            right: initial;
            top: 212px;
        }
    }

    ${Avatar}:nth-child(4) {
        bottom: 58px;
        height: 25px;
        left: 27px;
        width: 25px;

        ${media.lgUp} {
            bottom: unset;
            height: 50px;
            left: 50px;
            top: 220px;
            width: 50px;
        }
    }

    ${Avatar}:nth-child(5) {
        height: 29px;
        left: 90px;
        top: 15px;
        width: 29px;

        ${media.lgUp} {
            bottom: -264px;
            height: 58px;
            left: -32px;
            top: unset;
            width: 58px;
        }
    }

    ${Avatar}:nth-child(6) {
        bottom: 12px;
        height: 33px;
        right: 25px;
        width: 33px;

        ${media.lgUp} {
            bottom: -264px;
            height: 66px;
            right: -64px;
            width: 66px;
        }
    }

    ${Avatar}:nth-child(7) {
        bottom: 16px;
        height: 29px;
        left: 86px;
        width: 29px;

        ${media.lgUp} {
            bottom: unset;
            height: 58px;
            left: 364px;
            top: 64px;
            width: 58px;
        }
    } /*stylelint-enable selector-type-no-unknown*/

    ${media.mdUp} {
        margin: auto 0;
    }

    ${media.lgUp} {
        margin: 0;
    }
`;

export const StyledHeading = styled.h2`
    font-weight: var(--font-weight-normal);
    margin-bottom: 16px;

    span {
        display: block;
        font-weight: var(--font-weight-semibold);
        margin-bottom: -8px;
    }
`;

export const Content = styled.div<{
    marginDesktop?: string;
    marginMobile?: string;
}>`
    display: grid;
    gap: 16px;
    grid-column: span 4;
    margin: ${({ marginMobile }) => (marginMobile ? marginMobile : "0")};
    width: 100%;
    z-index: var(--z-index-component-foreground);

    /*stylelint-disable-next-line */
    ${media.mdUp} {
        align-items: flex-start;
        gap: 64px;
        grid-column-end: 12;
        grid-column-start: 1;
        grid-template-columns: [first] 320px [second] 340px;
        text-align: left;
    }

    /*stylelint-disable-next-line */
    ${media.lgUp} {
        gap: 288px;
        justify-content: center;
        margin: ${({ marginDesktop }) => (marginDesktop ? marginDesktop : "0")};
        min-height: 640px;
    }
`;

export const Article = styled.article`
    border-top: 1px solid var(--color-grey-50);
    color: var(--color-black);
    font-size: 16px;
    font-style: italic;
    line-height: 24px;
    margin: 16px 0;
    padding-top: 16px;
    width: 100%;

    /*stylelint-disable-next-line */
    ${media.lgUp} {
        margin: 32px 0;
        padding-top: 32px;
    }
`;

export const Author = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    grid-template-columns: [first] 64px [second] 192px;
    text-align: left;

    /* stylelint-disable-next-line*/
    ${media.mdUp} {
        grid-template-columns: [first] 64px [second] 384px;
    }
`;

export const Info = styled.div`
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
        font-weight: var(--font-weight-semibold);
    }

    /*stylelint-disable-next-line */
    ${media.mdUp} {
        p {
            margin-bottom: 16px;
        }
    }

    /*stylelint-disable-next-line */
    ${media.lgUp} {
        margin: auto 0;
    }
`;

export const Name = styled.span`
    margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div`
    margin-top: 8px;
`;
