import { LatestStoriesProps } from "storyblok/components";
import { useState } from "react";
import Carousel from "nuka-carousel";
import Image from "next/legacy/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import {
    ARROW_RIGHT,
    AnimationStyle,
    FramerMotionWrapper,
    Icon
} from "components/ui";

import {
    CreatedAt,
    Function,
    ImageAndFunction,
    ImageWrapper,
    NameAndFunction,
    Professional,
    Story,
    StyledContainer,
    StyledLink,
    StyledSliderControls,
    StyledTitle,
    Text,
    Wrapper
} from "./LatestStories.styled";

import { formatDate } from "helpers/format";
import { useMediaQueryContext } from "helpers/hooks";

export function LatestStories({ blok, verhalen }: LatestStoriesProps) {
    const { t } = useTranslation();
    const { title, link } = blok;
    const { isMobileView, isTabletViewOrLarger } = useMediaQueryContext();
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <Wrapper>
            <StyledContainer>
                <StyledTitle>
                    {title}
                    {!isMobileView && verhalen && (
                        <StyledSliderControls
                            totalSlides={verhalen.length}
                            currentSlide={currentSlide}
                            onChange={setCurrentSlide}
                        />
                    )}
                </StyledTitle>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Carousel
                    afterSlide={nextSlide => {
                        setCurrentSlide(nextSlide);
                    }}
                    slideIndex={currentSlide}
                    dragging
                    swiping
                    withoutControls
                    slideWidth={isTabletViewOrLarger ? "472px" : "254px"}
                    cellSpacing={isTabletViewOrLarger ? 48 : 16}
                >
                    {verhalen &&
                        verhalen.map((verhaal, index) => (
                            <FramerMotionWrapper
                                key={index}
                                playWhenInView
                                animationType={AnimationStyle.FadeIn}
                                duration={0.5}
                            >
                                <Story>
                                    <Link
                                        href={`/${verhaal.full_slug}`}
                                        passHref
                                        legacyBehavior
                                    >
                                        <a>
                                            <Text>
                                                {verhaal.content.introText}
                                            </Text>
                                            <CreatedAt>
                                                {formatDate(
                                                    verhaal.created_at,
                                                    "dd MMMM, yyyy"
                                                )}
                                            </CreatedAt>
                                            <Professional>
                                                <ImageAndFunction>
                                                    <ImageWrapper>
                                                        <Image
                                                            src={`${verhaal.content.heroImage.filename}/m/48x48`}
                                                            alt={
                                                                verhaal.content
                                                                    .heroImage
                                                                    .alt ?? ""
                                                            }
                                                            layout="fill"
                                                            objectFit="cover"
                                                            objectPosition="center"
                                                            unoptimized
                                                        />
                                                    </ImageWrapper>
                                                    <NameAndFunction>
                                                        <p>{verhaal.name}</p>
                                                        {isTabletViewOrLarger && (
                                                            <span>
                                                                {
                                                                    verhaal
                                                                        .content
                                                                        .jobFunction
                                                                }
                                                            </span>
                                                        )}
                                                    </NameAndFunction>
                                                </ImageAndFunction>
                                                {isTabletViewOrLarger && (
                                                    <Function>
                                                        <Icon
                                                            icon={ARROW_RIGHT}
                                                            size={48}
                                                            color="var(--color-primary)"
                                                        />
                                                    </Function>
                                                )}
                                            </Professional>
                                            {isMobileView && (
                                                <Function>
                                                    <span>
                                                        {
                                                            verhaal.content
                                                                .jobFunction
                                                        }
                                                    </span>

                                                    <Icon
                                                        icon={ARROW_RIGHT}
                                                        size={48}
                                                        color="var(--color-primary)"
                                                    />
                                                </Function>
                                            )}
                                        </a>
                                    </Link>
                                </Story>
                            </FramerMotionWrapper>
                        ))}
                </Carousel>
                {isMobileView && (
                    <StyledSliderControls
                        totalSlides={verhalen.length}
                        currentSlide={currentSlide}
                        onChange={setCurrentSlide}
                    />
                )}
                <Link
                    legacyBehavior
                    href={link ?? "/zorgprofessionals/ervaringen"}
                    passHref
                >
                    <StyledLink>
                        {t("common:seeallstories")}
                        <Icon
                            icon={ARROW_RIGHT}
                            size={32}
                            color="var(--color-primary)"
                        />
                    </StyledLink>
                </Link>
            </StyledContainer>
        </Wrapper>
    );
}
