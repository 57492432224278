import styled from "styled-components";

import { Heading } from "components/ui";

import { media } from "constants/media";

export const StyledOffices = styled.div`
    margin: 0 auto;
    max-width: 1400px;
    padding: 32px 24px;

    ${media.mdUp} {
        padding: 64px 120px;
    }
`;

export const OfficesHeading = styled(Heading)`
    font-weight: 800;
    margin-bottom: 32px;
`;

export const OfficesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media.mdUp} {
        column-gap: 48px;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const OfficeItem = styled.div`
    background-color: var(--color-white);
    overflow: hidden;
    width: 100%;

    ${media.mdUp} {
        border-radius: 24px;
        height: 460px;
        max-width: 576px;
        width: calc(50% - 24px);
    }
`;

export const ImageWrapper = styled.div`
    border-radius: 24px;
    height: 124px;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        transition-duration: var(--default-transition-duration);
        transition-property: transform;
        transition-timing-function: ease;
    }

    &:hover img {
        transform: scale(1.05);
    }

    ${media.mdUp} {
        border-radius: 0;
        height: 228px;
    }
`;

export const InfoWrapper = styled.div`
    padding-top: 16px;

    ${media.mdUp} {
        padding: 16px 32px 32px;
    }
`;

export const Address = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 15px;
        margin: 8px 0 24px;
        max-width: 264px;
    }
`;

export const ContactItem = styled.a`
    column-gap: 16px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 8px;
    text-decoration: none;
    transition-duration: var(--default-transition-duration);
    transition-property: color;
    transition-timing-function: ease;

    svg {
        min-width: 30px;
    }

    &:hover {
        color: var(--color-primary);
    }

    ${media.mdUp} {
        font-size: 15px;
    }
`;
