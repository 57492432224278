import { TextRow } from "components/ui/skeleton/Skeleton.styled";

interface TextProps {
    className?: string;
    rows: number;
}

export function TextSkeleton({ className, rows = 1 }: TextProps): JSX.Element {
    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            {[...Array(rows)].map((_, i) => (
                <TextRow key={i} className={className} />
            ))}
        </>
    );
}
