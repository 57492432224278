import { LazyMotion, Variants, domAnimation } from "framer-motion";
import { StoryblokAsset } from "@storyblok/react";
import Image from "next/legacy/image";

import { Avatar, Container } from "./Avatars.styled";

import { Coordinates, useMousePosition } from "helpers/hooks/useMousePosition";
import { clamp } from "helpers/transform";

/**
 * @param index Image ID, images are positioned clockwise,  0 to 7
 * @param mousePosition The position of the mouse relative to the center of the screen, -1 to 1
 * @param strength The strength of which the avatar is moved, (index 1,6,7 move slower)
 * @param range Max amount the avatar is moved (clamp)
 */
function moveAvatar(
    index = 0,
    mousePosition: Coordinates,
    strength = 10,
    range = 75
): Coordinates {
    if (mousePosition.x === 1 && mousePosition.y === 1) {
        return {
            x: 0,
            y: 0
        };
    }

    let x = mousePosition.x * (strength * (index + 1));
    let y = mousePosition.y * (strength * (index + 1));

    if ([1, 6, 7].includes(index)) {
        x = x / 2;
        y = y / 2;
    }

    return {
        x: clamp(x, range * -1, range),
        y: clamp(y, range * -1, range)
    };
}

interface AvatarsProps {
    /** Array of storyblok images to show as avatars */
    avatars: Array<StoryblokAsset>;
}

export function Avatars({ avatars }: AvatarsProps): JSX.Element {
    const mousePosition = useMousePosition();

    const item: Variants = {
        hidden: {
            opacity: 0,
            scale: 0.7
        },
        move: (index: number) => {
            return {
                ...moveAvatar(index, mousePosition.relative)
            };
        },
        show: index => {
            return {
                opacity: 1,
                scale: 1,
                transition: { delay: index * 0.05 }
            };
        }
    };

    return (
        <LazyMotion features={domAnimation}>
            <Container>
                {avatars.map(({ id, filename, alt }: StoryblokAsset, index) => {
                    if (index > 7) {
                        return;
                    }

                    return (
                        <Avatar
                            key={id}
                            variants={item}
                            layout
                            custom={index}
                            viewport={{ once: true }}
                            initial="hidden"
                            whileInView={["show", "move"]}
                        >
                            <Image
                                src={filename}
                                alt={alt}
                                layout="fill"
                                objectFit="cover"
                            />
                        </Avatar>
                    );
                })}
            </Container>
        </LazyMotion>
    );
}
