import { Button, JoinOurTeamProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";
import { RichTextRender, StoryblokButton } from "components/shared";

import {
    Background,
    ButtonWrapper,
    Content,
    ImageWrapper,
    StyledContainer
} from "./JoinOurTeam.styled";

import { optimizeImage } from "helpers/util/optimizeImage";
import { useMediaQueryContext } from "helpers/hooks";

export function JoinOurTeam({ blok }: JoinOurTeamProps): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();

    //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { image, text, button } = blok;

    return (
        <Background>
            <StyledContainer {...storyblokEditable(blok)}>
                <div>
                    <FramerMotionWrapper
                        playWhenInView
                        animationType={AnimationStyle.FadeInFromBottom}
                        duration={0.5}
                    >
                        {image.filename && (
                            <FramerMotionWrapper
                                playWhenInView
                                animationType={AnimationStyle.FadeIn}
                                duration={0.5}
                                delay={0.25}
                            >
                                <ImageWrapper>
                                    <Image
                                        alt={image.alt}
                                        src={optimizeImage(
                                            image.filename,
                                            image.focus,
                                            false
                                        )}
                                        width={isDesktopView ? 369 : 272}
                                        height={isDesktopView ? 614 : 181}
                                        objectFit="cover"
                                        layout="fixed"
                                    />
                                </ImageWrapper>
                            </FramerMotionWrapper>
                        )}
                    </FramerMotionWrapper>
                </div>

                <Content>
                    <FramerMotionWrapper
                        playWhenInView
                        animationType={AnimationStyle.FadeInFromBottom}
                        duration={0.5}
                    >
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                        <RichTextRender type={text} content={text} />
                        <ButtonWrapper>
                            {/* eslint-disable */}
                            {/* @ts-ignore*/}
                            {button.map((entry: Button) => {
                                return (
                                    entry.component === "Button" && (
                                        <StoryblokButton
                                            key={entry._uid}
                                            {...entry}
                                        />
                                    )
                                );
                            })}
                            {/* eslint-enable */}
                        </ButtonWrapper>
                    </FramerMotionWrapper>
                </Content>
            </StyledContainer>
        </Background>
    );
}
