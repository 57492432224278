import styled, { css } from "styled-components";

import { media } from "constants/media";

function determineMenuPosition(
    isMobileMenuActive: boolean,
    isSubMenuActive: boolean
) {
    if (!isMobileMenuActive) {
        return css`
            transform: translate(0, 50px);
        `;
    }

    if (isSubMenuActive) {
        return css`
            transform: translate(-100vw, 0);
        `;
    } else {
        return css`
            transform: translate(0, 0);
        `;
    }
}

export const NavigationContainer = styled.div<{
    isMobileMenuActive: boolean;
    isSubMenuActive: boolean;
    stickyBarActive: boolean;
}>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    opacity: ${({ isMobileMenuActive }) => (isMobileMenuActive ? 1 : 0)};
    padding: 24px;
    position: fixed;
    top: ${({ stickyBarActive }) => (stickyBarActive ? "66px" : "106px")};
    transform-origin: bottom center;
    transition: all var(--default-transition-duration) ease;
    visibility: ${({ isMobileMenuActive }) =>
        isMobileMenuActive ? "visible" : "hidden"};
    width: 100%;
    z-index: var(--z-index-header);

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ isMobileMenuActive, isSubMenuActive }) =>
        determineMenuPosition(isMobileMenuActive, isSubMenuActive)}

    ${media.mdUp} {
        background-color: var(--color-orange-60);
        bottom: auto;
        left: 0;
        padding: 0;
        position: absolute;
        top: 78px;
        transform: ${({ isMobileMenuActive }) =>
            isMobileMenuActive ? "scaleY(1)" : "scaleY(0)"};
        transform-origin: top center;
    }

    ${media.lgUp} {
        background-color: transparent;
        flex: 1 1 auto;
        flex-direction: row;
        gap: 0;
        left: auto;
        max-height: fit-content;
        opacity: 1;
        padding: 0;
        position: initial;
        top: auto;
        transform: none;
        visibility: visible;
        z-index: var(--z-index-header);
    }

    ${media.xlUp} {
        gap: 24px;
    }
`;
