import styled, { css } from "styled-components";

import { Container, Icon, PLAY } from "components/ui";

import { media } from "constants/media";

export const StyledContainer = styled(Container)`
    padding: 0 8px;

    ${media.mdUp} {
        padding: 0 24px;
    }
`;

export const List = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 0 8px;
    justify-content: center;
    list-style: none;
    margin: 0 auto;
    max-width: 320px;
    width: 100%;

    ${media.mdUp} {
        gap: 0 48px;
        max-width: unset;
        width: auto;

        > :nth-child(-n + 3) {
            color: var(--color-primary);
        }
    }

    ${media.xlUp} {
        > :nth-child(-n + 4) {
            color: var(--color-primary);
        }
    }
`;

export const ListItem = styled.li`
    color: var(--color-white);
    font-weight: bold;
    width: 33.33%;

    ${media.mdUp} {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        gap: 16px;
    }
`;

export const PlayIcon = styled(Icon).attrs({
    color: "var(--color-white)",
    icon: PLAY
})`
    cursor: pointer;
    left: 50%;
    position: absolute;
    top: calc(50% + 15px);
    transform: translate(-50%, -50%);
    transition: transform var(--quick-transition-duration) ease;
    z-index: var(--z-index-component-foreground);

    ${media.mdUp} {
        left: 50%;
        top: 50%;
    }
`;

export const SelectVideoButton = styled.button<{ rounded?: boolean }>`
    aspect-ratio: 6 / 5;
    background: transparent;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: var(--z-index-homepage-section);

    img {
        transform: translateY(30px);
    }

    /* stylelint-disable-next-line */
    ${({ rounded }) =>
        rounded &&
        css`
            &&& {
                border-radius: 100%;
                height: 80px;
                width: 80px;
            }
        `}

    ::after {
        /* prettier-ignore */
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.37));
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: var(--z-index-homepage-section);
    }

    ${media.mdUp} {
        aspect-ratio: unset;
        border-radius: 10em;
        height: 78px;
        width: 160px;

        img {
            transform: translateY(0);
        }
    }

    :disabled {
        background-size: 110%;
        opacity: 0.5;
    }

    :not(:disabled) {
        :hover,
        :focus {
            ${PlayIcon} {
                transform: translate(-50%, -50%) scale(0.9);
            }
        }
    }
`;

export const ButtonLabel = styled.span`
    align-items: center;
    background-color: var(--color-orange-60);
    color: var(--color-white);
    cursor: pointer;
    display: inline-flex;
    font-weight: bold;
    height: 40px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-component-foreground);
`;
