import { VacancyProps } from "storyblok/components";

import storageReader from "./storageReader";

export default function savedVacancyChecker(
    currentVacancy: VacancyProps | undefined,
    key: string
) {
    if (typeof window === "undefined") return false;

    //if the vacancy is undefined return false
    if (currentVacancy === undefined) return false;

    const localStorageVacancies = storageReader(key);

    //if the key doesnt exist in local storage, return false
    if (localStorageVacancies === null) return false;

    //check if currentvacancy is present in the localstorage array of vacancies
    const result: undefined | VacancyProps = localStorageVacancies.find(v => {
        if (v.slug) return v.slug === currentVacancy.slug;
        if (v.title) return v.title === currentVacancy.title;
    });

    return Boolean(result);
}
