import { VacancyProps } from "storyblok/components";

import storageReader from "./storageReader";

export default function storageAdder(
    currentVacancy: VacancyProps | undefined,
    key: string
) {
    if (typeof window === "undefined") return null;

    if (currentVacancy === undefined) return null;

    //parse savedVacancies from localstorage
    let localStorageVacancies = storageReader(key);

    //if savedVacancies doesnt exist yet, make it
    if (localStorageVacancies === null) {
        localStorageVacancies = [];
    }

    //add vacancy to localstorage
    localStorageVacancies.push(currentVacancy);

    localStorage.setItem(key, JSON.stringify(localStorageVacancies));
}
