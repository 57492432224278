import styled, { css } from "styled-components";

import { Container, Icon } from "components/ui";

import { media } from "constants/media";

export function selctQuoteStyles(
    withQuoteMarks: boolean,
    centeredText: boolean
) {
    if (withQuoteMarks && centeredText) {
        return css`
            ${media.mdUp} {
                padding: 80px 24px;
            }
        `;
    }

    if (!withQuoteMarks && !centeredText) {
        return css`
            ${media.mdUp} {
                padding: 80px 100px;
            }
        `;
    }

    if (withQuoteMarks && !centeredText) {
        return css`
            ${media.mdUp} {
                padding: 80px 24px;
            }
        `;
    }

    return css`
        ${media.mdUp} {
            padding: 80px 0;
        }
    `;
}

function selectTextAlign(centeredText: boolean) {
    if (centeredText) {
        return css`
            text-align: center;
        `;
    }

    return css`
        text-align: left;
    `;
}

export const QuoteContainer = styled(Container)<{
    withQuoteMarks: boolean;
    centeredText: boolean;
}>`
    flex-direction: column;
    gap: 24px;
    padding: 40px 24px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 40px;

        ${({ withQuoteMarks, centeredText }) =>
            selctQuoteStyles(withQuoteMarks, centeredText)};
    }
`;

export const QuoteDecoration = styled(Icon)`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 36px;

    ${media.mdUp} {
        width: 54px;
    }
`;

export const Quote = styled.blockquote<{
    centeredText?: boolean;
}>`
    flex: 1;
    font-family: var(--font-secondary);
    margin: 0;

    ${({ centeredText }) => centeredText && selectTextAlign(centeredText)};

    p {
        font-size: var(--type-heading-2-font-size);
        font-weight: var(--font-weight-semibold);
        line-height: var(--type-heading-2-line-height);
        margin: 0;
    }

    strong {
        color: var(--color-orange-40);
    }

    + ${QuoteDecoration} {
        align-self: flex-end;
        transform: rotate(180deg);
    }

    ${media.mdUp} {
        p {
            font-size: var(--type-heading-1-font-size);
            line-height: var(--type-heading-1-line-height);
        }
    }
`;
