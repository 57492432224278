import { Button, HomepageVideoBlok } from "storyblok/components";
import { MutableRefObject, useRef, useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

import {
    AnimationStyle,
    FramerMotionWrapper,
    VideoPlayerMethods
} from "components/ui";
import { StoryblokButton, StoryblokHeading } from "components/shared/storyblok";

import { MainVideo } from "./mainVideo";
import { StickyScrollWrapper } from "./stickyScrollWrapper";
import { StyledContainer } from "./HomepageVideos.styled";
import { VideoList } from "./videoList";

export interface CurrentVideoState {
    url: string;
    poster: string;
}

export function HomepageVideos({ blok }: HomepageVideoBlok): JSX.Element {
    const { title, description, buttons, videos } = blok;
    const [scrollProgress, setScrollProgress] = useState<number>(0);
    const firstVideo = videos[0];
    const [activeVideo, setActiveVideo] = useState<CurrentVideoState>({
        poster: firstVideo?.poster.filename || "",
        url: firstVideo?.video.filename || ""
    });
    const ref =
        useRef<VideoPlayerMethods>() as MutableRefObject<VideoPlayerMethods>;

    return (
        <StickyScrollWrapper
            scrollLength={200}
            setProgress={setScrollProgress}
            {...storyblokEditable(blok)}
        >
            <StyledContainer>
                {title.length >= 1 && (
                    <FramerMotionWrapper
                        animationType={AnimationStyle.FadeInFromBottom}
                        playWhenInView
                    >
                        <StoryblokHeading {...title[0]} />
                    </FramerMotionWrapper>
                )}
                <FramerMotionWrapper
                    animationType={AnimationStyle.FadeInFromBottom}
                    playWhenInView
                    delay={0.1}
                    duration={0.5}
                >
                    {render(description)}
                </FramerMotionWrapper>
                {buttons.length === 1 && (
                    <FramerMotionWrapper
                        animationType={AnimationStyle.FadeInFromBottom}
                        delay={0.2}
                        duration={0.5}
                    >
                        <StoryblokButton {...buttons[0]} />
                    </FramerMotionWrapper>
                )}
                {buttons.length > 1 && (
                    <FramerMotionWrapper
                        animationType={AnimationStyle.FadeInFromBottom}
                        delay={0.2}
                        duration={0.5}
                    >
                        <ul>
                            {buttons.map((button: Button) => (
                                <StoryblokButton
                                    key={button._uid}
                                    {...button}
                                />
                            ))}
                        </ul>
                    </FramerMotionWrapper>
                )}
            </StyledContainer>
            {videos.length > 0 && (
                <>
                    <MainVideo
                        ref={ref}
                        url={activeVideo.url}
                        poster={activeVideo.poster}
                        scrollProgress={scrollProgress}
                    />
                    {videos.length > 1 && (
                        <VideoList
                            videos={videos}
                            activeVideo={activeVideo}
                            setVideo={setActiveVideo}
                            playerRef={ref}
                        />
                    )}
                </>
            )}
        </StickyScrollWrapper>
    );
}
