import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const FullWidthBackground = styled.section<{ purple: boolean }>`
    background: ${({ purple }) =>
        purple ? "var(--color-purple-40)" : "var(--color-orange-60)"};
`;

export const Stat = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: calc(50% - 16px);

    ${media.mdUp} {
        max-width: 224px;
        width: calc(50% - 48px);
    }

    p {
        color: var(--color-white);
    }
`;

export const Title = styled.p`
    font-family: var(--font-secondary);
    font-size: 40px;
    font-weight: var(--font-weight-bold);
    line-height: 56px;
`;

export const StyledContainer = styled(Container)`
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 40px;
    row-gap: 48px;

    //stylelint-disable-next-line
    ${Stat}:nth-child(odd) {
        margin-right: 32px;
    }

    ${media.mdUp} {
        column-gap: 48px;
        padding-bottom: 80px;
        padding-top: 80px;

        //stylelint-disable-next-line
        ${Stat}:nth-child(odd) {
            margin: unset;
        }
    }
`;
