import styled from "styled-components";

import { Container, Heading } from "components/ui";

import { media } from "constants/media";

export const StyledRoundImagesTextBlock = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 64px auto;

    ${media.mdUp} {
        align-items: center;
        flex-direction: row-reverse;
        gap: 32px;
        margin: 0 auto;
        min-height: 676px;
        padding: 80px 0;
    }

    ${media.lgUp} {
        gap: 97px;
    }
`;

export const ContentWrapper = styled.div`
    position: relative;

    ${media.mdUp} {
        margin-left: 24px;
        width: 50%;
    }
`;

export const Title = styled(Heading)`
    margin-bottom: 16px;
`;

export const Text = styled.div`
    font-size: 16px;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 18px;
    }
`;

export const ImageContainer = styled.div`
    flex: 1 0 auto;
`;

export const ImageWrapper = styled.div`
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        max-height: 463px;
        max-width: 463px;
    }
`;
