import { Button as ButtonType } from "storyblok/components";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { StoryblokBlokData } from "@storyblok/react";
import { StoryblokRichtextContent } from "storyblok-rich-text-react-renderer";
import Cookies from "js-cookie";
import Image from "next/image";
import lhcLogo from "public/images/logos/lhc-logo-full-2024.svg";

import { CLOSE, Icon } from "components/ui";
import {
    CloseButton,
    ImageWrapper,
    LinkWrapper,
    PopUpContent,
    RichtextRenderWrapper,
    StyledButton,
    StyledDialog,
    Title,
    Wrapper
} from "components/popUp/PopUp.styled";
import { RichTextRender } from "components/shared";

import { toSnakeCase } from "helpers/transform";

type PopUpProps = {
    blok: StoryblokBlokData & {
        title: string;
        description: Array<StoryblokRichtextContent>;
        button: Array<ButtonType>;
        cookieName: string;
        cookieDuration: string;
    };
};

function handleEscapeKey(
    event: KeyboardEvent,
    setState: Dispatch<SetStateAction<boolean>>
) {
    if (event.key === "Escape") {
        setState(false);
    }
}

export function PopUp({ blok }: PopUpProps) {
    const [isOpen, setIsOpen] = useState(true);
    const modalRef = useRef<HTMLDialogElement>(null);
    const { title, description, button } = blok;
    const cookieName = toSnakeCase(blok.cookieName);
    const cookieDuration = parseInt(blok.cookieDuration);
    const cookieIsPresent = Cookies.get(cookieName);
    const functionalCookieIsPresent = Cookies.get("cookiesFunctional");

    useEffect(() => {
        const modal = modalRef.current;
        document.addEventListener("keydown", e =>
            handleEscapeKey(e, setIsOpen)
        );

        // if the cookie already exists or if the functional cookie is not present yet, keep the pop up closed
        // if the functional cookie is not present yet, the user has not yet accepted the cookies
        // and we don't want to show the cookie consent and the pop up at the same time
        if (cookieIsPresent || !functionalCookieIsPresent) {
            return;
        }

        // if the cookie doesn't exist, open the pop up and set the cookie
        if (isOpen) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore-next-line
            modal?.showModal();
            Cookies.set(cookieName, "true", { expires: cookieDuration });
        }

        return () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore-next-line
            modal?.close();
            document.removeEventListener("keydown", e =>
                handleEscapeKey(e, setIsOpen)
            );
        };
    }, [
        cookieDuration,
        cookieIsPresent,
        cookieName,
        isOpen,
        functionalCookieIsPresent
    ]);

    return (
        <StyledDialog ref={modalRef}>
            <Wrapper>
                <CloseButton onClick={() => setIsOpen(false)}>
                    <Icon icon={CLOSE} size={24} />
                </CloseButton>

                <ImageWrapper>
                    <Image
                        priority
                        src={lhcLogo}
                        alt="LHC Logo"
                        style={{ objectFit: "contain" }}
                        width={257}
                    />
                </ImageWrapper>

                <PopUpContent>
                    <Title>{title}</Title>

                    <RichtextRenderWrapper>
                        <RichTextRender type="doc" content={description} />
                    </RichtextRenderWrapper>

                    <LinkWrapper>
                        {button.map(btn => (
                            <StyledButton
                                key={btn._uid}
                                buttonType={btn.buttonType}
                                icon={btn.icon}
                                reversedOrder={btn.reversedOrder}
                                size={btn.size}
                                href={btn.href}
                            >
                                {btn.title}
                            </StyledButton>
                        ))}
                    </LinkWrapper>
                </PopUpContent>
            </Wrapper>
        </StyledDialog>
    );
}
