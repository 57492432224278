import useTranslation from "next-translate/useTranslation";

import { NavItem } from "components/shared/header/navigation/item/NavItem";
import { SecondarySubMenu } from "components/shared/header/navigation/secondary/submenu";

import { ListItem, NavList, StyledNav } from "./MainNavigation.styled";
import { MainMenuItems } from "./MainNavigation.data";
import { PrimarySubMenu, SubMenuType } from "./submenu";

import { useMediaQueryContext } from "helpers/hooks";

export type MainMenuItemType = {
    id: string;
    href: string;
    title: string;
    submenu?: {
        primary: SubMenuType;
        secondary?: SubMenuType;
    };
};

export function MainNavigation(): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();
    const { t } = useTranslation();

    return (
        <StyledNav id="navigation" role="navigation" aria-label="Main">
            <NavList>
                {!isDesktopView && (
                    <ListItem>
                        <NavItem
                            id="freelancer"
                            href="/zorgprofessionals/opdrachten"
                            title={t("common:freelance")}
                        >
                            <SecondarySubMenu />
                        </NavItem>
                    </ListItem>
                )}
                {MainMenuItems &&
                    MainMenuItems.map(
                        ({ title, href, id, submenu }: MainMenuItemType) => (
                            <ListItem key={title}>
                                <NavItem id={id} href={href} title={title}>
                                    {submenu && (
                                        <PrimarySubMenu
                                            id={id}
                                            primary={submenu.primary}
                                            secondary={submenu.secondary}
                                        />
                                    )}
                                </NavItem>
                            </ListItem>
                        )
                    )}
            </NavList>
        </StyledNav>
    );
}
