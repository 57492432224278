import styled from "styled-components";

import { Button } from "components/ui";

import { media } from "constants/media";

export const ReviewQuotesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 272px;

    ${media.mdUp} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
`;

export const QuotePictures = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${media.mdUp} {
        flex-direction: column;
        gap: 16px;
        height: 344px;
        width: auto;
    }
`;

export const QuotePictureWrapper = styled.div`
    position: relative;
    width: 72px;

    ${media.mdUp} {
        margin-right: 112px;
    }
`;

export const QuotePicture = styled.div<{ selected?: boolean }>`
    border-radius: 300px;
    cursor: pointer;
    display: block;
    height: 72px;
    margin: 0 auto;
    object-fit: contain;
    opacity: ${({ selected }) => !selected && 0.2};
    overflow: hidden;
    position: relative;
    transform: ${({ selected }) => !selected && "scale(0.9)"};
    transition: all 0.4s ease-in-out;
    width: 72px;

    ${media.mdUp} {
        height: 88px;
        width: 88px;
    }
`;

export const Quotations = styled.div`
    height: 30px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 30px;

    ${media.mdUp} {
        right: -20px;
    }
`;
export const QuoteContent = styled.p`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const QuoteText = styled.h3`
    font-weight: var(--font-weight-semibold);
`;

export const QuoteName = styled.h5`
    font-size: var(--type-heading-5-font-size);
    font-weight: bolder;
    font-weight: 800;
    line-height: var(--type-heading-5-line-height);
`;

export const QuoteSubtitle = styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 8px 0 24px;

    ${media.mdUp} {
        font-size: var(--type-heading-6-font-size);
        line-height: var(--type-heading-6-line-height);
    }
`;

export const BigQuotePictureWrapper = styled.div`
    border-radius: 300px;
    display: block;
    height: 408px;
    min-width: 264px;
    object-fit: contain;
    overflow: hidden;
    position: relative;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;

    ${media.mdUp} {
        gap: 24px;
    }
`;

export const StyledButton = styled(Button)`
    align-self: center;
    width: 100%;

    ${media.mdUp} {
        align-self: flex-end;
        width: fit-content;
    }

    ${media.lgUp} {
        align-self: center;
    }
`;
