import { Button } from "storyblok/components";

import { StandaloneStoryblokButtonWrapper } from "components/standaloneStoryblokButton/StandaloneStoryblokButton.styled";
import { StoryblokButton } from "components/shared";

type StandaloneStoryblokButtonProps = {
    blok: Button & {
        placement?: "left" | "right" | "center";
    };
};

export function StandaloneStoryblokButton({
    blok
}: StandaloneStoryblokButtonProps) {
    return (
        <StandaloneStoryblokButtonWrapper
            placement={blok.placement ?? "center"}
        >
            <StoryblokButton {...blok} />
        </StandaloneStoryblokButtonWrapper>
    );
}
