import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const UspsSection = styled(Container)`
    flex-direction: column;
    gap: 16px;
    padding: 24px;

    > h1 {
        flex: 1;
        font-weight: var(--font-weight-semibold);
    }

    ${media.mdUp} {
        align-items: center;
        flex-direction: row;
        gap: 50px;
        padding: 80px 24px;
    }
`;

export const UspsContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;

    ${media.mdUp} {
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const Text = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
    white-space: pre-wrap;

    h1 {
        font-size: 40px;
        line-height: 64px;
    }

    h2 {
        font-weight: var(--font-weight-semibold);
    }

    p {
        font-family: var(--font-secondary);
        font-size: var(--type-paragraph-large-font-size);
        line-height: var(--type-paragraph-large-line-height);
    }
`;
