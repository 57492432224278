import styled, { css } from "styled-components";

import { CHEVRON_RIGHT, Icon } from "components/ui";

import { MenuType } from "./NavItem";

import { media } from "constants/media";

export const HitBox = styled.div`
    ${media.lgUp} {
        margin-bottom: -25px;
        padding-bottom: 25px;
    }
`;

export const Chevron = styled(Icon).attrs({
    icon: CHEVRON_RIGHT
})<{
    type: MenuType;
}>`
    stroke: var(--color-white);
    transition: all var(--quick-transition-duration) ease;

    ${media.mdUp} {
        transform: rotateZ(90deg);
    }

    ${media.lgUp} {
        stroke: var(--color-black);
    }
`;

const activeState = css`
    ${media.mdUp} {
        background-color: var(--color-grey-45);
        color: var(--color-black);
    }

    ${Chevron} {
        stroke: currentColor;
        transform: rotateZ(-90deg);
    }
`;

export const Anchor = styled.a<{
    type: MenuType;
    isActive: boolean;
}>`
    align-items: center;
    color: var(--color-white);
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    line-height: 56px;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease;
    white-space: nowrap;

    ${media.mdUp} {
        padding: 0 32px;

        ::after {
            border-top: 1px var(--color-orange-55) solid;
            content: "";
            display: ${({ isActive }) => (isActive ? "none" : "block")};
            height: 1px;
            left: 32px;
            position: absolute;
            top: 0;
            width: calc(100% - 64px);
        }
    }

    /* stylelint-disable-next-line */
    ${({ isActive }) => isActive && activeState}

    /* stylelint-disable-next-line no-descending-specificity */
    ${media.lgUp} {
        background-color: transparent;
        border-radius: 10em;
        color: var(--color-black);
        display: inline-flex;
        font-size: 13px;
        gap: 2px;
        justify-content: flex-start;
        line-height: 40px;
        ${
            /* sc-declaration */ ({ type }) =>
                type === "secondary" && "border: 1px solid var(--color-black);"
        }
        padding: 0 12px;
        width: auto;

        ::after {
            display: none;
        }

        :hover,
        :focus,
        :active {
            ${activeState}
        }

        :active {
            background-color: var(--color-grey-50);
        }
    }

    ${media.xlUp} {
        font-size: 14px;
        gap: 4px;
    }
`;
