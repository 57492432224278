import {
    ARROW_DOWN,
    ARROW_LEFT,
    ARROW_RIGHT,
    ARROW_UP,
    BULLSEYE,
    CHECK,
    CHECK_WITH_CIRCLE,
    CHEVRON_DOWN,
    CHEVRON_LEFT,
    CHEVRON_RIGHT,
    CHEVRON_UP,
    CLOCK,
    CLOSE,
    COACHING,
    COLLEAGUES,
    CONVERSATION,
    DOCTOR,
    EURO,
    EXTERNAL_LINK,
    FACEBOOK,
    FIXED_CONTACT,
    FREEDOM,
    FULLSCREEN,
    HANDSHAKE,
    HEALTHCARE,
    HEART,
    HEART_FILLED,
    HIRED,
    INDIVIDUAL_CARE,
    INSTAGRAM,
    LINKEDIN,
    MAIL,
    MAP_PIN,
    MENTAL_HEALTH,
    MOTIVATED,
    NEWS,
    NURSE,
    NUTRITION,
    OFFER,
    PAUSE,
    PERSONAL_DEVELOPMENT,
    PHONE,
    PHONE_ACTIVE,
    PLAY,
    QUOTE_BUBBLE,
    SEARCH,
    SIGNUP,
    SPORT,
    STAR,
    STUDENTS,
    TWITTER,
    USER,
    VERSATILITY,
    VITALITY,
    WHATSAPP,
    WORKING_CONDITIONS,
    YOUTUBE
} from "./IconSet";

export const Icons: { [index: string]: JSX.Element } = {
    ARROW_DOWN,
    ARROW_LEFT,
    ARROW_RIGHT,
    ARROW_UP,
    BULLSEYE,
    CHECK,
    CHECK_WITH_CIRCLE,
    CHEVRON_DOWN,
    CHEVRON_LEFT,
    CHEVRON_RIGHT,
    CHEVRON_UP,
    CLOCK,
    CLOSE,
    COACHING,
    COLLEAGUES,
    CONVERSATION,
    DOCTOR,
    EURO,
    EXTERNAL_LINK,
    FACEBOOK,
    FIXED_CONTACT,
    FREEDOM,
    FULLSCREEN,
    HANDSHAKE,
    HEALTHCARE,
    HEART,
    HEART_FILLED,
    HIRED,
    INDIVIDUAL_CARE,
    INSTAGRAM,
    LINKEDIN,
    MAIL,
    MAP_PIN,
    MENTAL_HEALTH,
    MOTIVATED,
    NEWS,
    NURSE,
    NUTRITION,
    OFFER,
    PAUSE,
    PERSONAL_DEVELOPMENT,
    PHONE,
    PHONE_ACTIVE,
    PLAY,
    QUOTE_BUBBLE,
    SEARCH,
    SIGNUP,
    SPORT,
    STAR,
    STUDENTS,
    TWITTER,
    USER,
    VERSATILITY,
    VITALITY,
    WHATSAPP,
    WORKING_CONDITIONS,
    YOUTUBE
};
