import { StoryblokBlogRichText } from "@storyblok/react";

import { Container } from "components/ui";
import { RichTextRender } from "components/shared";

import { Wrapper } from "./BlogRichText.styled";

import type { FC } from "react";

const BlogRichText: FC<StoryblokBlogRichText> = ({ blok }) => {
    return (
        <Container>
            <Wrapper>
                <RichTextRender type="doc" content={blok.content} />
            </Wrapper>
        </Container>
    );
};
export default BlogRichText;
