import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    ${media.mdUp} {
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
    }
`;

const NavList = styled.ul`
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const SocialsList = styled(NavList)`
    gap: 16px;
`;

export const DisclaimerList = styled(NavList)`
    flex-direction: column;
    gap: 32px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 24px;
    }
`;

export const ClosingText = styled.span`
    color: var(--color-grey-55);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
`;

export const ClosingAnchor = styled(ClosingText).attrs({
    as: "a"
})`
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all var(--quick-transition-duration) ease;

    @media (any-hover: hover) {
        :hover,
        :focus,
        :active {
            color: var(--color-orange-50);
        }
    }
`;
