import styled from "styled-components";

import { Button } from "components/ui";

export const StyledButton = styled(Button)<{ isVisible: boolean }>`
    bottom: 15px;
    box-shadow: 0 76px 80px rgba(0, 0, 0, 0.07), 0 27px 29px rgba(0, 0, 0, 0.04),
        0 13px 14px rgba(0, 0, 0, 0.04), 0 7px 7px rgba(0, 0, 0, 0.03),
        0 3px 3px rgba(0, 0, 0, 0.02);
    left: 16px;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    position: fixed;
    transform: translateX(${({ isVisible }) => (isVisible ? 0 : "-15px")});
    transition: all var(--default-transition-duration) ease;
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
    z-index: var(--z-index-submenu);
`;
