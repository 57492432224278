import { Wrapper } from "./Pointer.styled";

export function Pointer(): JSX.Element {
    return (
        <Wrapper>
            <svg
                width="117"
                height="56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                viewBox="0 0 117 56"
            >
                <path
                    d="M117 .013C103.821-.019 91.018 4.916 80.66 14.02 70.3 23.123 62.992 35.864 59.916 50.18c-.442 1.973-.706 3.847-.971 5.819-.265-1.972-.53-3.846-.971-5.819v-.098c-3.098-14.485-10.538-27.35-21.084-36.458C26.345 4.517 13.327-.287 0 .013h117z"
                    fill="#fff"
                />
            </svg>
        </Wrapper>
    );
}
