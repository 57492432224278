import {
    StoryblokBlokData,
    StoryblokComponent,
    storyblokEditable
} from "@storyblok/react";

import { TermArticleStyledHeader } from "components/termsAndConditions/termArticle/TermArticle.styled";

export type TermArticleType = {
    articleNumber?: string;
    title?: string;
    content?: Array<StoryblokBlokData> | null;
};

export type TermArticleProps = {
    blok: StoryblokBlokData & {
        articleNumber?: string;
        title?: string;
        content?: Array<StoryblokBlokData & TermArticleType> | null;
    };
};

// Created a function for this just so its always the same for the heading and the link to the heading
export const createTermHeading = ({
    title,
    articleNumber
}: {
    title?: string;
    articleNumber?: string;
}) => {
    return `${articleNumber ? `Artikel ${articleNumber} - ` : ""} ${
        title || ""
    }`;
};

function TermArticle({ blok }: TermArticleProps): JSX.Element {
    const { articleNumber, title, content } = blok;

    return (
        <span {...storyblokEditable(blok)}>
            <TermArticleStyledHeader
                headingLevel={2}
                id={createTermHeading({
                    articleNumber: articleNumber,
                    title: title
                })}
            >
                {createTermHeading({
                    articleNumber: articleNumber,
                    title: title
                })}
            </TermArticleStyledHeader>

            {content?.map(block => (
                <StoryblokComponent key={block._uid} blok={block} />
            ))}
        </span>
    );
}

export default TermArticle;
