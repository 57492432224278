import { StoryblokBlokData } from "@storyblok/react";

import { StyledTermTableRow } from "components/termsAndConditions/termTable/TermTable.styled";
import {
    Text,
    Title
} from "components/termsAndConditions/termTableRow/TermTableRow.styled";

export type TermTableRowProps = {
    blok: StoryblokBlokData & {
        title?: string;
        text?: string;
    };
};

function TermTableRow({ blok }: TermTableRowProps): JSX.Element {
    const { title, text } = blok;

    return (
        <StyledTermTableRow>
            <Title>{title}</Title>
            <Text>{text}</Text>
        </StyledTermTableRow>
    );
}

export default TermTableRow;
