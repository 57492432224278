/**
 * Clamp a value by a min and max value
 * @param value The current value as a number
 * @param min The minimum value to be returned
 * @param max The maximum value to be returned
 * @returns {number}
 */
export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}
