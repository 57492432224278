import { VacancyCardProps } from "storyblok/components";
import { useState } from "react";
import Link from "next/link";
import parse from "html-react-parser";

import {
    ARROW_RIGHT,
    CLOCK,
    HEART,
    HEART_FILLED,
    Icon,
    MAP_PIN,
    USER
} from "components/ui";

import {
    Arrow,
    Bottom,
    Category,
    Description,
    Heart,
    Info,
    InfoBlock,
    InfoContent,
    InfoIcon,
    StyledAnchor,
    Title,
    Wrapper
} from "./WishListVacancyCard.styled";

import { getEducationCode } from "helpers/hooks/getEducationCode";
import { useMediaQueryContext } from "helpers/hooks";
import { useWishlistCounter } from "helpers/hooks/useWishlistCounterContext";
import savedVacancyChecker from "helpers/util/localStorage/savedVacancyChecker";
import storageAdder from "helpers/util/localStorage/storageAdder";
import storageRemover from "helpers/util/localStorage/storageRemover";

const localStorageKey = "savedVacancies";

export function WishListVacancyCard({
    className,
    title,
    description,
    href,
    category,
    hours,
    education,
    region,
    overview,
    vacancy
}: VacancyCardProps): JSX.Element {
    const [isVacancySaved, setIsVacancySaved] = useState(
        savedVacancyChecker(vacancy, localStorageKey)
    );

    const { subtractCount } = useWishlistCounter();

    const { isTabletViewOrLarger } = useMediaQueryContext();

    return (
        <Link legacyBehavior href={href} passHref>
            <StyledAnchor href="#">
                <Wrapper className={className} overview={overview}>
                    <Heart
                        onClick={e => {
                            e.preventDefault();
                            if (isVacancySaved) {
                                storageRemover(vacancy, localStorageKey);
                            } else {
                                storageAdder(vacancy, localStorageKey);
                            }
                            setIsVacancySaved(!isVacancySaved);
                            subtractCount?.();
                        }}
                    >
                        <Icon
                            className="heart"
                            icon={isVacancySaved ? HEART_FILLED : HEART}
                            size={20}
                            color="var(--color-orange-60)"
                        />
                    </Heart>
                    <Title>{title}</Title>
                    {description && isTabletViewOrLarger && (
                        <Description>{parse(description + "...")}</Description>
                    )}

                    <Bottom>
                        <Info>
                            {category && <Category>{category}</Category>}
                            {hours && (
                                <InfoBlock>
                                    <InfoIcon>
                                        <Icon
                                            icon={CLOCK}
                                            size={20}
                                            color="var(--color-primary)"
                                        />
                                    </InfoIcon>
                                    <InfoContent>{hours}</InfoContent>
                                </InfoBlock>
                            )}

                            {education && (
                                <InfoBlock>
                                    <InfoIcon>
                                        <Icon
                                            icon={USER}
                                            size={20}
                                            color="var(--color-primary)"
                                        />
                                    </InfoIcon>
                                    <InfoContent>
                                        {getEducationCode(education)}
                                    </InfoContent>
                                </InfoBlock>
                            )}

                            {region && (
                                <InfoBlock>
                                    <InfoIcon>
                                        <Icon
                                            icon={MAP_PIN}
                                            size={20}
                                            color="var(--color-primary)"
                                        />
                                    </InfoIcon>
                                    <InfoContent>{region}</InfoContent>
                                </InfoBlock>
                            )}
                        </Info>
                        <Arrow>
                            <Icon
                                icon={ARROW_RIGHT}
                                size={36}
                                color="var(--color-primary)"
                            />
                        </Arrow>
                    </Bottom>
                </Wrapper>
            </StyledAnchor>
        </Link>
    );
}
