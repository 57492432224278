
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { apiPlugin, storyblokInit } from "@storyblok/react";

import { BaseHead, GoogleTagManager } from "components";
import { CookieConsent } from "components/shared";

import { GlobalStyle, StoryblokComponents } from "config";
import { MediaQueryProvider } from "helpers/hooks";
import { WishlistCounterProvider } from "helpers/hooks/useWishlistCounterContext";

import type { AppProps } from "next/app";

storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_SECRET,
    bridge: true,
    components: StoryblokComponents,
    use: [apiPlugin]
});

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    return (
        <>
            <GlobalStyle />
            <BaseHead />
            <GoogleTagManager>
                <MediaQueryProvider>
                    <WishlistCounterProvider>
                        <CookieConsent />
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Component {...pageProps} />
                    </WishlistCounterProvider>
                </MediaQueryProvider>
            </GoogleTagManager>
        </>
    );
}
const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  