import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { StoryblokPage } from "storyblok/components";

import type { FC } from "react";

export const Page: FC<StoryblokPage> = ({ blok }) => {
    return (
        <div {...storyblokEditable(blok)} key={blok._uid}>
            {blok?.body?.map(nestedBlok => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </div>
    );
};
