import { forwardRef, useEffect } from "react";
import { useThrottle } from "@react-hook/throttle";

import { VideoPlayer, VideoPlayerMethods } from "components/ui";

import { VideoWrapper } from "./MainVideo.styled";

import { clamp } from "helpers/transform";
import { useMediaQueryContext } from "helpers/hooks";

interface AnimationProps {
    width: string;
    height: string;
    scale: number;
}

interface MainVideoProps {
    url: string;
    poster: string;
    scrollProgress: number;
}

const mobileStyle: AnimationProps = {
    height: "auto",
    scale: 1,
    width: "100vw"
};

function calculateStyles(
    viewportWidth: number,
    progress: number,
    containerSize: number
): AnimationProps {
    const styles = {
        height: {
            max: containerSize * 0.642,
            min: containerSize * 0.535
        },
        scale: {
            max: 1.1,
            min: 1
        },
        width: {
            max: viewportWidth * 1.5,
            min: containerSize
        }
    };

    const computedHeight = styles.height.max - styles.height.max * progress;
    const computedWidth = styles.width.max - styles.width.max * progress;

    const height = clamp(computedHeight, styles.height.min, styles.height.max);
    const width = clamp(computedWidth, styles.width.min, styles.width.max);

    return {
        height: `${height / 1.2}px`,
        scale: 1,
        width: `${width}px`
    };
}

export const MainVideo = forwardRef<VideoPlayerMethods, MainVideoProps>(
    function MainVideo(
        { url, scrollProgress, poster }: MainVideoProps,
        forwardedRef
    ) {
        const { containerSize, isMobileView } = useMediaQueryContext();
        const [styles, setStyles] = useThrottle<AnimationProps>(
            {
                height: "925px",
                scale: 1.1,
                width: "150vw"
            },
            90
        );

        useEffect(() => {
            const newStyles = calculateStyles(
                window.innerWidth,
                scrollProgress,
                containerSize
            );
            setStyles(newStyles);
        }, [containerSize, scrollProgress, setStyles]);

        return (
            <VideoWrapper
                animate={isMobileView ? mobileStyle : styles}
                transition={{
                    ease: "easeOut"
                }}
            >
                <VideoPlayer url={url} ref={forwardedRef} poster={poster} />
            </VideoWrapper>
        );
    }
);
