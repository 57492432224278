import styled, { css } from "styled-components";

import { media } from "constants/media";

export const StyledCookieConsent = styled.div<{
    isOpen: boolean;
    onClose: () => void;
}>`
    background: var(--color-white);
    border-radius: 24px;
    bottom: 0;
    box-shadow: var(--dropdown-box-shadow);
    height: fit-content;
    left: 4px;
    margin: auto;
    max-width: 320px;
    padding: 38px 24px 24px;
    position: fixed;
    right: 4px;
    top: 0;
    z-index: var(--z-index-modal-close-button);

    ${media.mdUp} {
        bottom: 24px;
        max-width: 392px;
        padding: 32px;
        right: 24px;
    }
`;

export const Overlay = styled.div`
    backdrop-filter: blur(8px);
    inset: 0;
    position: fixed;
    z-index: var(--z-index-modal-close-button);
`;

export const CloseButton = styled.button`
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--color-white);
    border: unset;
    border-radius: 100%;
    color: var(--color-black);
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    margin: 0 0 auto auto;
    position: absolute;
    right: 8px;
    top: 8px;
    transition: var(--default-transition-duration) ease;
    z-index: 1;

    &:hover {
        background-color: var(--color-orange-60);
        color: var(--color-white);
    }
`;

export const Description = styled.p`
    margin-bottom: 16px;

    ${media.mdUp} {
        margin-bottom: 24px;
    }
`;

export const Options = styled.ul<{ isVisible: boolean }>`
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--default-transition-duration) ease-in-out;

    /* stylelint-disable-next-line */
    ${({ isVisible }) =>
        isVisible &&
        css`
            margin: 24px auto;
            max-height: 200px;
            padding: 0 16px;
            transition: max-height var(--default-transition-duration)
                ease-in-out;
        `}
`;

export const Option = styled.li`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

export const Link = styled.a`
    color: var(--color-black);
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    ${media.mdUp} {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
    }
`;

export const BottomContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: space-between;

    ${media.mdUp} {
        flex-direction: row;
        gap: 35px;
    }
`;

export const MoreInfoButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;

    ${media.mdUp} {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: 32px;
    }
`;

export const PreferencesButton = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;

    ${media.mdUp} {
        cursor: pointer;
        font-size: 18px;
        line-height: 32px;
    }
`;

export const AcceptButton = styled.span<{ isShowingDetails: boolean }>`
    align-items: center;
    background-color: var(--color-orange-60);
    border: 0;
    border-radius: 2em;
    color: var(--color-white);
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    min-height: 56px;
    padding: 16px 24px;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease-in-out;

    &:hover,
    &:focus {
        background-color: var(--color-orange-50);
    }

    &:focus {
        box-shadow: 0 0 8px var(--color-orange-50);
    }

    &:active {
        background-color: var(--color-orange-45);
    }

    /* stylelint-disable-next-line */
    ${({ isShowingDetails }) =>
        isShowingDetails &&
        css`
            flex: 0 1 0;
        `}
`;
