import { useEffect, useState } from "react";

import { MenuBackground, MenuForeGround } from "./HeaderBackground.styled";

import { useMediaQueryContext } from "helpers/hooks";
import { useMenuContext } from "helpers/hooks/useMenuContext";

type HeaderBackgroundProps = {
    stickyBarActive: boolean;
};

export function HeaderBackground({
    stickyBarActive
}: HeaderBackgroundProps): JSX.Element {
    const { isDesktopView, isTabletView } = useMediaQueryContext();
    const { isSubMenuActive, isMobileMenuActive, menuHeight } =
        useMenuContext();
    const [isBackgroundVisible, setIsBackgroundVisible] =
        useState<boolean>(false);

    useEffect(
        function toggleBackground() {
            setIsBackgroundVisible(isSubMenuActive || isMobileMenuActive);

            if (isDesktopView) {
                const timeout = setTimeout(() => {
                    if (!isSubMenuActive) {
                        setIsBackgroundVisible(false);
                    }
                }, 150);

                return () => clearTimeout(timeout);
            }
        },
        [isDesktopView, isSubMenuActive, isMobileMenuActive]
    );

    return (
        <>
            {!isTabletView && (
                <MenuForeGround
                    isMenuActive={isBackgroundVisible}
                    isSubMenuActive={isSubMenuActive}
                    height={menuHeight}
                    stickyBarActive={stickyBarActive}
                />
            )}
            <MenuBackground isMenuActive={isBackgroundVisible} />
        </>
    );
}
