import styled from "styled-components";

import { media } from "constants/media";

export const HeroWrapper = styled.div``;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
    padding: 56px 24px;

    ${media.mdUp} {
        padding: 56px 0 48px;
        width: 593px;
    }
`;
export const TextTitle = styled.h1`
    font-weight: var(--font-weight-medium);
    text-align: center;
`;
export const TextDescription = styled.h5`
    font-weight: var(--font-weight-medium);
    text-align: center;

    p {
        font-size: var(--type-heading-5-font-size);
        line-height: 32px;
    }

    strong {
        color: var(--color-orange-60);
        font-weight: var(--font-weight-medium);
    }
`;
export const HeroImage = styled.div`
    height: 269px;
    overflow: hidden;
    position: relative;
    width: 100vw;

    ${media.lgUp} {
        height: 496px;
    }
`;
