import styled from "styled-components";

import { Icon } from "components/ui";

import { media } from "constants/media";

export const StyledNav = styled.nav<{ showMenu: boolean }>`
    flex: 0 1 auto;
    opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
    order: -1;
    transition: all var(--default-transition-duration) ease;

    ${media.mdUp} {
        display: none;
        order: 1;
        padding: 32px 32px 24px;
        visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
    }

    ${media.lgUp} {
        display: inline-flex;
        opacity: 1;
        padding: 0;
        visibility: visible;
    }
`;

export const NavList = styled.ul`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;

    ${media.mdUp} {
        gap: 15px;
        justify-content: flex-start;
    }
`;

export const ListItem = styled.li`
    align-items: center;
    display: inline-flex;
`;

export const LoginAndSavedJobs = styled.div`
    align-items: center;
    display: flex;
    gap: 24px;
`;

export const StyledIcon = styled(Icon)`
    color: var(--color-white);

    ${media.mdUp} {
        color: var(--color-black);
    }
`;
