import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        color: var(--color-white);
    }
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 32px;
    list-style: none;

    > li {
        align-items: self-start;
        display: flex;
        row-gap: 8px;
    }
`;

export const IconContainer = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: 48px;

    ${media.mdUp} {
        margin-top: 8px;
        width: 64px;
    }
`;
