import { Button, HomepageProfessionalsBlok } from "storyblok/components";
import {
    MARK_BOLD,
    NODE_PARAGRAPH,
    render
} from "storyblok-rich-text-react-renderer";
import { storyblokEditable } from "@storyblok/react";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";
import { StoryblokButton } from "components/shared/storyblok";

import { Avatars } from "./avatars";
import {
    Buttons,
    Content,
    StyledContainer,
    StyledHeading,
    Wrapper
} from "./HomepageProfessionals.styled";

export function HomepageProfessionals({
    blok
}: HomepageProfessionalsBlok): JSX.Element {
    const { title, description, buttons, professionals } = blok;

    return (
        <Wrapper {...storyblokEditable(blok)}>
            <StyledContainer type="grid">
                <Content>
                    <FramerMotionWrapper
                        animationType={AnimationStyle.FadeInFromBottom}
                        playWhenInView
                        duration={0.5}
                    >
                        <StyledHeading>
                            {render(title, {
                                markResolvers: {
                                    [MARK_BOLD]: children => (
                                        <strong>{children}</strong>
                                    )
                                },
                                nodeResolvers: {
                                    [NODE_PARAGRAPH]: children => (
                                        <>{children}</>
                                    )
                                }
                            })}
                        </StyledHeading>
                    </FramerMotionWrapper>
                    <FramerMotionWrapper
                        animationType={AnimationStyle.FadeInFromBottom}
                        delay={0.1}
                        playWhenInView
                        duration={0.5}
                    >
                        {render(description)}
                    </FramerMotionWrapper>
                    <FramerMotionWrapper
                        animationType={AnimationStyle.FadeInFromBottom}
                        delay={0.2}
                        playWhenInView
                        duration={0.5}
                    >
                        {buttons.length === 1 && (
                            <StoryblokButton {...buttons[0]} />
                        )}
                        {buttons.length > 1 && (
                            <Buttons>
                                {buttons.map((button: Button) => (
                                    <li key={button._uid}>
                                        <StoryblokButton {...button} />
                                    </li>
                                ))}
                            </Buttons>
                        )}
                    </FramerMotionWrapper>
                </Content>
                {professionals?.length > 0 && (
                    <Avatars avatars={professionals} />
                )}
            </StyledContainer>
        </Wrapper>
    );
}
