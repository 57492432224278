import styled from "styled-components";

import { media } from "constants/media";

export const ArticleWrapper = styled.div`
    width: 100%;

    ${media.mdUp} {
        display: flex;
        gap: 48px;
    }
`;

export const Wrapper = styled.div`
    padding: 40px 0;
    width: 100%;

    ${media.lgUp} {
        padding: 80px 0;

        h3 {
            margin-bottom: 24px;
        }
    }
`;
