import styled from "styled-components";

import { Container } from "components/ui/container";

import {
    ContactButton,
    PhoneIcon
} from "./preheader/links/PreheaderLinks.styled";

import { media } from "constants/media";

export const LogoContainer = styled.div`
    display: flex;
`;

export const Wrapper = styled.div`
    background-color: var(--color-white);
    position: relative;
    width: 100%;
`;

export const StickyHeader = styled.header`
    background-color: var(--color-white);
    display: flex;
    flex-direction: column-reverse;
    opacity: 0;
    position: fixed;
    top: -100%;
    transition: all 0.5s ease-in-out;
    width: 100%;

    ${media.mdUp} {
        z-index: var(--z-index-header);
    }

    ${LogoContainer} {
        display: none;
    }

    &.active {
        border-bottom: 2px solid var(--color-orange-80);
        box-shadow: var(--dropdown-box-shadow);
        opacity: 1;
        top: 0;
        z-index: var(--z-index-header);

        ${media.mdUp} {
            .preheader-in-sticky-bar {
                width: auto;

                ${PhoneIcon} {
                    background: var(--color-orange-60);
                    border-radius: 50%;
                    display: flex;
                    height: 32px;
                    justify-content: center;
                    margin-top: -4px;
                    width: 32px;

                    svg {
                        color: var(--color-white);
                    }
                }

                span {
                    color: var(--color-grey-60);
                }

                ${ContactButton} {
                    a {
                        background: var(--color-orange-60);
                        color: var(--color-white);
                        padding: 8px 24px;
                    }
                }
            }

            ${LogoContainer} {
                align-items: center;
                display: flex;
                gap: 25px;

                span {
                    color: var(--border-color);
                    font-family: var(--font-secondary);
                    font-size: 20px;
                    line-height: 16px;
                }

                a {
                    display: block;
                    overflow: hidden;
                    width: 72px;
                }
            }
        }

        .preheader {
            display: none;
        }
    }
`;

export const StyledHeader = styled.header`
    background-color: var(--color-white);
    display: flex;
    flex-direction: column-reverse;
    transition: all 0.3s ease-in-out;

    ${media.mdUp} {
        position: relative;
        z-index: var(--z-index-header);
    }
`;

export const StyledContainer = styled(Container)`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px 24px;

    &.sticky {
        padding: 8px 24px;
    }

    .preheader-in-menu {
        display: none;
    }

    ${media.mdUp} {
        justify-content: space-between;
    }
`;

export const Logo = styled.img`
    height: 40px;
    width: 191px;

    ${media.mdUp} {
        margin-left: 10px;
    }

    ${media.xlUp} {
        height: 56px;
        margin-left: 3px;
        width: 248px;
    }
`;
