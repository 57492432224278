import dynamic from "next/dynamic";

import { CLOSE, Icon } from "components/ui";

import {
    IconWrapper,
    StyledContent,
    StyledContentWrapper
} from "./Modal.styled";

import { useBodyScrollLock } from "helpers/hooks/useBodyScrollLock";
import { useMediaQueryContext } from "helpers/hooks";

interface ModalProps {
    onClose: () => void;
    isOpen: boolean;
    children: React.ReactNode;
}

export function Modal({ children, onClose, isOpen }: ModalProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Modal = dynamic(() => import("react-modal"));
    const { isMobileView } = useMediaQueryContext();
    useBodyScrollLock(isOpen);

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            ariaHideApp={false}
            className="react-modal"
            overlayClassName="react-modal-overlay"
            closeTimeoutMS={400}
        >
            <StyledContentWrapper>
                <IconWrapper
                    onClick={() => onClose()}
                    isMobileView={isMobileView}
                >
                    <Icon
                        icon={CLOSE}
                        color="var(--color-white)"
                        size={isMobileView ? 16 : 24}
                    />
                </IconWrapper>
                <StyledContent>{children}</StyledContent>
            </StyledContentWrapper>
        </Modal>
    );
}
