import styled from "styled-components";

import { media } from "constants/media";

export const Button = styled.button<{
    isActive: boolean;
    isVisible: boolean;
    stickyHeader?: boolean;
}>`
    background: ${({ isActive }) =>
        isActive
            ? "var(--color-black)"
            : "linear-gradient(180deg, var(--color-orange-50) 42.19%, var(--color-orange-60) 100%)"};
    border: 0;
    border-radius: 10em;
    bottom: 14px;
    box-shadow: 0 76px 80px rgba(0, 0, 0, 0.07), 0 27px 29px rgba(0, 0, 0, 0.04),
        0 13px 14px rgba(0, 0, 0, 0.04), 0 7px 7px rgba(0, 0, 0, 0.03),
        0 3px 3px rgba(0, 0, 0, 0.02);
    height: 48px;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    position: ${({ stickyHeader }) => (stickyHeader ? "static" : "absolute")};
    right: 8px;
    transform: translateX(${({ isVisible }) => (isVisible ? 0 : "-15px")})
        scale(0.9);
    transition: all var(--quick-transition-duration) ease;
    transition-delay: var(--standard-transition-duration);
    width: 48px;
    z-index: var(--z-index-hamburger);

    ${media.mdUp} {
        bottom: auto;
        position: relative;
        right: auto;
        transform: scale(1);
    }
`;

export const Line = styled.span<{ isActive: boolean }>`
    background-color: var(--color-white);
    border-radius: 2px;
    display: inline-block;
    height: 2px;
    left: 15px;
    position: absolute;
    top: 22px;
    transform-origin: center center;
    transition: all var(--default-transition-duration) ease;
    width: 18px;

    :nth-child(1) {
        transform: ${({ isActive }) =>
            isActive ? "translate(0, 0) rotateZ(45deg)" : "translate(0, -5px)"};
    }

    :nth-child(2) {
        opacity: ${({ isActive }) => (isActive ? 0 : 1)};
    }

    :nth-child(3) {
        transform: ${({ isActive }) =>
            isActive ? "translate(0, 0) rotateZ(-45deg)" : "translate(0, 5px)"};
    }
`;
