import { useState } from "react";

import { CHEVRON_UP, Icon } from "components/ui";

import {
    MoreInformation,
    StyledArrow,
    Title,
    TitleWrapper,
    Wrapper
} from "./Accordion.styled";

interface Props {
    title: string;
    information: string;
}

export function Accordion({ information, title }: Props): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper>
            <TitleWrapper onClick={() => setIsOpen(!isOpen)}>
                <Title>{title}</Title>
                <StyledArrow isOpen={isOpen}>
                    <Icon icon={CHEVRON_UP} size={24} />
                </StyledArrow>
            </TitleWrapper>

            <MoreInformation isOpen={isOpen}>{information}</MoreInformation>
        </Wrapper>
    );
}
