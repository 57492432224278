import { BenefitDescriptionBlockItem, Button } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

import { ContactBanner } from "components";
import { StoryblokButton } from "components/shared/storyblok";

import { Container } from "./BenefitsDescriptionBlock.styled";

export function BenefitsDescriptionBlock({
    contactBannerBlok,
    description,
    link,
    subtitle,
    title
}: BenefitDescriptionBlockItem): JSX.Element {
    return (
        <Container {...storyblokEditable(contactBannerBlok?.[0])}>
            {title && <h2>{title}</h2>}
            {subtitle && <h3>{subtitle}</h3>}
            {description && <p>{description}</p>}
            {link &&
                link.map((entry: Button) => {
                    return (
                        entry.component === "Button" && (
                            <StoryblokButton key={entry._uid} {...entry} />
                        )
                    );
                })}
            <ContactBanner blok={contactBannerBlok?.[0]} />
        </Container>
    );
}

export default BenefitsDescriptionBlock;
