import styled, { css } from "styled-components";

export const PlayButton = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;
    left: 50%;
    padding: 25px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all var(--quick-transition-duration) ease;
    z-index: var(--z-index-component-foreground);
`;

export const Wrapper = styled.div<{ isPlaying: boolean }>`
    aspect-ratio: 16 / 9;
    background-color: var(--color-grey-45);
    cursor: pointer;
    position: relative;

    /* stylelint-disable-next-line */
    ${({ isPlaying }) =>
        isPlaying &&
        css`
            ${PlayButton} {
                opacity: 0;
            }

            :hover ${PlayButton} {
                opacity: 1;
            }
        `}
    ::after {
        /* prettier-ignore */
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.37));
        content: "";
        height: 100%;
        left: 0;
        opacity: ${({ isPlaying }) => (isPlaying ? 0 : 1)};
        position: absolute;
        top: 0;
        transition: all var(--default-transition-duration) ease;
        width: 100%;
    }
`;

export const FullScreenButton = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    bottom: 0;
    display: flex;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 32px;
    z-index: var(--z-index-component-foreground);
`;
