import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const StyledContainer = styled(Container)`
    align-items: center;
    color: var(--color-white);
    flex-direction: column;
    gap: 16px;
    margin: 24px auto;
    max-width: 1200px;
    overflow: hidden;
    text-align: center;

    ${media.mdUp} {
        border-radius: 462.5px;
        height: 642px;
        margin: 48px auto;

        text-align: left;
    }
`;
