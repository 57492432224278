import styled, { css } from "styled-components";

import { Button, Icon } from "components/ui";

import { media } from "constants/media";

export const Container = styled.div<{
    isOpen: boolean;
}>`
    bottom: 0;
    left: 0;
    max-height: calc(100vh - 80px);
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    overflow-y: auto;
    padding: 24px 24px 120px;
    position: fixed;
    transform: translateX(100vw);
    transition: all var(--default-transition-duration) ease;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    width: 100vw;
    z-index: var(--z-index-submenu);

    ${media.mdUp} {
        background-color: var(--color-white);
        bottom: auto;
        left: auto;
        margin-top: -1px;
        max-height: ${({ isOpen }) => (isOpen ? "100vh" : 0)};
        overflow-y: initial;
        padding: 0;
        position: relative;
        transform: translate(0, 0);
    }

    ${media.lgUp} {
        background-color: transparent;
        margin-top: 0;
        max-height: fit-content;
        padding-top: 20px;
        position: absolute;
        top: 74%;
        transform: translateY(${({ isOpen }) => (isOpen ? "0" : "-10px")});
        width: fit-content;
        z-index: var(--z-index-header);
    }

    ${media.xlUp} {
        top: calc(100% - 20px);
    }
`;

export const Title = styled.strong`
    display: block;
    font-family: var(--font-secondary);
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 24px;
`;

export const List = styled.ul`
    background-color: var(--color-white);
    height: max-content;
    list-style: none;
    margin: 0;
    width: 100%;

    ${media.mdUp} {
        padding: 16px 24px 24px;
    }

    ${media.lgUp} {
        border-radius: 0 0 24px 24px;
        box-shadow: 0 76px 80px rgba(0, 0, 0, 0.07),
            0 28px 29px rgba(0, 0, 0, 0.04), 0 13px 14px rgba(0, 0, 0, 0.04),
            0 7px 7px rgba(0, 0, 0, 0.03), 0 3px 3px rgba(0, 0, 0, 0.02);
        width: max-content;
    }
`;

export const Anchor = styled.a`
    align-items: center;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    line-height: 32px;
    padding: 8px 0;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease;

    ${media.lgUp} {
        display: inline-flex;
        line-height: 24px;

        :hover {
            color: var(--color-orange-50);
        }
    }
`;

export const ListItem = styled.li<{
    isFirst?: boolean;
    isLast?: boolean;
}>`
    border-bottom: 1px solid var(--color-grey-50);
    margin: 0;
    padding: 0;

    ${media.mdUp} {
        border-bottom: 0;

        & + & {
            border-top: 1px solid var(--color-grey-50);
        }
    }

    ${media.lgUp} {
        & + & {
            border-top: 0;
        }

        /* stylelint-disable-next-line order/order */
        ${({ isFirst }) =>
            isFirst &&
            css`
                border-bottom: 1px solid var(--color-grey-50);
                margin-bottom: 16px;
                padding: 0 0 8px;

                ${StyledIcon} {
                    margin-left: 8px;
                }

                ${Anchor}:hover ${StyledIcon} {
                    transform: translateX(5px);
                }
            `}

        ${({ isLast }) =>
            isLast &&
            css`
                margin-top: 8px;
            `}
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 8px;
    transition: all var(--quick-transition-duration) ease;
`;

export const Text = styled.span`
    flex: 1 1 auto;
`;

export const StyledButton = styled(Button).attrs({
    buttonType: "ghost-dark",
    size: "small"
})`
    justify-content: center;
    text-align: center;
    width: 100%;
`;
