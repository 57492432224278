import styled from "styled-components";

import { Icon } from "components/ui/icon";

import { media } from "constants/media";

export const StyledSliderControls = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    justify-content: space-between;
    margin-top: 24px;

    ${media.mdUp} {
        margin-top: 0;
    }
`;

export const SlideIndicatorWrapper = styled.div`
    background-color: var(--color-article-border);
    border-radius: 15px;
    height: 4px;
    margin-right: 24px;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        margin-right: 56px;
    }
`;

export const SlideIndicator = styled.div<{
    widthPercentage: number;
    color: string;
}>`
    background-color: var(--color-black);
    border-radius: 15px;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    transition: width var(--default-transition-duration) ease-in-out;
    width: ${props => props.widthPercentage}%;
`;

export const ArrowsWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
`;

export const PreviousIcon = styled(Icon)``;
