import { Button, ServicesPageProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

import { Avatars } from "components/homepage/zorgverleners/avatars";
import {
    AvatarsWrapper,
    ButtonsWrapper,
    ContentWrapper,
    StyledContainer,
    StyledDescription,
    StyledHeading,
    StyledStoryblokButton
} from "components/shared/avatars/AvatarsContainer.styled";

function AvatarsContainer({ blok }: ServicesPageProps): JSX.Element {
    const { title, description, professionals, buttons } = blok;

    return (
        <StyledContainer {...storyblokEditable(blok)}>
            <AvatarsWrapper>
                <Avatars avatars={professionals} />
            </AvatarsWrapper>
            <ContentWrapper>
                <StyledHeading headingLevel={2}>{title}</StyledHeading>
                <StyledDescription>{description}</StyledDescription>
                {buttons && (
                    <ButtonsWrapper>
                        {buttons.map((entry: Button) => {
                            return (
                                entry.component === "Button" && (
                                    <StyledStoryblokButton
                                        key={entry._uid}
                                        {...entry}
                                    />
                                )
                            );
                        })}
                    </ButtonsWrapper>
                )}
            </ContentWrapper>
        </StyledContainer>
    );
}

export default AvatarsContainer;
