export function toTitleCase(str: string): string {
    return str
        .replace(/\w\S*/g, function (txt: string) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
        .split("-")
        .join(" ");
}

export function toSnakeCase(str: string): string {
    return str.split(" ").join("-").toLowerCase();
}

export function capitalizeFirstChar(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
