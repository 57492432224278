import styled from "styled-components";

import { Container } from "components/ui";
import { SlideIndicatorWrapper } from "components/shared/sliderControls/SliderControls.styled";
import SliderControls from "components/shared/sliderControls/SliderControls";

import { media } from "constants/media";

export const Wrapper = styled.div`
    background: var(--color-grey-45);
    width: 100%;
`;

export const StyledSliderControls = styled(SliderControls)`
    margin-top: 16px;

    ${media.mdUp} {
        margin-top: 0;
    }
`;

export const StyledContainer = styled(Container)`
    flex-direction: column;
    padding: 40px 24px;

    .slider-container {
        width: 100%;
    }

    .slider-frame {
        overflow: visible !important;
    }

    .slide-slide:focus {
        outline: none;
    }
`;

export const NameAndFunction = styled.div`
    p {
        font-size: 14px;
    }

    span {
        font-size: 15px;
        font-weight: var(--font-weight-extra-bold);
        margin-left: 16px;
    }
`;

export const StyledTitle = styled.h2`
    display: flex;
    font-weight: normal;
    justify-content: space-between;
    margin-bottom: 16px;

    ${SlideIndicatorWrapper} {
        display: none;
    }

    ${media.mdUp} {
        margin-bottom: 32px;
    }
`;

export const CreatedAt = styled.div`
    color: var(--color-grey-55);
    font-size: 12px;
    font-weight: var(--font-weight-extra-bold);
    margin-bottom: 16px;
    text-transform: uppercase;

    ${media.mdUp} {
        margin-bottom: 24px;
    }
`;

export const Story = styled.article`
    background: var(--color-white);
    border-radius: 24px;
    box-shadow: 0 2px 4px var(--color-grey-50);
    margin-right: 16px;
    outline: none;
    padding: 24px;
    width: 254px;

    a {
        outline: none;
        text-decoration: none;
    }

    ${media.mdUp} {
        width: 472px;
    }
`;

export const Text = styled.p`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    //stylelint-disable-next-line
    display: -webkit-box;
    font-family: var(--font-secondary);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ImageAndFunction = styled.div`
    align-items: center;
    display: flex;
`;

export const Professional = styled.span`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
    position: relative;
    width: 100%;

    p {
        font-size: 13px;
        line-height: 16px;
        margin: 0 0 0 16px;
    }
`;

export const ImageWrapper = styled.div`
    border: 8px solid var(--color-grey-45);
    border-radius: 50%;
    display: flex;
    height: 64px;
    position: relative;
    width: 64px;

    img {
        border-radius: 50%;
    }
`;

export const Function = styled.p`
    align-items: center;
    display: flex;
    font-weight: var(--font-weight-extra-bold);
    justify-content: space-between;
    margin: 0;

    a {
        align-items: center;
        display: flex;
        flex-direction: row;
        font-weight: 700;
        justify-content: space-between;
        margin: 0;
        text-decoration: none;
    }

    p {
        margin: 0;
    }
`;

export const StyledLink = styled.a`
    align-items: center;
    color: var(--color-primary);
    display: flex;
    font-family: var(--font-secondary);
    font-weight: 700;
    margin-top: 40px;
    text-decoration: none;

    p {
        min-width: 16px;
    }

    ${media.mdUp} {
        margin: 32px 0;
    }
`;
