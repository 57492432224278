import { ReviewQuotesProps } from "storyblok/components";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/legacy/image";
import Link from "next/link";

import { Container, Icon } from "components/ui";
import { QUOTE_BUBBLE } from "components/ui/icon";

import {
    BigQuotePictureWrapper,
    Quotations,
    QuoteContent,
    QuoteName,
    QuotePicture,
    QuotePictureWrapper,
    QuotePictures,
    QuoteSubtitle,
    QuoteText,
    ReviewQuotesWrapper,
    StyledButton,
    Wrapper
} from "./ReviewQuotes.styled";

import { useMediaQueryContext } from "helpers/hooks";

const STORIES_PAGE_URL = "/zorgprofessionals/ervaringen";

export function ReviewQuotes({ blok }: ReviewQuotesProps) {
    const router = useRouter();

    const { content, CTAButton, CTAButtonText } = blok;

    // Extract the last slug from the router query and use it as the jobType
    // Structure of the slug is: [zorgprofessionals, verpleegkundigen]
    const {
        query: { slug }
    } = router;
    const jobType = Array.isArray(slug) && [...slug].pop();
    const fullStoriesPageUrl =
        STORIES_PAGE_URL + `${jobType ? `?filter=${jobType}` : ""}`;

    const { isDesktopView } = useMediaQueryContext();
    const [selectedQuote, setSelectedQuote] = useState(content?.[0]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (window) {
            setLoading(false);
        }
    }, [setLoading]);

    return (
        <Container>
            <Wrapper>
                <ReviewQuotesWrapper>
                    <QuotePictures>
                        {content &&
                            content.map(quote => {
                                const isSelected =
                                    quote._uid === selectedQuote?._uid;
                                return (
                                    <QuotePictureWrapper key={quote._uid}>
                                        <QuotePicture
                                            selected={isSelected}
                                            onClick={() =>
                                                setSelectedQuote(quote)
                                            }
                                        >
                                            {quote.image.filename && (
                                                <Image
                                                    src={`${quote.image.filename}/m/`}
                                                    layout="fill"
                                                    objectFit="cover"
                                                />
                                            )}
                                        </QuotePicture>
                                        {isSelected && (
                                            <Quotations>
                                                <Icon
                                                    color="transparent"
                                                    icon={QUOTE_BUBBLE}
                                                    viewbox="0 0 24 24"
                                                    size={34}
                                                />
                                            </Quotations>
                                        )}
                                    </QuotePictureWrapper>
                                );
                            })}
                    </QuotePictures>
                    {selectedQuote && !loading && (
                        <QuoteContent>
                            <QuoteName>{selectedQuote.name}</QuoteName>
                            <QuoteSubtitle>
                                {selectedQuote.subtitle}
                            </QuoteSubtitle>
                            <QuoteText>
                                &quot;{selectedQuote.text}&quot;
                            </QuoteText>
                        </QuoteContent>
                    )}

                    {isDesktopView && (
                        <BigQuotePictureWrapper>
                            <Image
                                src={selectedQuote.image.filename}
                                layout="fill"
                                objectFit="cover"
                            />
                        </BigQuotePictureWrapper>
                    )}
                </ReviewQuotesWrapper>

                {CTAButton && CTAButtonText && (
                    <Link passHref legacyBehavior href={fullStoriesPageUrl}>
                        <StyledButton buttonType="primary">
                            {CTAButtonText}
                        </StyledButton>
                    </Link>
                )}
            </Wrapper>
        </Container>
    );
}
