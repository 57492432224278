import Link from "next/link";

import { Anchor, Description, StyledAvatar, Title } from "./Story.styled";

type StoryProps = {
    title: string;
    href: string;
    description: string;
    image: string;
};

export function Story({
    title,
    href,
    description,
    image
}: StoryProps): JSX.Element {
    return (
        <Link legacyBehavior href={href} passHref prefetch={false}>
            <Anchor>
                <StyledAvatar imageUrl={image} />
                <div>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </div>
            </Anchor>
        </Link>
    );
}
