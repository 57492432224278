import styled from "styled-components";

import { media } from "constants/media";

export const NavList = styled.ul`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    width: 100%;

    ${media.mdUp} {
        gap: 20px;
        justify-content: flex-start;
    }

    li {
        a {
            align-items: center;
            display: flex;
            gap: 4px;
        }
    }
`;

export const NewsButton = styled.li`
    display: none;

    ${media.mdUp} {
        display: block;
    }
`;

export const Anchor = styled.a`
    font-size: 14px;
    line-height: 32px;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease;

    ${media.mdUp} {
        color: var(--color-white);

        :hover,
        :focus,
        :active {
            text-decoration: underline;
        }
    }
`;

export const ContactButton = styled.li`
    a {
        background: var(--color-orange-60);
        border-radius: 2em;
        color: var(--color-white);
        padding: 6px 32px;

        ${media.mdUp} {
            background: var(--color-white);
            color: var(--color-orange-60);
            padding: 0 16px;
        }
    }
`;

export const MeetButton = styled.li`
    display: none;

    ${media.mdUp} {
        display: block;
    }

    a {
        color: var(--color-orange-60);
        font-size: 15px;
        font-weight: 800;
        text-decoration: underline;
    }
`;

export const PhoneIcon = styled.div`
    align-items: center;
    background: var(--color-orange-60);
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-top: -4px;
    width: 32px;

    svg {
        color: var(--color-white);
    }

    ${media.mdUp} {
        background: var(--color-white);
        height: 24px;
        width: 24px;

        svg {
            color: var(--color-orange-60);
        }
    }
`;

export const ContactAndNavToggle = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
`;
