import { CHECK_WITH_CIRCLE, Icon } from "components/ui";

import { IconContainer, Item } from "./Usp.styled";

export function Usp({
    text,
    iconColor
}: {
    text: string;
    iconColor: string;
}): JSX.Element {
    return (
        <Item bgColor={iconColor}>
            <div>
                <IconContainer>
                    <Icon
                        icon={CHECK_WITH_CIRCLE}
                        size={20}
                        color="var(--color-white)"
                    />
                </IconContainer>
                <span>{text}</span>
            </div>
        </Item>
    );
}

export default Usp;
