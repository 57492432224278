import styled, { css } from "styled-components";

import { media } from "constants/media";

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    ${media.mdUp} {
        flex-wrap: nowrap;
    }
`;

export const StyledContent = styled.div`
    background: var(--color-white);
    color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;

    ${media.mdUp} {
        flex: 1 1 50%;
    }
`;

export const IconWrapper = styled.div<{ isMobileView: boolean }>`
    align-items: center;
    background-color: var(--color-black);
    border-radius: 50%;
    bottom: 16px;
    cursor: pointer;
    display: flex;
    height: 56px;
    justify-content: center;
    left: 16px;
    position: absolute;
    transition-duration: var(--default-transition-duration);
    transition-property: background-color;
    transition-timing-function: ease;
    width: 56px;
    z-index: var(--z-index-modal-close-button);

    &:hover {
        background-color: var(--color-orange-40);
    }

    ${media.mdUp} {
        bottom: unset;
        left: -27px;
        top: 62px;
    }
`;

export const ModalBase = css`
    .react-modal {
        background: var(--color-white);
        border-radius: 4px;
        box-shadow: var(--dropdown-box-shadow);
        height: 100%;
        left: unset;
        opacity: 0;
        outline: none;
        overflow: visible;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s opacity ease-in-out;
        width: 100%;
        z-index: var(--z-index-modal);

        svg g {
            fill: var(--color-secondary);
        }

        &-overlay {
            backdrop-filter: blur(12px);
            background: rgba(29, 29, 29, 0.2);
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: var(--z-index-modal);
        }

        ${media.mdUp} {
            width: 744px;
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ReactModal__Overlay {
        &--after-open .react-modal {
            opacity: 1;
        }

        &--before-close .react-modal {
            opacity: 0;
        }
    }
`;
