export const GTM_ID = process.env.GTM_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
    window?.dataLayer?.push({
        event: "pageview",
        page: url
    });
};

// pulled from https://github.com/sahava/datalayer-typescript/blob/master/types.ts#L11
interface EventData {
    category: string;
    action: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: EventData): void => {
    window?.dataLayer?.push({
        event: action,
        event_category: category,
        event_label: label,
        value
    });
};
