import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

import { ARROW_RIGHT, CLOSE, Checkbox, Icon } from "components";

import {
    AcceptButton,
    BottomContainer,
    CloseButton,
    Description,
    Link,
    Option,
    Options,
    Overlay,
    PreferencesButton,
    StyledCookieConsent
} from "./CookieConsent.styled";

function pushPreferencesToDataLayer(statistics: boolean, marketing: boolean) {
    window?.dataLayer?.push({ event: "cookies_functional" });

    if (statistics) {
        window?.dataLayer?.push({ event: "cookies_statistics" });
    }

    if (marketing) {
        window?.dataLayer?.push({ event: "cookies_marketing" });
    }
}

export function CookieConsent(): JSX.Element | null {
    const { register, watch, setValue } = useForm();
    const [isOpen, setIsOpen] = useState(true);
    const [showDetailedCookies, setShowDetailedCookies] = useState(false);
    const [savedPreferences, setSavedPreferences] = useState(true);

    useEffect(() => {
        // Checks if the cookies have been already set locally.
        const funcitionalCookieExists = Cookies.get("cookiesFunctional");
        const statisticsCookieExists = Cookies.get("cookiesStatistics");
        const marketingCookieExists = Cookies.get("cookiesMarketing");

        if (
            funcitionalCookieExists === undefined ||
            statisticsCookieExists === undefined ||
            marketingCookieExists === undefined
        ) {
            setSavedPreferences(false);
        }
    }, []);

    function saveCookiePreferences(acceptAll = false) {
        const isFunctionalAllowed = true;

        const isStatisticsAllowed = acceptAll
            ? true
            : watch("cookiesStatistics");
        const isMarketingAllowed = acceptAll ? true : watch("cookiesMarketing");

        Cookies.set("cookiesFunctional", isFunctionalAllowed.toString(), {
            expires: 7
        });

        Cookies.set("cookiesStatistics", isStatisticsAllowed.toString(), {
            expires: 7
        });

        Cookies.set("cookiesMarketing", isMarketingAllowed.toString(), {
            expires: 7
        });

        pushPreferencesToDataLayer(isStatisticsAllowed, isMarketingAllowed);
        setSavedPreferences(true);
    }

    const { route } = useRouter();
    const isCookieModalHidden = savedPreferences || route === "/privacy-policy";

    if (isCookieModalHidden) {
        return null;
    }

    return (
        <>
            <Overlay onClick={() => saveCookiePreferences(false)} />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledCookieConsent
                isOpen={isOpen}
                onClose={() => setIsOpen(!isOpen)}
            >
                <CloseButton onClick={() => saveCookiePreferences(false)}>
                    <Icon icon={CLOSE} size={24} />
                </CloseButton>

                <Description>
                    Om voor een optimale gebruikerservaring te zorgen gebruiken
                    wij cookies. Hiermee kunnen wij advertenties personaliseren
                    en websiteverkeer analyseren.{" "}
                    <Link href="/privacy-cookies">Meer informatie</Link>
                </Description>
                <Options isVisible={showDetailedCookies}>
                    <Option>
                        <Checkbox
                            {...register("cookiesFunctional")}
                            activeValue="true"
                            currentValue="true"
                            disabled
                            label="Functioneel"
                        />
                    </Option>
                    <Option>
                        <Checkbox
                            {...register("cookiesStatistics")}
                            activeValue="true"
                            currentValue={watch(
                                "cookiesStatistics"
                            )?.toString()}
                            label="Statistieken"
                            onChange={() =>
                                setValue(
                                    "cookiesStatistics",
                                    !watch("cookiesStatistics")
                                )
                            }
                        />
                    </Option>
                    <Option>
                        <Checkbox
                            {...register("cookiesMarketing")}
                            activeValue="true"
                            currentValue={watch("cookiesMarketing")?.toString()}
                            label="Marketing"
                            onChange={() =>
                                setValue(
                                    "cookiesMarketing",
                                    !watch("cookiesMarketing")
                                )
                            }
                        />
                    </Option>
                </Options>
                <BottomContainer>
                    <PreferencesButton
                        onClick={() =>
                            setShowDetailedCookies(!showDetailedCookies)
                        }
                    >
                        Voorkeuren
                    </PreferencesButton>
                    <AcceptButton
                        isShowingDetails={showDetailedCookies}
                        onClick={() =>
                            showDetailedCookies
                                ? saveCookiePreferences(false)
                                : saveCookiePreferences(true)
                        }
                    >
                        <span>
                            {showDetailedCookies
                                ? "Opslaan"
                                : "Alles accepteren"}
                        </span>
                        <Icon icon={ARROW_RIGHT} size={24} />
                    </AcceptButton>
                </BottomContainer>
            </StyledCookieConsent>
        </>
    );
}
