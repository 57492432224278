import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    background-color: var(--color-orange-50);
    margin: -88px 0 144px;
    overflow-y: hidden;
    padding: 88px 0;

    ${media.mdUp} {
        background: var(--color-orange-40);
        margin-bottom: 88px;
        padding: 0;
    }
`;

export const StyledContainer = styled(Container)`
    display: grid;
    position: relative;

    ${media.lgUp} {
        padding: 0;
    }
`;

export const Content = styled.div`
    display: grid;
    flex-direction: column;
    gap: 16px;
    grid-column: span 4;
    width: 100%;
    z-index: var(--z-index-component-foreground);

    article {
        color: var(--color-white);
        font-size: 16px;
        font-style: italic;
        line-height: 24px;
        max-width: 272px;
        width: 100%;

        ${media.lgUp} {
            font-size: 40px;
            line-height: 64px;
            max-width: unset;
        }
    }

    ${media.lgUp} {
        align-items: flex-start;
        grid-column-end: 11;
        grid-column-start: 2;
        text-align: left;
    }
`;

export const Author = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    grid-template-columns: [first] 64px [second] 192px;
    text-align: left;

    /* stylelint-disable-next-line*/
    ${media.mdUp} {
        grid-template-columns: [first] 64px [second] 384px;
    }
`;

export const Info = styled.span`
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
        font-weight: 600;
    }
`;

export const Name = styled.span`
    margin-bottom: 8px;
`;
