import Image from "next/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { Icon, MAIL, PHONE } from "components/ui";

import {
    Address,
    AddressLink,
    ContactLink,
    ImageWrapper,
    ListItem,
    Logo,
    NBBULogo,
    NavList,
    Navigation,
    QualityMarks,
    Section,
    StyledContainer,
    StyledFooter
} from "./Footer.styled";
import { FooterBottom } from "./bottom";
import { FooterLink, FooterLinkType } from "./link";
import { FooterUsp } from "./usp";

export function Footer(): JSX.Element {
    const { t } = useTranslation();

    const footerLinks: Array<FooterLinkType> = [
        {
            href: "/zorgprofessionals",
            title: t("common:caretaker")
        },
        {
            href: "/opdrachtgevers/voordelen",
            title: t("common:clients")
        },
        // {
        //     href: "/diensten",
        //     title: t("common:whatwedo")
        // },
        {
            href: "/over-ons",
            title: t("common:aboutus")
        }
    ];

    return (
        <StyledFooter>
            <StyledContainer>
                <Section>
                    <Logo
                        src="/images/logos/lhc-logo-full-black-orange.png"
                        width={266}
                        height={57}
                        alt="Logo Lead Healthcare"
                        loading="lazy"
                    />
                    <FooterUsp
                        type="experience"
                        title={t("common:yearsexperience")}
                    />
                    <FooterUsp type="award" title={t("common:nominated")} />

                    <QualityMarks>
                        <NBBULogo
                            src="/images/logos/NBBU.png"
                            alt="NBBU logo"
                            width={75}
                            height={27}
                            loading="lazy"
                        />

                        <Link href="/" passHref>
                            <ImageWrapper>
                                <Image
                                    src="/images/logos/sbb-beeldmerk.webp"
                                    alt="sbb logo"
                                    fill
                                    style={{ objectFit: "contain" }}
                                />
                            </ImageWrapper>
                        </Link>
                    </QualityMarks>
                </Section>
                <Section>
                    <Navigation>
                        <NavList>
                            {footerLinks.map(
                                ({ href, title }: FooterLinkType) => (
                                    <ListItem key={href}>
                                        <FooterLink href={href} title={title} />
                                    </ListItem>
                                )
                            )}
                        </NavList>
                    </Navigation>
                </Section>
                <Section>
                    <Address>
                        <strong>Lead Healthcare HQ</strong>
                        <br />
                        Luitenant Generaal van Heutszlaan 8, <br />
                        3743 JN Baarn, {t("common:thenetherlands")}
                        <NavList>
                            <ContactLink>
                                <FooterLink
                                    href="/contact"
                                    title="Contact / FAQ"
                                />
                            </ContactLink>
                            <li>
                                <AddressLink
                                    href="tel:0355430234"
                                    className="gtm-call-us"
                                >
                                    <Icon
                                        icon={PHONE}
                                        size={24}
                                        color="var(--color-orange-40)"
                                    />
                                    035 - 5430234
                                </AddressLink>
                            </li>
                            <li>
                                <AddressLink
                                    noMargin
                                    className="gtm-mail-us"
                                    href="mailto:info@leadhealthcare.nl"
                                >
                                    <Icon
                                        icon={MAIL}
                                        size={24}
                                        color="var(--color-orange-40)"
                                    />
                                    info@leadhealthcare.nl
                                </AddressLink>
                            </li>
                        </NavList>
                    </Address>
                </Section>
                <FooterBottom />
            </StyledContainer>
        </StyledFooter>
    );
}
