import { BenefitItem } from "storyblok/components";

import { Icon, Icons } from "components/ui";

import { Container, IconContainer, List } from "./Benefits.styled";

export function Benefits({
    items
}: {
    items: Array<BenefitItem>;
}): JSX.Element {
    return (
        <Container>
            <List>
                {items &&
                    items?.map(benefit => (
                        <li key={benefit.title}>
                            <IconContainer>
                                <Icon
                                    icon={Icons[benefit.icon]}
                                    size={64}
                                    viewbox="0 0 64 64"
                                />
                            </IconContainer>
                            <div>
                                <h4>{benefit.title}</h4>
                                <p>{benefit.description}</p>
                            </div>
                        </li>
                    ))}
            </List>
        </Container>
    );
}

export default Benefits;
