import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    background: var(--color-grey-50);
    overflow-y: hidden;
`;

export const StyledContainer = styled(Container)`
    flex-direction: column;
    padding: 40px 24px 24px;
    position: relative;
    z-index: var(--z-index-homepage-section);

    h4 {
        margin-bottom: 24px;
    }

    ${media.mdUp} {
        height: 900px;
        padding: 0;
        width: 100%;

        h4 {
            margin-bottom: 40px;
        }
    }

    ${media.lgUp} {
        width: 100%;
    }
`;

export const LeftContent = styled.div`
    display: none;
    position: relative;
    visibility: hidden;

    ${media.mdUp} {
        display: block;
        visibility: visible;
        width: 50%;
    }
`;

export const RightContent = styled.div`
    background: var(--color-grey-50);

    ${media.mdUp} {
        max-height: 900px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 40px 24px;
        width: 50%;

        //Remove scrollbar from container
        //stylelint-disable
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */

        ::-webkit-scrollbar {
            display: none;
        }
        //stylelint-enable

        ${media.mdUp} {
            cursor: ns-resize;
        }

        ${media.lgUp} {
            padding: 40px 56px;
        }
    }
`;

export const Content = styled.div`
    border-radius: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 688px;

    ${media.mdUp} {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const StyledProspect = styled.div`
    background: var(--color-white);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 24px;
    max-width: 520px;

    ${media.mdUp} {
        flex-direction: column;
        margin: 0 0 40px;
        min-height: unset;
    }
`;

export const Text = styled.span`
    font-family: var(--font-secondary);
    font-size: 20px;
    line-height: 32px;
    padding: 24px;
`;

export const ImageWrapper = styled.div`
    height: 112px;
    position: relative;
    width: 100%;

    img {
        border-radius: 24px 24px 0 0;
    }

    ${media.mdUp} {
        min-height: 192px;
        width: 100%;
    }
`;

export const DesktopTitle = styled.p`
    align-items: center;
    color: var(--color-white);
    display: flex;
    font-family: var(--font-secondary);
    font-size: 48px;
    font-weight: 600;
    height: 900px;
    justify-content: center;
    line-height: 60px;
    padding: 0 56px;
    position: absolute;
    width: 100%;
    z-index: var(--z-index-component-foreground);

    //stylelint-disable-next-line
    ${media.lgUp} {
        font-size: 96px;
        line-height: 120px;
    }
`;

export const DesktopImageWrapper = styled.div`
    height: 900px;
    position: relative;
    width: 100%;
`;
