import React from "react";
import Script from "next/script";
import dynamic from "next/dynamic";

import { Footer, Header, Metadata } from "components/shared";

import { Main, Wrapper } from "./Page.styled";

import { useMediaQueryContext } from "helpers/hooks";

const Breadcrumbs = dynamic(
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    //@ts-ignore
    () => import("components/ui").then(m => m.Breadcrumbs),
    { ssr: false }
);

type PageProps = {
    title?: string;
    description?: string;
    hideStickyHeader?: boolean;
    children: React.ReactNode;
};

export function Page({
    title,
    description,
    hideStickyHeader = false,
    children
}: PageProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();

    return (
        <>
            <Script
                id="trengo"
                dangerouslySetInnerHTML={{
                    __html: `
                                window.Trengo = window.Trengo || {};
                                window.Trengo.key = '5880LfFicCf9ixpz5lS8';
                            
                                // Create and append the script element
                                var script = document.createElement('script');
                                script.type = 'text/javascript';
                                script.async = true;
                                script.src = 'https://static.widget.trengo.eu/embed.js';
                            
                                document.head.appendChild(script);
                            `
                }}
            />
            <Metadata title={title} description={description} />
            <Wrapper>
                <Header hideStickyHeader={hideStickyHeader} />
                {/*eslint-disable-next-line*/}
                {/* @ts-ignore*/}
                {!isMobileView && <Breadcrumbs />}
                <Main>{children}</Main>
                <Footer />
            </Wrapper>
        </>
    );
}
