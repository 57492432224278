import { StatsProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";

import {
    FullWidthBackground,
    Stat,
    StyledContainer,
    Title
} from "./Stats.styled";

export function Stats({ blok }: StatsProps): JSX.Element {
    const { stat, isPurple } = blok;

    return (
        <FullWidthBackground purple={isPurple} {...storyblokEditable(blok)}>
            <StyledContainer>
                {stat.map((entry, index) => (
                    <Stat key={index}>
                        <FramerMotionWrapper
                            playWhenInView
                            animationType={AnimationStyle.FadeIn}
                            duration={0.5}
                            delay={index * 0.33}
                        >
                            <Title>{entry.number}</Title>
                            <p>{entry.subject}</p>
                        </FramerMotionWrapper>
                    </Stat>
                ))}
            </StyledContainer>
        </FullWidthBackground>
    );
}
