import { Office } from "storyblok/components";
import Image from "next/legacy/image";

import {
    Address,
    ContactItem,
    ImageWrapper,
    InfoWrapper,
    OfficeItem,
    OfficesHeading,
    OfficesWrapper,
    StyledOffices
} from "components/offices/Offices.styled";
import { Heading, Icon, MAIL, PHONE } from "components/ui";

export type OfficesProps = {
    offices: Array<Office>;
};

export function Offices({ offices }: OfficesProps): JSX.Element {
    return (
        <StyledOffices>
            <OfficesHeading headingLevel={4}>Onze vestigingen</OfficesHeading>
            <OfficesWrapper>
                {offices.map(office => (
                    <OfficeItem key={office.name}>
                        <ImageWrapper>
                            {office.image?.filename && (
                                <Image
                                    src={office.image?.filename}
                                    quality={100}
                                    unoptimized
                                    layout="fill"
                                    objectFit="cover"
                                    alt={office.image.alt}
                                />
                            )}
                        </ImageWrapper>
                        <InfoWrapper>
                            <Heading headingLevel={3}>{office.name}</Heading>
                            <Address>{office.address}</Address>
                            {office.phoneNumber && (
                                <ContactItem href={`tel:${office.phoneNumber}`}>
                                    <Icon
                                        icon={PHONE}
                                        size={24}
                                        color="var(--color-primary)"
                                    />
                                    {office.phoneNumber}
                                </ContactItem>
                            )}
                            {office.email && (
                                <ContactItem href={`mailto:${office.email}`}>
                                    <Icon
                                        icon={MAIL}
                                        size={24}
                                        color="var(--color-primary)"
                                    />
                                    {office.email}
                                </ContactItem>
                            )}
                        </InfoWrapper>
                    </OfficeItem>
                ))}
            </OfficesWrapper>
        </StyledOffices>
    );
}
