import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Background = styled.div`
    background: var(--color-purple-50);
`;

export const StyledContainer = styled(Container)`
    color: var(--color-white);
    flex-direction: column;
    font-family: var(--font-secondary);
    margin-top: 64px;
    padding: 24px;

    ${media.lgUp} {
        flex-direction: row;
        margin-top: 124px;
        padding: 104px 0;
    }
`;

export const ImageWrapper = styled.div`
    height: 330px;
    margin: -88px 0 0 -168px;
    overflow: visible;
    position: relative;
    text-align: center;

    ${media.lgUp} {
        margin: -188px 0 0 -10%;
        width: 50%;
    }
`;

export const Content = styled.div`
    max-width: 472px;

    ${media.lgUp} {
        margin-left: -15%;
        width: 100%;
    }
`;

export const ButtonWrapper = styled.ul`
    list-style: none;
    margin-top: 16px;

    li:first-of-type {
        margin-bottom: 16px;
    }

    ${media.lgUp} {
        align-items: center;
        display: flex;
        gap: 24px;
    }
`;
