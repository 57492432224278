import { OrganisationsHeroProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import { useRouter } from "next/router";

import { HeroPillImage } from "components/shared/storyblok/HeroPillImage";
import { Icons } from "components/ui";

import {
    Background,
    Container,
    Content,
    Links,
    MoreInfoContainer,
    StyledButton,
    Subtitle,
    Title
} from "./ZzpHero.styled";

import { useMediaQueryContext } from "helpers/hooks";

type MyObject = {
    [key: string]: any;
};

function excludeSlugObject<T extends MyObject>(originalObject: T): Partial<T> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { slug, ...rest } = originalObject;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return rest;
}

export function ZzpHero({ blok }: OrganisationsHeroProps): JSX.Element {
    const router = useRouter();
    const { isMobileView } = useMediaQueryContext();
    const { title, subtitle, description, image, linksBlok } = blok;
    const imageBlok = image[0];
    const queryParams = excludeSlugObject(router.query);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const searchParams = new URLSearchParams(queryParams).toString();
    const urlParams = searchParams?.length > 0 ? `?${searchParams}` : "";

    return (
        <Background>
            <Container {...storyblokEditable(blok)}>
                {isMobileView && imageBlok && (
                    <HeroPillImage
                        image={imageBlok?.image}
                        size={imageBlok?.size}
                    />
                )}
                <Content>
                    {title && <Title>{title}</Title>}
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    {description && <p>{description}</p>}
                    {linksBlok && linksBlok?.[0] && (
                        <MoreInfoContainer>
                            <Links>
                                {linksBlok?.[0].links.map(linkItem => (
                                    <StyledButton
                                        key={linkItem?.title}
                                        href={`${linkItem?.href}${urlParams}`}
                                        buttonType={linkItem?.buttonType}
                                        icon={Icons?.[linkItem?.icon]}
                                        reversedOrder={linkItem?.reversedOrder}
                                        size={linkItem?.size}
                                    >
                                        {linkItem?.title}
                                    </StyledButton>
                                ))}
                            </Links>
                        </MoreInfoContainer>
                    )}
                </Content>
                {!isMobileView && imageBlok && (
                    <HeroPillImage
                        image={imageBlok?.image}
                        size={imageBlok?.size}
                    />
                )}
            </Container>
        </Background>
    );
}

export default ZzpHero;
