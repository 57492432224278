import { motion } from "framer-motion";
import styled from "styled-components";

import { media } from "constants/media";

export const VideoWrapper = styled(motion.div)`
    aspect-ratio: 16 / 9;
    background-color: var(--color-grey-55);
    margin: 0 0 8px;
    position: relative;
    transform-origin: top center;
    width: 100vw;
    will-change: width, height, transform;
    z-index: var(--z-index-component-foreground);

    ${media.mdUp} {
        aspect-ratio: 15 / 8;
        border-radius: 100em;
        margin: 0 -25vw 32px;
        overflow: hidden;
    }
`;
