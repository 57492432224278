import { Button, ContactBannerProps } from "storyblok/components";
import { StoryblokBlokData, storyblokEditable } from "@storyblok/react";
import { StoryblokRichtextContent } from "storyblok-rich-text-react-renderer";

import { HEART_FILLED, Icon } from "components";
import { RichTextRender } from "components/shared/richTextRender";

import {
    ContactInfoTextContainer,
    Container,
    StyledButton
} from "./ContactBanner.styled";

export function ContactBanner({
    blok
}: {
    blok: ContactBannerProps;
}): JSX.Element {
    if (!blok) {
        return <></>;
    }

    const { description, isFullWidthBanner, link, title } = blok;
    const withIcon = true;

    return (
        <Container
            isFullWidthBanner={isFullWidthBanner}
            {...storyblokEditable(blok as StoryblokBlokData)}
        >
            {isFullWidthBanner && (
                <>
                    {withIcon && <Icon icon={HEART_FILLED} size={56} />}
                    <ContactInfoTextContainer>
                        {title && <h3>{title}</h3>}
                        {description && (
                            <p>
                                <RichTextRender
                                    type="doc"
                                    content={
                                        description as Array<StoryblokRichtextContent>
                                    }
                                />
                            </p>
                        )}
                    </ContactInfoTextContainer>
                </>
            )}
            {!isFullWidthBanner && (
                <>
                    {title && <h6>{title}</h6>}
                    {description && (
                        <p>
                            <RichTextRender
                                type="doc"
                                content={
                                    description as Array<StoryblokRichtextContent>
                                }
                            />
                        </p>
                    )}
                </>
            )}
            {link &&
                link.map((entry: Button) => {
                    return (
                        entry.component === "Button" && (
                            <StyledButton key={entry._uid} {...entry} />
                        )
                    );
                })}
        </Container>
    );
}

export default ContactBanner;
