import styled, { css, keyframes } from "styled-components";

const gradient = keyframes`
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: 0% 0;
    }
`;

const gradientStyling = css`
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${gradient};
    animation-timing-function: linear;
    background: linear-gradient(to right, #eee 0%, #ddd 25%, #eee 50%);
    background-size: 200% 200%;
`;

export const TextRow = styled.div`
    border-radius: 5px;
    height: 1em;
    margin-bottom: 4px;
    max-width: 100%;
    width: 100%;

    /* stylelint-disable order/properties-alphabetical-order */
    ${gradientStyling}/* stylelint-enable order/properties-alphabetical-order */
`;

export const CircleElement = styled.div<{ size: string }>`
    border-radius: 50%;

    /* stylelint-disable order/properties-alphabetical-order */
    ${gradientStyling} /* stylelint-enable order/properties-alphabetical-order */

    ${({ size }) => `
        height: ${size};
        width: ${size};
    `};
`;

export const RectangleElement = styled.div<{ width: string; height: string }>`
    border-radius: 5px;
    max-width: 100%;

    /* stylelint-disable order/properties-alphabetical-order */
    ${gradientStyling}; /* stylelint-enable order/properties-alphabetical-order */

    ${({ height, width }) => `
        height: ${height};
        width: ${width};
    `};
`;
