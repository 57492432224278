import { ChangeEvent, ForwardedRef, forwardRef } from "react";

import { Fill, Label, RadioInput, StyledRadio, Text } from "./Radio.styled";

export interface RadioProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Setting this to true will disable the input, blocking any further interactions */
    disabled?: boolean;
    /** The radio button is always rendered with a label, here you can set the text for the label */
    label?: string;
    /** Use this property to group multiple RadioButton components together. All RadioButtons that share the same name belong to a radio button group, with a single value */
    name: string;
    /** When this RadioButton is selected, the value passed through this key will be used when grabbing all the data from the form */
    value: string;
    id?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
}

export const Radio = forwardRef(function RadioButton(
    {
        className,
        disabled = false,
        label,
        name,
        value,
        checked,
        ...otherProps
    }: RadioProps,
    ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
    const id = `r-${name}-` + value;

    return (
        <Label disabled={disabled} className={className} htmlFor={id}>
            <RadioInput
                data-testid={`${name}:` + value}
                disabled={disabled}
                ref={ref}
                name={name}
                type="radio"
                value={value}
                checked={checked}
                {...otherProps}
                id={id}
                style={{ background: "transparent" }}
            />
            <StyledRadio hasLabel={!!label}>
                <Fill className={checked ? "animate-in" : "animate-out"} />
            </StyledRadio>
            <Text>{label}</Text>
        </Label>
    );
});
