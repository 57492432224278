import { motion } from "framer-motion";
import styled from "styled-components";

import { media } from "constants/media";

export const Wrapper = styled.section<{ scrollHeight: number }>`
    background: var(--color-orange-50);
    margin-top: 64px;
    position: relative;
    z-index: var(--z-index-homepage-section);

    ${media.mdUp} {
        background: transparent;
        margin: 0;
        min-height: ${({ scrollHeight }) => scrollHeight}px;
        padding: 0 0 335px;
    }
`;

export const StickyContainer = styled(motion.div)`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    position: relative;
    width: 100%;
    will-change: transform;
`;
