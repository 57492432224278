import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.div`
    background: var(--color-orange-80);
    color: var(--color-white);
    display: flex;
    justify-content: center;

    ${media.mdUp} {
        position: relative;
        z-index: var(--z-index-header);
    }
`;

export const StyledContainer = styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;

    ${media.mdUp} {
        justify-content: space-between;
        padding: 8px 24px;
    }
`;
