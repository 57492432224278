import { StoryblokBlokData } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react";

import { StyledAboutUsPage } from "./AboutUsPage.styled";

type Props = {
    blok: {
        body: Array<StoryblokBlokData>;
    };
};

function AboutUsPage({ blok }: Props): JSX.Element {
    return (
        <StyledAboutUsPage>
            {blok?.body?.map(nestedBlok => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </StyledAboutUsPage>
    );
}

export default AboutUsPage;
