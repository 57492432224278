import { Button, VacancyPillBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";
import { StoryblokButton } from "components/shared/storyblok";

import {
    ButtonWrapper,
    Content,
    ImageWrapper,
    Info,
    StyledContainer,
    StyledFramerMotionWrapper,
    Text,
    Wrapper
} from "./VacancyPill.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function VacancyPill({ blok }: VacancyPillBlok): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();

    const { title, text, buttons, image } = blok;

    return (
        <Wrapper {...storyblokEditable(blok)}>
            <StyledContainer>
                <StyledFramerMotionWrapper
                    playWhenInView
                    animationType={AnimationStyle.FadeInFromBottom}
                    duration={0.5}
                >
                    <Content>
                        {image.filename && (
                            <FramerMotionWrapper
                                playWhenInView
                                animationType={AnimationStyle.FadeIn}
                                duration={0.5}
                                delay={0.25}
                            >
                                <ImageWrapper>
                                    <Image
                                        alt={image.alt}
                                        src={image.filename}
                                        width={isDesktopView ? 400 : 320}
                                        height={isDesktopView ? 400 : 320}
                                        objectFit="cover"
                                        layout="fixed"
                                    />
                                </ImageWrapper>
                            </FramerMotionWrapper>
                        )}
                        <Info>
                            <h3>{title}</h3>
                            <Text>{text}</Text>
                            {buttons && (
                                <ButtonWrapper>
                                    {buttons.map((button: Button) => {
                                        return (
                                            button.component === "Button" && (
                                                <div key={button._uid}>
                                                    <StoryblokButton
                                                        {...button}
                                                    />
                                                </div>
                                            )
                                        );
                                    })}
                                </ButtonWrapper>
                            )}
                        </Info>
                    </Content>
                </StyledFramerMotionWrapper>
            </StyledContainer>
        </Wrapper>
    );
}
