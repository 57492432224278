import styled from "styled-components";

import { media } from "constants/media";

export const StyledNav = styled.nav`
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
`;

export const NavList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;

    ${media.lgUp} {
        flex-direction: row;
        gap: 5px;
        padding-bottom: 0;
        width: auto;
    }

    ${media.xlUp} {
        gap: 8px;
    }
`;

export const ListItem = styled.li`
    position: relative;

    & + & {
        border-top: 1px solid var(--color-orange-50);
    }

    ${media.mdUp} {
        :first-of-type a::after {
            display: none;
        }

        & + & {
            border-top: 0;
        }
    }

    ${media.lgUp} {
        align-items: center;
        display: inline-flex;
        position: initial;
    }
`;
