import styled, { css } from "styled-components";

import { media } from "constants/media";

function determineSpacerSize(size: string) {
    switch (size) {
        case "xxs":
            return css`
                height: 4px;
            `;
        case "xs":
            return css`
                height: 8px;
            `;
        case "s":
            return css`
                height: 16px;
            `;
        case "ms":
            return css`
                height: 16px;

                ${media.mdUp} {
                    height: 24px;
                }
            `;
        case "m":
            return css`
                height: 24px;

                ${media.mdUp} {
                    height: 32px;
                }
            `;
        case "l":
            return css`
                height: 40px;

                ${media.mdUp} {
                    height: 48px;
                }
            `;
        case "xl":
            return css`
                height: 48px;

                ${media.mdUp} {
                    height: 56px;
                }

                ${media.lgUp} {
                    height: 64px;
                }
            `;
        case "xxl":
            return css`
                height: 64px;

                ${media.mdUp} {
                    height: 88px;
                }
            `;
        case "xxxl":
            return css`
                height: 88px;

                ${media.mdUp} {
                    height: 96px;
                }

                ${media.lgUp} {
                    height: 112px;
                }
            `;
        default:
            return css`
                height: 0;
            `;
    }
}

export const Spacer = styled.div<{ size: string }>`
    ${({ size }) => determineSpacerSize(size)};
`;
