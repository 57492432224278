import styled from "styled-components";

import { Heading } from "components/ui";

import { media } from "constants/media";

export const ArticleWrapper = styled.div`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin-bottom: 64px;
    }
`;

export const TermsPageWrapper = styled.div`
    margin: 0 24px 8px;

    ${media.mdUp} {
        margin: 0 auto 32px;
        max-width: 1200px;
        padding: 0 64px;
        width: 100%;
    }

    ${media.xlUp} {
        padding: 0;
    }
`;

export const TermsPageHeading = styled(Heading)`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin-bottom: 48px;
    }
`;

export const TermsContentWrapper = styled.div`
    display: flex;
`;

export const TermsMenu = styled.ul`
    display: none;

    ${media.mdUp} {
        display: block;
        list-style: none;
        max-width: 264px;
        min-width: 264px;
    }
`;

export const TermsMenuItem = styled.a`
    display: none;

    ${media.mdUp} {
        color: var(--color-black);
        cursor: pointer;
        display: block;
        font-size: 15px;
        font-weight: 800;
        line-height: 24px;
        margin-bottom: 8px;
        min-width: 264px;
        text-decoration: none;
        transition-duration: var(--default-transition-duration);
        transition-property: color;
        transition-timing-function: ease;

        &:hover {
            color: var(--color-primary);
        }
    }
`;
