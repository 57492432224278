import Link from "next/link";

import { Anchor, Chevron, HitBox } from "./NavItem.styled";

import { useMediaQueryContext } from "helpers/hooks";
import { useMenuContext } from "helpers/hooks/useMenuContext";

export type MenuType = "primary" | "secondary";

type NavItemProps = {
    id: string;
    href: string;
    title: string;
    children?: React.ReactNode | null;
    type?: MenuType;
};

export function NavItem({
    title,
    id,
    href,
    children = null,
    type = "primary"
}: NavItemProps): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();
    const { activeItem, setIsSubMenuActive, setActiveItem } = useMenuContext();
    const isOpen: boolean = id === activeItem;

    function handleHover(): void {
        setActiveItem(id);

        if (type === "primary") {
            setIsSubMenuActive(true);
        }
    }

    function handleMouseLeave(): void {
        setIsSubMenuActive(false);
        setActiveItem(null);
    }

    function handleClick(event: React.MouseEvent): void {
        event.preventDefault();
        setActiveItem(id);
        setIsSubMenuActive(true);
    }

    return (
        <HitBox
            onMouseEnter={() => isDesktopView && handleHover()}
            onMouseLeave={() => isDesktopView && handleMouseLeave()}
        >
            <Link legacyBehavior href={href} passHref prefetch={false}>
                <Anchor
                    type={type}
                    isActive={isOpen}
                    onClick={event => !isDesktopView && handleClick(event)}
                    aria-expanded={isOpen}
                    aria-selected={isOpen}
                    aria-controls={`menu-${id}`}
                >
                    {title}
                    <Chevron
                        size={isDesktopView ? 16 : 24}
                        type={type}
                        aria-hidden="true"
                    />
                </Anchor>
            </Link>
            {children}
        </HitBox>
    );
}
