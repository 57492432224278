import { RectangleElement } from "components/ui/skeleton/Skeleton.styled";

interface RectangleProps {
    className?: string;
    width: string;
    height: string;
}

export function RectangleSkeleton({
    className,
    width,
    height
}: RectangleProps): JSX.Element {
    return (
        <RectangleElement className={className} width={width} height={height} />
    );
}
