import styled from "styled-components";

import { media } from "constants/media";

export const Wrapper = styled.div<{ overview?: boolean }>`
    background: var(--color-white);
    border-radius: 24px;
    box-shadow: 0 2px 4px var(--color-grey-50);
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-evenly;
    min-width: 272px;
    padding: 24px;
    position: relative;

    ${media.mdUp} {
        width: 300px;
    }

    ${media.lgUp} {
        width: 368px;
    }
`;

export const Heart = styled.div`
    border-radius: 45px;
    height: 12px;
    justify-content: end;

    position: absolute;
    right: 18px;
    top: 18px;
    width: 12px;

    svg {
        height: 200%;
        left: -6px;
        position: absolute;
        top: -4px;
        width: 200%;
    }

    /* have to reformat box shadow */
    &:hover {
        box-shadow: 0 0 20px var(--color-orange-50);
        cursor: pointer;
    }
`;

export const Title = styled.h6`
    color: var(--color-black);
    font-size: 16px;
    font-weight: 800;
    hyphens: auto;
    line-height: 24px;
    margin-bottom: 4px;
    text-transform: capitalize;
    width: 70%;

    /*stylelint-disable-next-line*/
    ${media.mdUp} {
        font-size: 20px;
        line-height: 32px;
    }
`;

export const Description = styled.div<{ small?: boolean }>`
    color: var(--color-grey-60);
    font-size: 15px;
    height: ${({ small }) => (small ? "48px" : "auto")};
    line-height: 24px;
    margin: 8px 0 16px;

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: var(--type-paragraph-font-size);
        font-weight: 400;
        line-height: var(--type-paragraph-line-height);
    }
`;

export const Info = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    ${media.mdUp} {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const Category = styled.div`
    background: var(--color-grey-50);
    border-radius: 16px;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    margin-right: 8px;
    padding: 4px 8px;
    text-transform: uppercase;
`;

export const Bottom = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    ${media.mdUp} {
        align-items: center;
    }
`;
export const Arrow = styled.div`
    max-width: 40px;
`;
export const InfoBlock = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    margin-right: 8px;
`;
export const InfoIcon = styled.div`
    margin-right: 8px;
`;

export const InfoContent = styled.div`
    color: var(--color-grey-60);
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
`;

export const StyledAnchor = styled.a`
    text-decoration: none;
`;
