import { createGlobalStyle } from "styled-components";

import { ModalBase } from "components/ui/modal/Modal.styled";

import { media } from "constants/media";

export const GlobalStyle = createGlobalStyle`
    ${ModalBase}

    html {

        /* ===== Theme colors ====== */
        --color-primaryLight: var(--color-orange-40);
        --color-primary: var(--color-orange-50);

        --color-secondary: var(--color-purple-50);
        --color-secondaryDark: var(--color-purple-60);

        --color-white: #fff;
        --color-black: #1d1f1d;

        /* Support colors */
        --color-red-light: #f5e4e4;
        --color-red-40: #e53e46;
        --color-red-45: #cb2930;
        --color-red-50: #b2181f;
        --color-red-55: #a11017;
        --color-red-60: #8e080e;
        --color-youtube-red: #ff0302;

        --color-green-40: #59e899;
        --color-green-45: #3ae487;
        --color-green-50: #2ce27e;
        --color-green-55: #1ed771;
        --color-green-60: #1ac165;
        --color-whatsapp-green: #25d366;

        --color-orange-35: #fbbc12;
        --color-orange-40: #ffb21e;
        --color-orange-45: #ff9d0a;
        --color-orange-50: #f39000;
        --color-orange-55: #ee8203;
        --color-orange-60: #e57300;
        --color-orange-65: #ee6333;
        --color-orange-80: #d8430a;

        --color-light-blue-10: rgba(0, 176, 191, 0.1);
        --color-light-blue-40: #adffff;
        --color-light-blue-45: #93f9f9;
        --color-light-blue-50: #80efef;
        --color-light-blue-55: #6be7e7;
        --color-light-blue-60: #4cd6d6;

        --color-twitter-blue: #1da1f2;
        --color-facebook-blue: #1877f2;
        --color-linkedin-blue: #0a66c2;

        --color-purple-40: #6179f6;
        --color-purple-45: #556ce8;
        --color-purple-50: #4e64d8;
        --color-purple-55: #4158cf;
        --color-purple-60: #2a42c3;

        --color-grey-45: #f8fafc;
        --color-grey-50: #e9eef2;
        --color-grey-55: #c2c4c9;
        --color-grey-60: #595959;
        --color-instagram-black: #000100;


        --color-grey: var(--color-grey-55);
        --color-light-grey: var(--color-grey-45);
        --color-border-grey: var(--color-grey-55);
        --color-article-border: rgba(29, 29, 29, 0.1);

        /* Button */
        --type-button-line-height: 24px;
        --type-button-font-size-small: 13px;
        --type-button-font-size-large: 15px;

        /* Dropdown */
        --dropdown-box-shadow: 0 76px 80px rgba(34, 12, 74, 0.07),
            0 27.7413px 29.2013px rgba(34, 12, 74, 0.05),
            0 13.4679px 14.1767px rgba(34, 12, 74, 0.04),
            0 6.6022px 6.94968px rgba(34, 12, 74, 0.03),
            0 2.61052px 2.74791px rgba(34, 12, 74, 0.02);

        /* Transition duration variable */
        --quick-transition-duration: 150ms;
        --default-transition-duration: 250ms;


        /* Typography */
        --font-primary: "Cabrito Sans", sans-serif;
        --font-secondary: "Cabrito Serif", serif;

        --type-heading-1-font-size: 40px;
        --type-heading-1-font-weight: 600;
        --type-heading-1-line-height: 56px;
        --type-heading-2-font-size: 32px;
        --type-heading-2-line-height: 48px;
        --type-heading-3-font-size: 28px;
        --type-heading-3-line-height: 40px;
        --type-heading-4-font-size: 24px;
        --type-heading-4-line-height: 32px;
        --type-heading-5-font-size: 20px;
        --type-heading-5-line-height: 32px;
        --type-heading-6-font-size: 16px;
        --type-heading-6-line-height: 24px;

        --type-paragraph-font-size: 14px;
        --type-paragraph-line-height: 24px;

        --type-paragraph-large-font-size: 16px;
        --type-paragraph-large-line-height: 24px;

        --type-label-font-size: 14px;
        --type-label-line-height: 24px;

        --type-sub-text-font-size-sub-text: 12px;
        --type-sub-text-line-height: 24px;

        --type-subtitle-font-size: 13px;
        --type-subtitle-line-height: 16px;

        /* Modal Overlay */
        --overlay-color: #b2b2bf;

        /* Z-index stacking */
        --z-index-modal-close-button: 200;
        --z-index-modal: 101;
        --z-index-submenu: 100;
        --z-index-hamburger: 99;
        --z-index-header: 98;
        --z-index-menu-foreground: 97;
        --z-index-menu-background: 96;
        --z-index-footer: 95;
        --z-index-component-foreground: 3;

        --z-index-homepage-section: 2;
        --z-index-homepage-bg: 1;

        /* font-weights */
        --font-weight-normal: 400;
        --font-weight-medium: 500;
        --font-weight-semibold: 600;
        --font-weight-bold: 700;
        --font-weight-extra-bold: 800;


        /* Smooth scroll behavior for scrolling to ID'ed elements on static pages */
        scroll-behavior: smooth;


        ${media.mdUp} {
            --type-heading-1-font-size: 40px;
            --type-heading-1-line-height: 56px;
            --type-heading-2-font-size: 34px;
            --type-heading-2-line-height: 64px;
            --type-heading-3-font-size: 28px;
            --type-heading-3-line-height: 48px;
            --type-heading-4-font-size: 26px;
            --type-heading-4-line-height: 40px;
            --type-heading-5-font-size: 20px;
            --type-heading-5-line-height: 32px;
            --type-heading-6-font-size: 18px;
            --type-heading-6-line-height: 32px;

            --type-paragraph-font-size: 15px;
            --type-paragraph-line-height: 24px;

            --type-paragraph-large-font-size: 18px;
            --type-paragraph-large-line-height: 32px;

            --type-sub-text-font-size-sub-text: 14px;

            --type-subtitle-font-size: 14px;
        }

        ${media.lgUp} {
            --type-heading-1-font-size: 58px;
            --type-heading-1-line-height: 80px;
        }


    }

    * {
        box-sizing: border-box;
    }

    /* Headers */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--font-secondary);
    }

    h1 {
        font-size: var(--type-heading-1-font-size);
        font-weight: var(--type-heading-1-font-weight);
        line-height: var(--type-heading-1-line-height);
    }

    h2 {
        font-size: var(--type-heading-2-font-size);
        line-height: var(--type-heading-2-line-height);
    }

    h3 {
        font-size: var(--type-heading-3-font-size);
        line-height: var(--type-heading-3-line-height);
    }

    h4 {
        font-size: var(--type-heading-4-font-size);
        line-height: var(--type-heading-4-line-height);
    }

    h5 {
        font-size: var(--type-heading-5-font-size);
        line-height: var(--type-heading-5-line-height);
    }

    h6 {
        font-size: var(--type-heading-6-font-size);
        line-height: var(--type-heading-6-line-height);
        text-transform: uppercase;
    }

    p {
        font-size: var(--type-paragraph-font-size);
        line-height: var(--type-paragraph-line-height);
    }


    /* General components */
    a {
        color: var(--color-violet-50);
        font-size: var(--type-anchor-font-size);
        line-height: var(--type-anchor-line-height);
    }

    button {
        font-size: var(--type-button-font-size-small);
        line-height: var(--type-button-line-height);
    }

    label {
        font-size: var(--type-label-font-size);
        line-height: var(--type-label-line-height);
    }

    /*  General styling */
    html,
    body,
    button,
    input,
    select,
    textarea {
        font-family: var(--font-primary);
    }

    input,
    textarea {
        font-size: var(--font-size---type-paragraph-font-size);
    }

    html,
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        color: var(--color-black);
        margin: 0;
        padding: 0;
    }

    /* stylelint-disable selector-max-id */
    #__next {
        min-height: 100vh;
        overflow-x: hidden;
    }
    /* stylelint-enable selector-max-id */
`;
