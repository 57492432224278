import { CircleElement } from "components/ui/skeleton/Skeleton.styled";

interface CircleProps {
    className?: string;
    size: string;
}

export function CircleSkeleton({ className, size }: CircleProps): JSX.Element {
    return <CircleElement className={className} size={size} />;
}
