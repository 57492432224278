import {
    ArrowsWrapper,
    PreviousIcon,
    SlideIndicator,
    SlideIndicatorWrapper,
    StyledSliderControls
} from "components/shared/sliderControls/SliderControls.styled";
import { Button } from "components/ui/button";
import { CHEVRON_LEFT, CHEVRON_RIGHT, Icon } from "components/ui/icon";

export enum SliderControlTheme {
    PRIMARY = "primary",
    BLACK_AND_WHITE = "black-and-white",
    WHITE_BUTTONS = "white-buttons"
}

export interface SliderControlsProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Current index for slider component */
    currentSlide: number;
    /** Total number of slides in the carousel */
    totalSlides: number;
    /** onChange callback function for when one of the controls gets pressed */
    onChange: (newIndex: number) => void;
    /** Allowing the user to click next and previous when at the start or end of the slider */
    isAllowedToLoop?: boolean;
    /** Number of slides to scroll on next / previous */
    slidesToScroll?: number;
    /** Theme for the colors of the controls */
    theme?: SliderControlTheme;
}

export function SliderControls({
    className,
    currentSlide,
    totalSlides,
    onChange,
    isAllowedToLoop = false,
    slidesToScroll = 1
}: SliderControlsProps): JSX.Element {
    const onPreviousClick = () => {
        if (currentSlide - slidesToScroll < 0) {
            onChange(totalSlides - slidesToScroll);
        } else {
            onChange(currentSlide - slidesToScroll);
        }
    };

    const onNextClick = () => {
        if (currentSlide + slidesToScroll > totalSlides - slidesToScroll) {
            onChange(0);
        } else {
            onChange(currentSlide + slidesToScroll);
        }
    };

    const currentIndicatorWidthPercentage: number =
        (currentSlide + 1 * slidesToScroll) / (totalSlides / 100);

    return (
        <StyledSliderControls className={className}>
            <SlideIndicatorWrapper>
                <SlideIndicator
                    color="var(--color-black)"
                    widthPercentage={currentIndicatorWidthPercentage}
                />
            </SlideIndicatorWrapper>
            <ArrowsWrapper>
                <Button
                    size="round"
                    buttonType="ghost-dark"
                    onClick={() => onPreviousClick()}
                    disabled={!isAllowedToLoop && currentSlide === 0}
                >
                    <PreviousIcon icon={CHEVRON_LEFT} size={22} />
                </Button>
                <Button
                    size="round"
                    buttonType="ghost-dark"
                    onClick={() => onNextClick()}
                    disabled={
                        !isAllowedToLoop &&
                        currentSlide + slidesToScroll === totalSlides
                    }
                >
                    <Icon icon={CHEVRON_RIGHT} size={22} />
                </Button>
            </ArrowsWrapper>
        </StyledSliderControls>
    );
}

export default SliderControls;
