import styled, { css } from "styled-components";

import { media } from "constants/media";

export const Title = styled.p`
    color: var(--color-primary);
    font-size: var(--type-heading-3-font-size);
    font-weight: 700;
    line-height: var(--type-heading-3-line-height);
    margin: 8px 0 16px;
    width: 95%;

    ${media.lgUp} {
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    padding: 8px 0;
`;

export const MoreInformation = styled.div<{ isOpen: boolean }>`
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--default-transition-duration) ease-in-out;

    p {
        margin: 16px 0;

        ${media.lgUp} {
            margin: 0 0 32px;
        }
    }

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isOpen }) =>
        isOpen &&
        css`
            max-height: 5000px;
            transition: max-height var(--default-transition-duration)
                ease-in-out;
        `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;

export const TitleWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
`;

export const StyledArrow = styled.div<{ isOpen: boolean }>`
    margin: 0;
    min-height: 11px;
    min-width: 8px;
    transform: rotate(-180deg);
    transition: transform var(--quick-transition-duration) linear;

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isOpen }) =>
        isOpen &&
        css`
            margin-left: -8px;
            margin-top: 11px;
            transform: rotate(0deg);
            transition: transform 0.15s linear;
        `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;
