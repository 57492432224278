import styled from "styled-components";

import { Button } from "components/ui";

import { media } from "constants/media";

export const ImageWrapper = styled.div`
    border-radius: 24px;
    height: auto;
    height: 128px;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        overflow: hidden;
    }

    ${media.mdUp} {
        border-radius: 0;
        flex: 1;
        height: auto;
        margin-bottom: 0;
        max-width: 264px;
    }
`;

export const Category = styled.div`
    background: var(--color-grey-50);
    border-radius: 16px;
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 20px;
    padding: 4px 8px;
    text-align: center;
    text-transform: uppercase;
`;

export const Item = styled.div`
    background-color: var(--color-white);
    border-radius: 24px;
    box-shadow: 0 2px 4px var(--color-grey-50);
    margin-bottom: 16px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        border-radius: 16px;
        display: flex;
        margin-bottom: 24px;
        padding: 0;
    }
`;

export const Content = styled.div<{ isDouble: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    h5 {
        font-weight: 600;
    }

    ${media.mdUp} {
        flex: 1;
        flex-direction: ${({ isDouble }) => (isDouble ? "row" : "column")};
        height: 100%;
        padding: 32px;
    }
`;

export const Container = styled.div`
    max-height: 200px;
    padding: 16px 0;
    width: 100%;

    ${media.lgUp} {
        grid-column: span 9;
    }
`;

export const Footer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;

    ${media.mdUp} {
        margin: 0;
    }
`;

export const Date = styled.span`
    color: var(--color-grey-55);
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
`;

export const StyledButton = styled(Button)`
    ${media.mdUp} {
        align-self: flex-end;
    }
`;
