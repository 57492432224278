import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Script from "next/script";

import { pageview } from "lib/gtag";

const handleRouteChange = (url: string) => {
    pageview(url);
};
interface GoogleTagManagerProps {
    children: React.ReactNode;
}

export const GoogleTagManager = ({
    children
}: GoogleTagManagerProps): JSX.Element => {
    const router = useRouter();
    const [datalayerSet, setDatalayerSet] = useState(false);

    useEffect(() => {
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        window.dataLayer = window?.dataLayer || [];
        window?.dataLayer?.push({
            event: "gtm.js",
            "gtm.start": new Date().getTime()
        });
        setDatalayerSet(true);

        // Convert Experiments installed according to these articles:
        // https://support.convert.com/hc/en-us/articles/205151015
        // https://support.convert.com/hc/en-us/articles/360020671451-React-Redux-and-Convert-Experiences

        // This code will poll for the convert.experiments_evaluated flag every 50ms
        // for two minutes after the useEffect React Event
        const TIMEOUT_DURATION = 2 * 60 * 1000; // 2 minutes in milliseconds
        const POLLING_INTERVAL = 50; // 50ms
        const startTime = Date.now();

        const pollingFunction = setInterval(() => {
            const elapsedTime = Date.now() - startTime;

            if (window.convert && window.convert.experiments_evaluated) {
                window._conv_q = window._conv_q || [];
                window._conv_q.push(["run", "true"]);
                // Insert any code you want to execute anything on your own after the experiments have been evaluated
                // if the code changes are not included in the experiment code editors.
                clearInterval(pollingFunction); // Stop polling
            } else if (elapsedTime >= TIMEOUT_DURATION) {
                clearInterval(pollingFunction); // Stop polling
            }
        }, POLLING_INTERVAL);

        // Cleanup function to clear the interval if the component unmounts
        return () => clearInterval(pollingFunction);
    }, []);

    const gtmId = process.env.NEXT_PUBLIC_GTM_ID;

    return (
        <>
            {datalayerSet && gtmId && (
                <Head>
                    <script
                        src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}`}
                        async
                    />
                </Head>
            )}

            <Script src="//cdn-4.convertexperiments.com/js/10043209-10045180.js" />

            {children}
        </>
    );
};
