import Link from "next/link";

import { Anchor } from "./SubmenuItem.styled";
import { Card } from "./card";
import { Story } from "./story";

export type SubMenuLinkType =
    | "heading"
    | "link"
    | "card"
    | "inverted-card"
    | "story";

export type SubMenuItemType = {
    href: string;
    title: string;
    description?: string;
    icon?: JSX.Element | string;
    type: SubMenuLinkType;
};

export function SubmenuItem({
    href,
    title,
    description,
    icon,
    type = "link"
}: SubMenuItemType): JSX.Element {
    const shouldRenderLink: boolean = type === "link";
    const shouldRenderCard: boolean = ["card", "inverted-card"].includes(type);
    const storyImage: string = typeof icon === "string" ? icon : "";
    const iconImage: JSX.Element | undefined =
        typeof icon !== "string" ? icon : undefined;

    return (
        <>
            {shouldRenderLink && (
                <Link legacyBehavior href={href} passHref prefetch={false}>
                    <Anchor type={type}>{title}</Anchor>
                </Link>
            )}
            {type === "story" && (
                <Story
                    href={href}
                    title={title}
                    description={description || ""}
                    image={storyImage}
                />
            )}
            {shouldRenderCard && (
                <Card
                    href={href}
                    icon={iconImage}
                    title={title}
                    description={description}
                    invert={type === "inverted-card"}
                />
            )}
        </>
    );
}
