import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { Icon, PHONE } from "components/ui";
import { NavToggle } from "components/shared/header/navigation/mobile";

import {
    Anchor,
    ContactAndNavToggle,
    ContactButton,
    MeetButton,
    NavList,
    NewsButton,
    PhoneIcon
} from "./PreheaderLinks.styled";

import { useMediaQueryContext } from "helpers/hooks";

type Props = {
    showNewsButton?: boolean;
    showMeetButton?: boolean;
    className?: string;
};

export function PreheaderLinks({
    showNewsButton = true,
    showMeetButton = false,
    className
}: Props): JSX.Element {
    const { t } = useTranslation();
    const { isMobileView } = useMediaQueryContext();

    return (
        <NavList className={className ?? ""}>
            {showMeetButton && (
                <MeetButton>
                    <Link
                        legacyBehavior
                        href="/vacatures"
                        passHref
                        prefetch={false}
                    >
                        <Anchor>{t("common:meet")}</Anchor>
                    </Link>
                </MeetButton>
            )}
            {showNewsButton && (
                <NewsButton>
                    <Link
                        legacyBehavior
                        href="/nieuws"
                        passHref
                        prefetch={false}
                    >
                        <Anchor>{t("common:news")}</Anchor>
                    </Link>
                </NewsButton>
            )}
            <li>
                <Anchor href="tel:0355430234">
                    <PhoneIcon>
                        <Icon icon={PHONE} size={14} />
                    </PhoneIcon>
                    <span>035 - 5430234</span>
                </Anchor>
            </li>

            <ContactAndNavToggle>
                <ContactButton>
                    <Link
                        legacyBehavior
                        href="/contact"
                        passHref
                        prefetch={false}
                    >
                        <Anchor>{t("common:contact")}</Anchor>
                    </Link>
                </ContactButton>

                {isMobileView && <NavToggle stickyHeader />}
            </ContactAndNavToggle>
        </NavList>
    );
}
