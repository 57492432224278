import { useEffect, useState } from "react";

export const SCROLL_UP = "up";
export const SCROLL_DOWN = "down";
export const SCROLL_DEFAULT = "default";

interface ScrollDirectionOptions {
    /** Add additional logic for when any handlers are allowed to fire. */
    fireScrollHandler?: boolean;
    /** The initial direction the scroll direction in the state is set to. */
    initialDirection?: "up" | "down" | "default";
    /** Specify a threshold so no handlers are fired until this point is reached. */
    thresholdPixels?: number;
    /** Add additional logic when the default state has to be returned after a certain amount of pixels.  */
    defaultOnScrollUpPixels?: number;
}

export function useScrollDirection({
    initialDirection = SCROLL_DEFAULT,
    thresholdPixels = 64,
    defaultOnScrollUpPixels = 0,
    fireScrollHandler = true
}: ScrollDirectionOptions): "up" | "down" | "default" {
    const [scrollDir, setScrollDir] = useState(initialDirection);

    useEffect(() => {
        const threshold = thresholdPixels || 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;
            const thresholdScroll = Math.abs(scrollY - lastScrollY);
            if (
                thresholdScroll < threshold &&
                scrollY >= defaultOnScrollUpPixels
            ) {
                // We haven't exceeded the threshold
                ticking = false;
                return;
            }

            if (!fireScrollHandler) {
                return setScrollDir(SCROLL_DEFAULT);
            }

            if (scrollY <= threshold) {
                setScrollDir(SCROLL_DEFAULT);
            } else {
                if (scrollY > lastScrollY) {
                    if (scrollY >= thresholdPixels) {
                        setScrollDir(SCROLL_DOWN);
                    }
                } else {
                    if (scrollY <= defaultOnScrollUpPixels) {
                        setScrollDir(SCROLL_UP);
                    }
                    setScrollDir(SCROLL_UP);
                }
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);
        window.addEventListener("touchend", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("touchend", onScroll);
        };
    }, [
        initialDirection,
        thresholdPixels,
        scrollDir,
        defaultOnScrollUpPixels,
        fireScrollHandler
    ]);

    return scrollDir;
}
