import { VacancyProps, VacancyWishListProps } from "storyblok/components";
import { useState } from "react";
import Link from "next/link";

import { ARROW_RIGHT, Container, Icon } from "components/ui";
import { WishListVacancyCard } from "components/vacancies/WishListVacancyCard/WishListVacancyCard";

import {
    Background,
    SavedVacancyWrapper,
    StyledLink,
    Title,
    VacancyCounter,
    Wrapper
} from "./VacancyWishList.styled";

import { useWishlistCounter } from "helpers/hooks/useWishlistCounterContext";
import storageReader from "helpers/util/localStorage/storageReader";

function generateVacancyCard(v: VacancyProps, zzp: boolean) {
    if (!v) return <></>;
    const href = `zorgprofessionals/${zzp ? "opdrachten" : "vacatures"}/${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        zzp ? v?.id : v?.slug
    }`;
    const category = zzp ? v?.start_date : v?.department;
    const hours = zzp
        ? v?.uren_per_week
        : v?.min_hours && v?.max_hours
        ? `${v?.min_hours}-${v?.max_hours} uur`
        : null;
    const city = zzp ? v?.client?.town : v?.city;
    const education = zzp ? v?.niveau : v?.education_code;
    let description = v?.description || "";
    description?.length > 60
        ? (description = description?.substring(0, 70))
        : description;

    return (
        <WishListVacancyCard
            className="slider-card"
            key={JSON.stringify(v)}
            title={v.title}
            href={href}
            category={category}
            hours={hours}
            description={description}
            education={education}
            region={city ? `Regio ${city}` : ""}
            vacancy={v}
        />
    );
}

export function VacancyWishList({ blok }: VacancyWishListProps) {
    const [savedVacancies, setSavedVacancies] = useState(
        storageReader("savedVacancies")
    );

    const { wishlistCount } = useWishlistCounter();

    return (
        <Background>
            <Container>
                <Wrapper>
                    {/* Change blok.Title to blok.title in storyblok */}
                    <Title>{blok.title}</Title>

                    <VacancyCounter>
                        {wishlistCount ? wishlistCount : "0"} vacature(s)
                        opgeslagen
                    </VacancyCounter>

                    <SavedVacancyWrapper
                        onClick={() =>
                            setSavedVacancies(storageReader("savedVacancies"))
                        }
                    >
                        {savedVacancies && savedVacancies.length > 0 ? (
                            savedVacancies.map(vacancy => {
                                const currentIsZZP = Boolean(vacancy.client);
                                return generateVacancyCard(
                                    vacancy,
                                    currentIsZZP
                                );
                            })
                        ) : (
                            <Link
                                href="/zorgprofessionals/vacatures"
                                passHref={true}
                            >
                                <StyledLink href="#">
                                    <span>Bekijk alle vacatures</span>
                                    <Icon
                                        icon={ARROW_RIGHT}
                                        size={24}
                                        color="var(--color-black)"
                                    />
                                </StyledLink>
                            </Link>
                        )}
                    </SavedVacancyWrapper>
                </Wrapper>
            </Container>
        </Background>
    );
}
