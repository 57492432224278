import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    background-image: url("/images/professional-bg-mobile.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    ${media.xlUp} {
        background-image: url("/images/professional-bg-desktop.webp");
        background-position: 0 -150px;
    }
`;

export const StyledContainer = styled(Container)`
    flex-direction: column;
    margin-top: 16px;
    padding: 0 24px 48px;
    position: relative;

    ${media.mdUp} {
        padding: 32px;
    }

    ${media.xlUp} {
        min-height: 744px;
        padding: 48px;
    }
`;

export const ImageWrapper = styled.div`
    height: 168px;
    margin: -16px 0 24px;
    position: relative;
    width: 320px;

    ${media.mdUp} {
        height: 348px;
        margin: 0;
        position: absolute;
        right: -48px;
        width: 348px;
    }

    ${media.lgUp} {
        height: 464px;
        width: 464px;
    }

    ${media.xlUp} {
        height: 696px;
        width: 696px;
    }

    img {
        border-radius: 1000px 0 0 1000px;
    }
`;

export const Text = styled.p`
    font-family: var(--font-secondary);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
`;

export const LinkWrapper = styled.div`
    margin-top: 16px;
`;

export const InputWrapper = styled.div`
    max-width: 392px;

    svg {
        cursor: pointer;
    }
`;

export const Content = styled.div`
    max-width: 392px;

    ${media.xlUp} {
        margin: auto 0;
    }
`;
