import styled, { css } from "styled-components";

import { AvatarSizeType } from "./Avatar";

import { media } from "constants/media";

function determineSizeStyling(size: AvatarSizeType) {
    switch (size) {
        case "small":
            return css`
                border: 2px solid var(--color-white);
                height: 32px;
                width: 32px;
            `;

        case "large":
        default:
            return css`
                height: 48px;
                width: 48px;

                ${media.lgUp} {
                    border: 8px solid var(--color-grey-45);
                    height: 64px;
                    width: 64px;
                }
            `;
    }
}

export const ImageWrapper = styled.div<{ size: AvatarSizeType }>`
    aspect-ratio: 1/1;
    border-radius: 100%;
    display: inline-flex;
    overflow: hidden;
    transition: all var(--quick-transition-duration) ease;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ size }) => determineSizeStyling(size)}
`;
