import { UspsSectionBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

import { Text, UspsContainer, UspsSection } from "./Usps.styled";
import Usp from "./usp/Usp";

export function Usps({ blok }: UspsSectionBlok): JSX.Element {
    const { items, sectionTitle, text } = blok;

    return (
        <UspsSection {...storyblokEditable(blok)}>
            <Text>
                <h2>{sectionTitle}</h2>
                <p>{text}</p>
            </Text>
            <UspsContainer>
                {items?.map(item => (
                    <Usp
                        key={item?.text}
                        text={item?.text}
                        iconColor={item?.iconColor}
                    />
                ))}
            </UspsContainer>
        </UspsSection>
    );
}

export default Usps;
