import { StoryblokBlokData, StoryblokComponent } from "@storyblok/react";

import { StyledTermTable } from "components/termsAndConditions/termTable/TermTable.styled";

export type TermTableProps = {
    blok: StoryblokBlokData & {
        items?: Array<StoryblokBlokData> | null;
    };
};

function TermTable({ blok }: TermTableProps): JSX.Element {
    return (
        <StyledTermTable>
            {blok.items?.map(block => (
                <StoryblokComponent key={block._uid} blok={block} />
            ))}
        </StyledTermTable>
    );
}

export default TermTable;
