import styled from "styled-components";

import { Button, Icon, Input, Radio } from "components/ui";
import { LabelWrapper } from "components/ui/input/Input.styled";

import { media } from "constants/media";

export const StyledContactFormWrapper = styled.div`
    height: 100%;
    margin: 0 auto;
    max-width: 1400px;
    overflow-x: visible;
    overflow-y: hidden;
    position: relative;
    width: 100%;
`;

export const ContactPageBackground = styled.div`
    background: url("/images/contact-page-bg-mobile.png") no-repeat top center;
    background-size: cover;
    height: 225px;
    width: 100%;

    ${media.mdUp} {
        background: url("/images/contact-page-bg-desktop.svg") no-repeat top
            center;
        background-size: cover;
        height: 200%;
        left: 0;
        overflow: visible;
        position: absolute;
        top: -150px;
        width: 100%;
        z-index: -1;
    }
`;

/* Background image not an SVG because this was transparent for some reason */
export const DesktopImage = styled.div`
    background: url("/images/contact-page-image-desktop.png") no-repeat top
        right -5px;
    background-size: contain;
    height: 600px;
    overflow: visible;
    position: absolute;
    right: 0;
    top: 300px;
    width: 600px;
    z-index: -1;
`;

export const StyledContactForm = styled.div<{ leftMargin: boolean }>`
    margin: 32px 24px;

    ${media.mdUp} {
        margin: ${({ leftMargin }) =>
            leftMargin ? "24px 0 24px 120px" : "24px auto"};
        width: 576px;
    }
`;

export const Description = styled.p`
    font-family: var(--font-secondary);
    font-size: var(--type-heading-5-font-size);
    line-height: var(--type-heading-5-line-height);
`;

export const InputWrapper = styled.div<{ isFlex?: boolean }>`
    margin: 24px 0;

    ${media.mdUp} {
        display: ${props => (props.isFlex ? "flex" : "block")};
    }
`;

export const StyledRadio = styled(Radio)`
    margin-right: 10px;

    ${media.mdUp} {
        margin-right: 26px;
    }
`;

export const ToggleInputWrapper = styled.div`
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-50);
    border-radius: 24px;
    margin-bottom: 24px;
    padding: 16px;
    width: 100%;
`;

export const ToggleAndTimeWrapper = styled.div`
    ${media.mdUp} {
        display: flex;
    }
`;

export const TimeWrapper = styled.div`
    margin-top: 20px;

    ${media.mdUp} {
        margin: 0 0 0 40px;
    }
`;

export const PhoneNumberWrapper = styled.div`
    margin-top: 20px;
`;

export const InputHeading = styled.div`
    color: var(--color-black);
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
`;

export const StyledInput = styled(Input)<{ hasRightMargin?: boolean }>`
    margin-bottom: 24px;

    ${LabelWrapper} {
        background-color: var(--color-white);
        border-radius: 4px;
        padding: 0 8px;
        width: fit-content;
    }

    ${media.mdUp} {
        margin-bottom: 0;
        margin-right: ${props => (props.hasRightMargin ? "24px" : 0)};
        width: 50%;
    }
`;

export const SubmitButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        align-items: center;
        flex-direction: row-reverse;
    }
`;

export const FormDisclaimer = styled.div`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin: 0;
    }
`;

export const StyledButton = styled(Button)`
    justify-content: center;
    width: 100%;

    ${media.mdUp} {
        justify-content: normal;
        margin-right: 24px;
        width: fit-content;
    }
`;

export const StyledAnchor = styled.a`
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
    transition-duration: var(--default-transition-duration);
    transition-property: color;
    transition-timing-function: ease;

    &:hover {
        color: var(--color-orange-40);
    }
`;

export const FormErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormErrorButton = styled(Button)`
    margin-top: 24px;
    width: fit-content;
`;

export const SuccessBlock = styled.div`
    border: 2px solid var(--color-green-50);
    border-radius: 24px;
    margin-top: 24px;
    padding: 24px;
`;

export const SuccessTitle = styled.div`
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 16px;

    ${media.mdUp} {
        font-size: 18px;
    }
`;

export const SuccessIcon = styled(Icon)`
    font-size: 18px;
    font-weight: 800;
    line-height: 32px;
    margin-right: 8px;
`;

export const SelectWrapper = styled.div`
    position: relative;
    width: fit-content;

    &::after {
        --size: 4px;

        border-bottom: 2px solid var(--color-black);
        border-right: 2px solid var(--color-black);
        content: "";
        height: var(--size);
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-70%) rotate(45deg);
        width: var(--size);
    }
`;

export const StyledSelect = styled.select`
    appearance: none;
    background-color: var(--color-grey-45);
    border: 1px;
    border-radius: 28px;
    height: 54px;
    padding: 16px 24px 16px 16px;

    &:focus {
        background-color: var(--color-white);
        border: var(--color-white);
        box-shadow: 0 0 6px var(--color-primaryLight);
        outline: none;
    }
`;
