import { createContext, useCallback, useContext, useState } from "react";
import React from "react";

import storageReader from "helpers/util/localStorage/storageReader";

export interface WishlistCounterProps {
    children?: React.ReactNode;
}

interface WishlistCountContextModel {
    wishlistCount: number;
    subtractCount?: () => void;
    addCount?: () => void;
}

export const WishlistCountContext = createContext(
    {} as WishlistCountContextModel
);

export function useWishlistCounter(): WishlistCountContextModel {
    return useContext(WishlistCountContext);
}

export function WishlistCounterProvider({
    children
}: WishlistCounterProps): React.ReactElement {
    const [wishlistCount, setWishlistCount] = useState(
        storageReader("savedVacancies").length
    );

    const subtractCount = useCallback(() => {
        setWishlistCount(wishlistCount - 1);
    }, [wishlistCount]);

    const addCount = useCallback(() => {
        setWishlistCount(wishlistCount + 1);
    }, [wishlistCount]);

    return (
        <WishlistCountContext.Provider
            value={{
                addCount,
                subtractCount,
                wishlistCount
            }}
        >
            {children}
        </WishlistCountContext.Provider>
    );
}
