import Link from "next/link";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const IconBackground = styled.div`
    align-items: center;
    background: var(--color-white);
    border-radius: 50%;
    bottom: -50%;
    cursor: pointer;
    display: flex;
    height: 56px;
    justify-content: center;
    position: absolute;
    transition-duration: var(--default-transition-duration);
    transition-property: all;
    transition-timing-function: ease;
    width: 56px;
`;

export const StyledContainer = styled(Container)<{ isHomepage?: boolean }>`
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: ${({ isHomepage }) => (isHomepage ? "0" : "40px")};
    z-index: var(--z-index-homepage-section);

    ${media.mdUp} {
        margin: 0 auto;
        max-width: 1200px;
        padding-bottom: 120px;
        padding-top: ${({ isHomepage }) => (isHomepage ? "0" : "120px")};
        width: 100%;
    }
`;

export const Links = styled.ul`
    list-style: none;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const StyledHeading = styled.h3`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin-bottom: 40px;
        padding: 0 32px;
        text-align: center;
    }
`;

export const LinkWrapper = styled.li`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    justify-content: space-between;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;

    svg {
        min-height: 24px;
        min-width: 24px;
    }

    ${media.mdUp} {
        //stylelint-disable
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(29, 29, 29, 0.6) 100%
        );
        //stylelint-enable
        border-radius: 400px;
        flex-direction: column;
        height: 448px;
        margin: 0 8px;
        width: 304px;

        :hover {
            ${IconBackground} {
                bottom: 64px;
            }

            img {
                transform: scale(1.05);
                transition-duration: var(--default-transition-duration);
                transition-property: all;
                transition-timing-function: ease;
            }
        }
    }
`;

export const ImageWrapper = styled.div`
    height: 48px;
    margin-right: 12px;
    min-height: 48px;
    min-width: 48px;
    position: relative;
    width: 48px;

    //stylelint-disable-next-line
    img {
        border-radius: 100%;
    }

    ${media.mdUp} {
        height: 448px;
        margin: 0;
        min-width: 304px;
        mix-blend-mode: multiply;
        width: 304px;

        //stylelint-disable-next-line
        img {
            border-radius: 400px;
            transition-duration: var(--default-transition-duration);
            transition-property: all;
            transition-timing-function: ease;
        }
    }
`;

export const Title = styled.a`
    align-items: center;
    display: flex;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: 24px;
    text-decoration: none;
    width: 100%;

    ${media.mdUp} {
        bottom: 33%;
        color: var(--color-white);
        font-size: 26px;
        justify-content: center;
        line-height: 40px;
        margin: auto;
        max-width: 272px;
        position: absolute;
        text-align: center;
    }
`;

export const Wrapper = styled.div`
    ${media.mdUp} {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        width: 100%;
    }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledScrollContainer = styled(ScrollContainer)`
    cursor: grab;
    display: flex;
    height: 448px;
`;

export const BottomLinkAndIndicators = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    ${media.mdUp} {
        margin-top: 40px;
    }

    ${media.lgUp} {
        justify-content: center;
        position: relative;
    }
`;

export const StyledLink = styled(Link)`
    align-items: center;
    display: flex;
    font-weight: 800;
    gap: 4px;
    margin-left: auto;
    text-decoration: none;

    ${media.mdUp} {
        margin-left: 0;
    }

    ${media.lgUp} {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const Indicators = styled.div`
    display: none;

    ${media.mdUp} {
        display: flex;
        gap: 40px;
    }
`;

export const Indicator = styled.button`
    align-items: center;
    background-color: var(--color-white);
    border: 0;
    border-radius: 40px;
    display: flex;
    padding: 8px;
    transition: transform var(--default-transition-duration) ease;

    > svg > path {
        stroke-width: 1px;
    }

    &:active,
    &:hover {
        box-shadow: var(--dropdown-box-shadow);
        cursor: pointer;
    }

    &:active {
        transform: translateY(2px);
    }
`;
