import { Button as ButtonProps } from "storyblok/components";
import Link from "next/link";

import { Button, Icons } from "components/ui";

export function StoryblokButton({
    className,
    href,
    buttonType,
    reversedOrder,
    size,
    icon,
    title
}: ButtonProps): JSX.Element {
    return (
        <Link legacyBehavior href={href} passHref prefetch={false}>
            <Button
                href={href}
                buttonType={buttonType}
                reversedOrder={reversedOrder}
                size={size}
                icon={Icons[icon]}
                className={className}
            >
                {title}
            </Button>
        </Link>
    );
}
