import enUS from "date-fns/locale/en-US";
import format from "date-fns/format";
import nl from "date-fns/locale/nl";
import parseISO from "date-fns/parseISO";

export function formatDate(
    /** Date (E.G. 2021-03-19T15:22:27.552Z) */
    date: string,
    /** Date format (E.G. MM-dd-yyyy)*/
    dateFormat: string,
    /** Locale from router.locale */
    locale?: string
): string {
    let importedLocale: Locale;
    switch (locale) {
        case "en": {
            importedLocale = enUS;
            break;
        }
        case "nl":
        default: {
            importedLocale = nl;
        }
    }

    return format(parseISO(date), dateFormat, { locale: importedLocale });
}
