import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const Background = styled.div`
    background: var(--color-orange-60);
`;

export const StyledContainer = styled(Container)`
    color: var(--color-white);
    flex-direction: column;
    font-family: var(--font-secondary);
    padding: 24px;

    ${media.lgUp} {
        align-items: center;
        flex-direction: row;
        gap: 48px;
        justify-content: center;
        padding: 64px 0;

        p {
            font-size: 18px;
            line-height: 32px;
        }
    }
`;

export const ImageWrapper = styled.div`
    text-align: center;

    img {
        border-radius: 187px;
    }
`;

export const Content = styled.div`
    max-width: 576px;
`;

export const ButtonWrapper = styled.div`
    margin-top: 16px;
`;
