import { Button, OurClientsBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import Marquee from "react-fast-marquee";

import { StoryblokButton } from "components/shared/storyblok";

import { ClientsGrid, Container } from "./OurClients.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function OurClients({ blok }: OurClientsBlok): JSX.Element {
    const { title, description, link, clientsImages } = blok;
    const { isMobileView } = useMediaQueryContext();

    return (
        <Container {...storyblokEditable(blok)}>
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
            {link &&
                link.map((entry: Button) => {
                    return (
                        entry.component === "Button" && (
                            <StoryblokButton key={entry._uid} {...entry} />
                        )
                    );
                })}
            {clientsImages && (
                <Marquee
                    direction="left"
                    gradient={false}
                    speed={isMobileView ? 50 : 25}
                    style={{ width: "100%" }}
                >
                    {clientsImages.map(image => (
                        <ClientsGrid key={image?.filename}>
                            {image?.filename && (
                                <Image
                                    alt={image?.alt ?? ""}
                                    draggable={false}
                                    height={112}
                                    objectFit="contain"
                                    src={image?.filename}
                                    width={112}
                                />
                            )}
                        </ClientsGrid>
                    ))}
                </Marquee>
            )}
        </Container>
    );
}

export default OurClients;
