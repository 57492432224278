import { RoundImageTextBlockProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";

import { AnimationStyle, FramerMotionWrapper } from "components/ui";

import {
    ContentWrapper,
    ImageContainer,
    ImageWrapper,
    StyledRoundImagesTextBlock,
    Text,
    Title
} from "./RoundImageTextBlock.styled";

function RoundImageTextBlock({ blok }: RoundImageTextBlockProps): JSX.Element {
    const { title, text, image } = blok;

    return (
        <FramerMotionWrapper
            playWhenInView
            animationType={AnimationStyle.FadeInFromBottom}
            duration={0.5}
            delay={0.1}
        >
            <StyledRoundImagesTextBlock {...storyblokEditable(blok)}>
                <ImageContainer>
                    <ImageWrapper>
                        {image && (
                            <Image
                                src={image?.filename}
                                layout="fill"
                                objectFit="cover"
                                quality={100}
                                alt={image?.alt}
                            />
                        )}
                    </ImageWrapper>
                </ImageContainer>
                <ContentWrapper>
                    {title && <Title headingLevel={2}>{title}</Title>}
                    <Text>{text}</Text>
                </ContentWrapper>
            </StyledRoundImagesTextBlock>
        </FramerMotionWrapper>
    );
}

export default RoundImageTextBlock;
