import Image from "next/legacy/image";

import { ImageWrapper } from "./Avatar.styled";

export type AvatarSizeType = "small" | "large";

type AvatarProps = {
    imageUrl: string;
    size?: AvatarSizeType;
    className?: string;
};

export function Avatar({
    imageUrl,
    size = "large",
    className
}: AvatarProps): JSX.Element {
    return (
        <ImageWrapper size={size} className={className}>
            <Image
                src={imageUrl}
                width={48}
                height={48}
                quality={100}
                alt="Story profile picture"
            />
        </ImageWrapper>
    );
}
