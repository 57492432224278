import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 220px;
`;

export const ArrowLeft = styled.div<{ allowed?: boolean }>`
    cursor: pointer;
    margin-right: 10px;

    ${({ allowed }) =>
        !allowed &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;
export const ArrowRight = styled.div<{ allowed?: boolean }>`
    cursor: pointer;
    margin-left: 10px;

    ${({ allowed }) =>
        !allowed &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

export const Page = styled.div<{ empty?: boolean; active?: boolean }>`
    align-items: center;
    background: var(--color-primary);
    border-radius: 50px;
    cursor: pointer;
    height: 42px;
    justify-content: space-between;
    line-height: 42px;
    margin: 0 5px;
    max-height: 42px;
    max-width: 42px;
    min-width: 42px;
    text-align: center;

    /* stylelint-disable order/properties-alphabetical-order*/
    ${({ empty }) =>
        empty &&
        css`
            opacity: 0;
            pointer-events: none;
        `}

    ${({ active }) =>
        active &&
        css`
            background: var(--color-secondary);
            color: var(--color-primary);
        `} /* stylelint-enable order/properties-alphabetical-order */
`;
