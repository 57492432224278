import styled from "styled-components";

import { ARROW_RIGHT, Icon } from "components/ui";

const StyledIcon = styled(Icon)`
    margin-left: 4px;
    transition: all var(--quick-transition-duration) ease;
`;

type ArrowProps = {
    className?: string;
};

export function Arrow({ className }: ArrowProps): JSX.Element {
    return (
        <StyledIcon
            icon={ARROW_RIGHT}
            size={24}
            color="var(--color-orange-50)"
            className={className}
            aria-hidden="true"
        />
    );
}
